import { Grid, TextField } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import useStyles from "../provinces.styles";
import Button from "@material-ui/core/Button";
import { useDispatch, useSelector } from "react-redux";

const FormProvinces = ({ data,onCreate,onUpdate, edit }) => {

  const classes = useStyles();
  const { handleSubmit, control, getValues } = useForm();
  const { provinces } = useSelector((state) => state.Provinces);

  const onSubmit = (_data) => !!data ? onUpdate(_data) : onCreate(_data);

  return (
    <Grid container direction="row" wrap="wrap" spacing={2} >
      <form
        style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            name="provincename"
            control={control}
            defaultValue={data ? data.locationName : ""}
            rules={{ 
              required: "Province Name required",
              validate: (value) => {
                
                var match = provinces.filter(province => province?.locationName.toLowerCase().trim() == getValues()?.provincename.toLowerCase().trim() )
                if ( match.length === 0 || edit === true ) {
                  return true
                } else {
                  return "Province Already Exists"
                }
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Province Name"
                variant="outlined"
                value={value}
                className={classes.input}
                style={{ marginTop: 10 }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name="abbreviation"
            control={control}
            defaultValue={data ? data.data : ""}
            rules={{ 
              required: "Abbreviation is Required",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Abbreviation"
                variant="outlined"
                value={value}
                className={classes.input}
                style={{ marginTop: 10 }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name="capital"
            control={control}
            defaultValue={data ? data.capital : ""}
            rules={{ 
              required: "Capital is Required",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Capital"
                variant="outlined"
                value={value}
                className={classes.input}
                style={{ marginTop: 10 }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />

          <Controller
            name="country"
            control={control}
            defaultValue={data ? data.country : ""}
            rules={{ 
              required: "Country is Required",
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Country"
                variant="outlined"
                value={value}
                className={classes.input}
                style={{ marginTop: 10 }}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />


        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.saveBtn}>
          <Button color="primary" type="submit">
            Save Changes
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default FormProvinces;
