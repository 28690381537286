import { SET_RETURN_TO_WORK_DATA, SET_WELLBEING_DATA } from '../types/analytics.types';

const initialState = {
    returntoworkdata: [],
    wellbeingdata:{},
    isLoading: true
}

export const AnalyticsReducer = (state=initialState,action) => {

    switch(action.type){
        case SET_RETURN_TO_WORK_DATA:
            return {
                ...state,
                returntoworkdata: action.payload.data,
                isLoading: false
            }
        case SET_WELLBEING_DATA:
            return {
                ...state,
                wellbeingdata: action.payload.data,
                isLoading: false
            }
        default:
            return state;
    }
}