import React, { useState, useEffect } from "react";
import useStyles from "./userForm.styles";
import TextField from '@mui/material/TextField';
import OrganizationSelect from "../../select/select-organization.component";
import StatusSelect from "../../select/select-status.component";
import { getProvinces } from "../../../redux/actions/provinces.action";
import { useSelector, useDispatch } from "react-redux";
import SaveButton from "../../../components/buttons/save-button/saveButton.component";
import { getOrganization } from "../../../redux/actions/organization.action";
import { adminCreateUser, adminUpdateUser } from "../../../redux/actions/authentication.action";
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import moment from 'moment';
import { useHistory, Link } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import { alpha, styled } from '@mui/material/styles';
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathoactionableresults from '../../../assets/images/actionableresults.png'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../../redux/actions/loading.action";
import CircularProgress from '@mui/material/CircularProgress';
import { getUsers } from "../../../redux/actions/authentication.action";

const UserForm = ({ userData, add, closeAddEditUserDialog, edit }) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, setValue } = useForm();
  const { user, permissions, impersonating, impersonatedorganization } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization); 
  const [loading, setActionLoading]= useState(false);
  const [userAcessLevel, setAccessLevelState] = useState('EMPLOYEE');

  useEffect(() => {

    if (edit === true){
     
      setValue("organization", returnOrganization(userData.organization_id).name);
      setValue("organization_id", userData.organization_id);
      setValue("location_id", userData.location_id);
      setValue("email", userData.username);
      setValue("firstname", userData.first_name);
      setValue("lastname", userData.last_name);
      setAccessLevelState(userData.profile_name === 'isEndUser' ? 'EMPLOYEE' : 'ADMIN' )
    }
    else {

      setValue("organization", returnOrganization(user?.userdata?.organization_id).name);
      setValue("organization_id", user?.userdata?.organization_id);
      setValue("location_id", returnOrganization(user?.userdata?.organization_id).location_id);
      
    }

  },[user])

  useEffect(() => {
    if (user?.email){
      dispatch(getOrganization(user?.email));
    }
  }, [dispatch, user]);
 
  const returnOrganization = (organization_id) => {
    
    let organizationobject = organizations.filter(organization => organization.id == organization_id );
    
    if (organizationobject.length > 0){
      return organizationobject[0]
    }
  }

  const returnProfileId = () => {

    let profileid = '';

    if (userAcessLevel === 'ADMIN'){
      profileid = permissions.filter( permission => permission.name === 'isDomainAdmin' )[0].id
      return profileid
    }
    else if (userAcessLevel === 'EMPLOYEE'){
      profileid = permissions.filter( permission => permission.name === 'isEndUser' )[0].id
      return profileid
    }
     
  }

  const submitAddEditUser = (data) => {
    
    if ( add === true ){

        let body = { 
          "email": user?.userdata?.user_email,
          "user_email": data.email,
          "first_name": data.firstname,
          "last_name": data.lastname,
          "location_id": data.location_id,
          "organization_id": data.organization_id,
          "profile_id": returnProfileId()   
        }
        
        setActionLoading(true);

        dispatch(adminCreateUser(
          body, 
          () => {

            dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization))
            setActionLoading(false);
            closeAddEditUserDialog();

          },
          () => {
            setActionLoading(false);
            closeAddEditUserDialog()

          }  
        ))
    }
    else {

      let body = { 
        "email": user?.userdata?.user_email,
        "user_email": data.email,
        "first_name": data.firstname,
        "last_name": data.lastname,
        "location_id": data.location_id,
        "organization_id": data.organization_id,
        "profile_id": returnProfileId(),
        "is_active": true
      }

      setActionLoading(true);

      dispatch(adminUpdateUser(
        body, 
        () => {

          dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization))
          setActionLoading(false);
          closeAddEditUserDialog()

        },
        () => {
          setActionLoading(false);
          closeAddEditUserDialog()

        }  
      ))

    }

  };

  const setAccessLevel = (accesslevel) => {
    setAccessLevelState(accesslevel);
  }


  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& .MuiInputBase-input': {
      color: '#000'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });

  return (

    <Grid container spacing={0}>
      <CssBaseline/>

      <Grid
        item
        xs={false}
        sm={false}
        md={5}
        lg={5}
      >
        <div style={{ 
          backgroundImage: `url(${empathoactionableresults})`,
          backgroundRepeat: 'no-repeat',        
          backgroundSize: 'contain',
          backgroundPosition:'left',
          height: '40vh',
          marginBottom:40
        }}/>

          <div style={{ display:'flex', flexDirection:'column', alignItems:'center', justifyContent:'center' }}>
              <Button 
                style={{ fontSize:12, backgroundColor:'#58d8f2', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 200 }}
                variant="text"  
                disableElevation> 
                <ArrowDownward />
                <Link to="/templates/empatho_users_template.xlsx" target="_blank" download style={{ padding:"0px 10px", textDecoration:'none', color:'#FFF' }}>Template</Link>
              </Button>
              <Button 
                style={{ fontSize:12, backgroundColor:'#302b50', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 200 }}
                variant="text" 
                onClick={() => history.push({ pathname: "/users-upload-csv" })}
                disableElevation>
                  <AddCircle/>
                  <div style={{ padding:"5px 10px" }}>Upload Users</div>
              </Button>
          </div>

      </Grid>
      <Grid
        item
        xs={false}
        sm={false}
        md={5}
        lg={1}>
        <div style={{ width:1, height:'100%', backgroundColor:'#CCC' }}/>
      </Grid>
      <Grid 
      item 
      xs={12} 
      sm={12} 
      md={7}
      lg={5}
      component="main">

              <form className={classes.form} onSubmit={handleSubmit(submitAddEditUser)}>

                  <div style={{ margin: 10 }}>

                    <div className={classes.inputlabel}>
                        Organization
                    </div>

                    <Controller
                      name="organization"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Organization is Required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Organization"
                          value={value}
                          onChange={onChange}
                          error={!!error} 
                          disabled={true}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>

                  <Controller
                      name="organization_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <input
                          type="hidden"
                          value={value}
                        />
                      )}
                  />

                  <Controller
                      name="location_id"
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <input
                          type="hidden"
                          value={value}
                        />
                      )}
                    />


                  <div style={{ margin:10 }}>

                    <div className={classes.inputlabel}>
                        First Name
                    </div>

                    <Controller
                      name="firstname"
                      control={control}
                      defaultValue=""
                      rules={{ required: "First Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Enter Employee First Name"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>

                  <div style={{ margin:10 }}>

                    <div className={classes.inputlabel}>
                        Last Name
                    </div>

                    <Controller
                      name="lastname"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Last Name is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Enter Employee Last Name"
                          value={value}
                          onChange={onChange}
                          error={!!error} 
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>

                  <div style={{ margin:10 }}>

                    <div className={classes.inputlabel}>
                        Email
                    </div>

                    <Controller
                      name="email"
                      control={control}
                      defaultValue=""
                      rules={{ 
                        required: "Email is required",
                        validate: (value) => {
                          if ( isEmail(value) ) {
                            return true
                          } else {
                            return "Please Enter a Valid Email Address"
                          }
                        }
                      }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Enter Email"
                          value={value}
                          onChange={onChange}
                          error={!!error} 
                          disabled={loading || edit}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                  </div>

                  {
                    user?.userdata?.profile_name === 'isDomainSuperAdmin' ?
                    <>
                      <div style={{ marginTop:20, marginBottom:10, fontWeight:'bold' }}>
                          Access Level
                      </div>
                      <div style={{ display:'flex', flexDirection:'row' }}>
                          <Button style={{ color:'#FFF', padding:'10px 30px', backgroundColor: userAcessLevel === 'ADMIN' ? '#533aed' : '#EEE', width:'100%' }} onClick={() => setAccessLevel('ADMIN')}>
                              ADMIN
                          </Button>
                          <div style={{ marginRight:5, marginLeft:5}} />
                          <Button style={{ color:'#FFF', padding:'10px 30px', backgroundColor: userAcessLevel === 'EMPLOYEE' ? '#533aed' : '#EEE' , width:'100%' }} onClick={() => setAccessLevel('EMPLOYEE')}>
                              EMPLOYEE
                          </Button>
                      </div>
                    </>
                    :
                    null
                  }
                  <br/>
              
                  <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
                    <Button 
                      type="submit"
                      style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 250 }}
                      variant="text"  
                      disabled={loading}
                      disableElevation> 
                      <div style={{ padding:"10px 10px" }}>
                        { add === true ? 'Add User' : 'Save Changes' }
                      </div>

                      {
                        loading ?
                          <div style={{ justifyContent:'center', alignItems:'center' }}>
                            <CircularProgress 
                              size={20}
                              sx= {{ color:"#FFF" }}
                            />
                          </div>
                        :
                        null
                      }

                    </Button>
                  </div>

                  <div style={{ display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'500', fontSize:13, color:'red', marginTop:20 }}>
                      DEACTIVATE ACCOUNT 
                  </div>

              </form>

      </Grid>
    
    </Grid>


  );
};

export default UserForm;
