import React from "react";
import SearchIcon from "@material-ui/icons/Search";
import { InputBase } from "@material-ui/core";
import useStyles from "./searchBar.styles";

const SearchBar = () => {

  const classes = useStyles();
  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Search..."
        classes={{
          root: classes.inputRoot,
          input: classes.input,
        }}
        inputProps={{'aria-label': 'search'}}
      ></InputBase>
    </div>
  );
};

export default SearchBar;
