import { makeStyles } from "@material-ui/core";
const drawerWidth = 300;

const useStyles = makeStyles((theme) => ({
    appBar: {
        [theme.breakpoints.up("sm")]: {
            width: `calc(100% - ${drawerWidth})px)`,
            marginLeft: drawerWidth
        },
        background: "white"
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none"
        }
    },
    rightMenu: {
        display: "flex",
        alignItems: "center"
    }
}))

export default useStyles;