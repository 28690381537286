import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600,
        fontSize: "24px"
    },
    formBox: {
        margin: "10px 0px",
        width: "100%"
    },
    input: {
        width: "100%"
    },
    saveBtn: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: theme.spacing(2)
    }
}))

export default useStyles;