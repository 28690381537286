import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Grid } from "@material-ui/core";
import Piechart from "../../components/piechart/pierchart.component";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Divider from "@mui/material/Divider";
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryPie,
} from "victory";
import moment from "moment";
import battery from "../../assets/images/batterychart.png";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";

const Onboardingtour = () => {
  const history = useHistory();
  const [currentWeekDaysWellbeing, setCurrentWeekDaysWellbeing] = useState(null);
  const [chartDataWellbeing, setChartDataWellbeing] = useState([]);
  const [currentWeekDaysOnlyWellbeing, setCurrentWeekDaysOnlyWellbeing] =useState(null);
  const [selecteddayWellbeing, setSelectedDayWellbeing] = useState(null);

  const Htmltooltip = ({ x, y, datum, dx, dy }) => {
    return (
      <g style={{ pointerEvents: "none" }}>
        <foreignObject x={x - 28} y={y - 20} width="150" height="150">
          <div
            style={{
              width: "58px",
              height: "25px",
              backgroundColor: "#FFFFFF",
              boxShadow: "0px 10px 32px rgba(31, 47, 70, 0.12)",
              borderRadius: "10px",
            }}
          >
            <Grid style={{ flexDirection: "row", display: "flex" }}>
              <div
                style={{
                  width: "10px",
                  height: "10px",
                  background:
                    10 === datum.y
                      ? "#F90303"
                      : 50 === datum.y
                      ? "#1CDC93"
                      : 40 === datum.y
                      ? "#F7BC2D"
                      : "#000000",
                  borderRadius: 10,
                  marginTop: 10,
                  marginLeft: 10,
                }}
              ></div>
              <span
                style={{
                  fontSize: 13,
                  fontWeight: 500,
                  marginLeft: 8,
                  marginTop: 6,
                }}
              >
                {datum.y}%
              </span>
            </Grid>
          </div>
          <div
            style={{
              width: 0,
              height: 0,
              borderLeft: "5px solid transparent",
              borderRight: "5px solid transparent",
              borderTop: "10px solid #FFFFFF",
              boxShadow: "0px 10px 32px rgba(31, 47, 70, 0.12)",
              marginTop: 0,
              marginLeft: "25px",
              position: "absolute",
              zIndex: 1,
            }}
          ></div>
        </foreignObject>
      </g>
    );
  };

  const chartdata = [
    {
      avg_day_score: 65.8,
      entered_date: "2022-08-04",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-05",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-06",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-07",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-08",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-09",
      organization_id: 1,
    },
    {
      avg_day_score: 64.4,
      entered_date: "2022-08-10",
      organization_id: 1,
    },
  ];

  const returnColor = (score) => {
    let real_score = parseInt(score) || 0;
    if (real_score >= 0 && real_score <= 34) {
      return "#FA0202";
    } else if (real_score > 34 && real_score <= 49) {
      return "#FA0202";
    } else if (real_score > 49 && real_score <= 64) {
      return "#F7BC2D";
    } else if (real_score > 64 && real_score <= 79) {
      return "#1CDC93";
    } else if (real_score > 79) {
      return "#1CDC93";
    }
  };

  const getCurrentWeekOnboardingTour = () => {
    var currentDate = moment();

    var days = [];
    var daysOnly = [];

    for (var i = 6; i >= 0; i--) {
      days.push({
        day: moment().subtract(i, "days").format("dddd").charAt(0),
        name: moment().subtract(i, "days").format("dddd"),
        date: moment().subtract(i, "days").format("YYYY-MM-DD"),
      });
      daysOnly.push(moment().subtract(i, "days").format("YYYY-MM-DD"));
    }

    setCurrentWeekDaysWellbeing(days);
    setCurrentWeekDaysOnlyWellbeing(daysOnly);

    let tempList = (chartdata && chartdata) || [];

    if (days.length > 0) {
      let tempchartdata = [];

      for (var i = 0; i < days.length; i++) {
        tempchartdata.push({ x: days[i].date, y: null });
      }

      for (var x = 0; x < tempList.length; x++) {
        for (var a = 0; a < days.length; a++) {
          if (
            days[a].date ===
            moment(tempList[x].entered_date).format("YYYY-MM-DD")
          ) {
            tempchartdata[a].y = tempList[x].avg_day_score;
          }
        }
      }

      for (var i = 0; i < tempchartdata.length; i++) {
        if (tempchartdata[i].y === null) {
          tempchartdata.splice(i, 1);
        }
      }

      setChartDataWellbeing(tempchartdata);
      
    }
  };

  useEffect(() => {
    getCurrentWeekOnboardingTour();
  }, []);

  return (
    <div>
      <div
        style={{
          fontWeight: 500,
          fontSize: 32,
          paddingTop: 70,
          marginLeft: "35px",
          paddingBottom: 30,
        }}
      >
        Onboarding Tour
      </div>
      <div>
        <Paper
          sx={{
            width: "75%",
            marginLeft: "35px",
            marginRight: "35px",
            padding: 8,
            minHeight: "321px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "17px",
            // display: "flex"
          }}
        >
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            xs={12}
            // direction="row"
            // spacing={2}
            container
          >
            <div style={{ fontWeight: 700, fontSize: 28, color: "#23262F" }}>
              Prepare for wellbeing science
            </div>
            <div
              style={{
                fontWeight: 400,
                fontSize: 14,
                paddingTop: 20,
                color: "#23262F",
                marginRight:100
              }}
            >
              Empatho’s overview will show you different metrics such as
              wellbeing, risk of burnout, energy level, readiness, and others.
              For each of these metrics there is a daily score, scores over
              time, and a score breakdown that will provide you with insights on
              how your employees scores are distributed within your
              organization.
            </div>

            <Grid
              item
              lg={12}
              xl={12}
              md={12}
              xs={12}
              direction="row"
              // spacing={2}
              container
            >

                  <div style={{ paddingRight: 40, marginTop: 50 }}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                          style={{
                            marginBottom: "20px",
                            color: "#23262F",
                            letterSpacing: "0.02em",
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            lineHeight: "5px",
                            fontSize: "18px",
                            fontWeight: 600,
                            fontStyle: "normal",
                          }}
                        >
                          Daily Score
                        </div>
                      
                      </div>
                      <Piechart
                        data={65}
                        dimension={""}
                        label={"SATISFACTORY"}
                        color={"#F7BC2D"}
                        radius={70}
                        strokeWidth={16}
                        scoreLabelSize={25}
                        scoreTextColor={"#23262F"}
                        dimensionLabelSize={12}
                        labelSize={28}
                        showLabel={false}
                        showGreyCircle={true}
                      />
                      <Grid
                        container
                        direction="row"
                        style={{ justifyContent: "center", marginTop: "20px" }}
                      >
                        <ArrowDropUpIcon
                          style={{
                            color: "#1CDC93",
                          }}
                        />
                        <span
                          style={{
                            fontWeight: 500,
                            fontFamily: "Sofia Pro",
                            fontSize: "12px",
                            letterSpacing: "0.205714px",
                            marginTop: 5,
                            color: "#1CDC93",
                          }}
                        >
                          {15}%
                        </span>
                      </Grid>
                      <Grid
                        container
                        direction="row"
                        style={{ justifyContent: "center" }}
                      >
                        <div
                          style={{
                            fontWeight: 400,
                            fontSize: "10px",
                            lineHeight: "12px",
                            alignItems: "center",
                          }}
                        >
                          compared to yesterday
                        </div>
                      </Grid>
                  </div>

                  <Divider orientation="vertical" flexItem  style={{ marginTop: 50 }}/>

                  <div style={{ paddingRight: 40, marginTop: 50, marginLeft:40 }}>

                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div
                          style={{
                            color: "#23262F",
                            letterSpacing: "0.02em",
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            lineHeight: "5px",
                            fontSize: "18px",
                            fontWeight: 600,
                            fontStyle: "normal",
                          }}
                        >
                          Score over time
                        </div>
                        <div
                          style={{
                            fontWeight: 500,
                            fontSize: "12px",
                            lineHeight: "5px",
                            letterSpacing: "0.02em",
                            textDecorationLine: "underline",
                            color: "#979797",
                          }}
                        >
                          Last week
                        </div>
                      </div>
                      <Grid item lg={12} xl={12} md={12} xs={12}>
                        <g>
                          <VictoryChart
                            padding={{ top: 30, bottom: 40, right: 35, left: 0 }}
                            domainPadding={{ x: [20, 20], y: 0 }}
                            height={300}
                          >
                            <VictoryAxis
                              dependentAxis
                              orientation="right"
                              style={{
                                axis: { stroke: "transparent" },
                                tickLabels: {
                                  fill: "#999",
                                  fontSize: 7,
                                  fontWeight: 400,
                                  color: "#9F9EA7",
                                },
                                grid: { stroke: "#DDD" },
                              }}
                              tickValues={[0, 20, 40, 60, 80, 100]}
                              tickFormat={(t) => `${t}` + "%"}
                            />

                            <VictoryAxis
                              style={{
                                axis: { stroke: "#DDD" },
                                tickLabels: {
                                  fill: (axis) => (axis.index === 6 ? "black" : "#999"),
                                  fontSize: 7,
                                  fontWeight: (axis) =>
                                    axis.index === 6 ? "bold" : "400",
                                },
                                grid: {
                                  stroke: (axis) =>
                                    selecteddayWellbeing === axis.tick
                                      ? "#000000"
                                      : "#DDD",
                                },
                              }}
                              tickValues={currentWeekDaysOnlyWellbeing}
                              tickFormat={(t) =>
                                `${moment(t).format("ddd").toUpperCase()}\n${moment(
                                  t
                                ).format("MMM DD")}`
                              }
                              tickFormat={(t, i) =>
                                i === 6
                                  ? `${"TODAY"}\n${moment(t).format("MMM DD")}`
                                  : `${moment(t).format("ddd").toUpperCase()}\n${moment(
                                      t
                                    ).format("MMM DD")}`
                              }
                            />

                            <VictoryLine
                              interpolation={"linear"}
                              data={[
                                { x: 1, y: 65 },
                                { x: 2, y: 73 },
                                { x: 3, y: 85 },
                                { x: 4, y: 54 },
                                { x: 5, y: 87 },
                                { x: 6, y: 87 },
                                { x: 7, y: 87 }
                              ]}
                              style={{ data: { stroke: "#DDD", strokeDasharray: 4 } }}
                            />

                            <VictoryScatter
                              data={[
                                { x: 1, y: 65 },
                                { x: 2, y: 73 },
                                { x: 3, y: 85 },
                                { x: 4, y: 54 },
                                { x: 5, y: 87 },
                                { x: 6, y: 87 },
                                { x: 7, y: 87 }
                              ]}
                              labels={({ datum }) => `${datum.y}%`}
                              labelComponent={
                                <VictoryTooltip
                                  cornerRadius={5}
                                  style={{ fontSize: 10 }}
                                  flyoutStyle={{ fill: "white", stroke: "#DDD" }}
                                />
                              }
                              size={4}
                              style={{
                                data: {
                                  fill: ({ datum }) =>
                                    selecteddayWellbeing === datum.x
                                      ? returnColor(datum.y)
                                      : "#D1D0D7",
                                },
                              }}
                              events={[
                                {
                                  target: "data", 
                                },
                              ]}
                            />
                          </VictoryChart>
                        </g>
                      </Grid>
                    </div>

                    <Divider orientation="vertical" flexItem  style={{ marginTop: 50 }}/>


                    <div style={{ paddingRight: 40, marginTop: 50, marginLeft:40 }}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <div
                          style={{
                            marginBottom: "20px",
                            color: "#23262F",
                            letterSpacing: "0.02em",
                            textAlign: "center",
                            alignItems: "center",
                            display: "flex",
                            lineHeight: "5px",
                            fontSize: "18px",
                            fontWeight: 600,
                            fontStyle: "normal",
                          }}
                        >
                          Score Breakdown &nbsp;
                        </div>
                      
                      </div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <svg width={240} height={210}>
                          <circle
                            cx="50%"
                            cy="50%"
                            r={65}
                            stroke="#EBEAED"
                            fill="transparent"
                            strokeWidth={10}
                          />
                          <VictoryPie
                            standalone={false}
                            width={240}
                            height={210}
                            innerRadius={69}
                            cornerRadius={({ datum }) => datum.y * 2}
                            data={[
                              {
                                x: " ",
                                y: 10,
                              },
                              {
                                x: " ",
                                y: 50,
                              },
                              {
                                x: " ",
                                y: 40,
                              },
                            ]}
                            colorScale={["#F90303", "#1CDC93", "#F7BC2D"]}
                            labelComponent={
                              <VictoryTooltip flyoutComponent={<Htmltooltip />} />
                            
                            }
                          />
                        </svg>
                        <img
                          src={battery}
                          style={{
                            width: "35px",
                            height: "auto",
                            zIndex: 1,
                            position: "absolute",
                          }}
                        />
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <div style={{ display: "flex", margin: "10px" }}>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              background: "#1CDC93",
                              flex: "none",
                              order: 0,
                              flexGrow: 0,
                              borderRadius: 10,
                              marginTop: -10,
                            }}
                          ></div>
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "20px",
                              marginTop: -12,
                              paddingLeft: 5,
                            }}
                          >
                            Optimal
                          </span>
                        </div>

                        <div style={{ display: "flex", margin: "10px" }}>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              background: "#F7BC2D",
                              flex: "none",
                              order: 0,
                              flexGrow: 0,
                              borderRadius: 10,
                              marginTop: -10,
                            }}
                          ></div>
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "20px",
                              marginTop: -12,
                              paddingLeft: 5,
                            }}
                          >
                            Satisfactory
                          </span>
                        </div>
                        <div style={{ display: "flex", margin: "10px" }}>
                          <div
                            style={{
                              width: "14px",
                              height: "14px",
                              background: "#FA0202",
                              flex: "none",
                              order: 0,
                              flexGrow: 0,
                              borderRadius: 10,
                              marginTop: -10,
                            }}
                          ></div>
                          <span
                            style={{
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "10px",
                              lineHeight: "20px",
                              marginTop: -12,
                              paddingLeft: 5,
                            }}
                          >
                            Low
                          </span>
                        </div>
                      </div>
                    </div> 
              </Grid>
                                  
          </Grid>

          <Grid
            style={{
              marginTop:10,
              height: 1,
              backgroundColor:'#D1D0D7',
            }}
          ></Grid>

          <Grid
            style={{
              marginTop:20,
              display: "flex",
              flexDirection: "row",
              justifyContent:"flex-end"
            }}
          >
            <Button
              onClick={() => history.push("/wellbeing")}
              style={{
                backgroundColor: "#533AED",
                borderRadius: 25,
                color: "#EBEAED",
                width: 158,
                height: 46,
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                letterSpacing: "2px"
              }}
            >
              PREVIOUS
            </Button>
            <div style={{ paddingLeft: 4, paddingRight: 4 }}></div>
            <Button
              onClick={() => history.push("/onboardingtoursecond")}
              style={{
                backgroundColor: "#533AED",
                borderRadius: 25,
                color: "#EBEAED",
                width: 158,
                height: 46,
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              NEXT
            </Button>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Onboardingtour;
