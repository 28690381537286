import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import useStyles from "./verifyaccount.styles";
import { useForm, Controller } from "react-hook-form";
import LoginBtn from "../../../components/login-btn/loginBtn.component";
import Typography from "@material-ui/core/Typography";
import { useDispatch } from "react-redux";
import { alpha, styled } from '@mui/material/styles';
import {
  setLogin,
  setVerifyAccount,
} from "../../../redux/actions/authentication.action";
import validator from 'validator'
import isEmail from 'validator/lib/isEmail';
import { useHistory } from "react-router-dom";
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathologinscreenimage from '../../../assets/images/loginscreenimage.png'
import CssBaseline from '@mui/material/CssBaseline';


const VerifyAccount = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [onLogin, setOnLogin] = useState(false);
  const [email, setEmail] = useState('');
  const [emailerror, setEmailError] = useState('');
  const [emailerrormessage, setEmailErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [showpassword, setShowPassword] = useState(false);

  const { handleSubmit, control } = useForm();

  const submitVerifyAccount = (data) => {
    
    const { code } = data;

    if (code){
      
      dispatch(
        setVerifyAccount( code, 
          (res) => {
              if (res) {
                history.push({ pathname: '/createpassword' });
              }
          }
      ));
    }
  };

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& .MuiInputBase-input': {
      color: '#FFF'
    },
    '& label.Mui-focused': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#FFF',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#CCC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFF',
      },
    },
  });

  return (

    <Grid container spacing={0}>
        <CssBaseline/>
        <Grid 
        item 
        xs={12} 
        sm={12} 
        md={7}
        lg={5}
        component="main">
              <div className={classes.container}>

                  <div style={{ flex:0.5, paddingLeft:50,  marginTop:50, justifyContent:'center' }}>
                      <img
                        color={'none'}
                        src={empathowhitelogo}
                        style={{ flex:0.5, width: '200px', height:'auto'}}
                      /> 
                  </div>
                  <div style={{ flex: 4, display:'flex', justifyContent:'center', alignItems:'center' }}>

                    <div style={{ width: '60%', display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignSelf:'center' }}>
                        
                        <div style={{ fontWeight: 'bold', color:'#FFF', fontSize:'3em', margin:'20px 0px 20px 0px' }}>
                          Verify Account
                        </div>

                        <form className={classes.form} onSubmit={handleSubmit(submitVerifyAccount)}>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Invitation Code
                            </div>
                          
                            <Controller
                              name="code"
                              control={control}
                              defaultValue=""
                              rules={{ required: "Code is required", minLength: 6, maxLength: 6}}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  placeholder="Enter Invitation Code"
                                  value={value.toUpperCase()} //TODO
                                  onChange={onChange}
                                  error={!!error} 
                                  inputProps={{ maxLength: 6 }}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>
                          <br/>
                          <LoginBtn label={'Verify Account'} />

                        </form>

                        <div style={{ textAlign:'center', fontWeight:'bold', marginTop:30, marginBottom:20 }}>
                              <a href="/resendcode" style={{ color:"#FFF", textDecoration:'none' }}> <span style={{ color:"#57bff1" }}>GET A NEW INVITATION CODE</span> </a>
                        </div>

                    </div>
                  </div>

                  <div style={{ flex:1, display:'flex', justifyContent:'center', alignItems:'flex-end', paddingBottom:30  }}>
                    <div style={{ padding:30, textAlign:'center', color:'#FFF', fontSize:'0.7em', fontWeight:'600' }}>
                        By tapping "continue" you agree to Empatho Corp's <br/>
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}>terms of service</a></span> and 
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}> privacy policy</a></span>.
                    </div>
                  </div>    
                
              </div>
              
        </Grid>
        
        <Grid
          item
          xs={false}
          sm={false}
          md={5}
          lg={7}
          sx={{
            backgroundImage: `url(${empathologinscreenimage})`,
            backgroundRepeat: 'no-repeat',        
            backgroundSize: 'contain',
            backgroundPosition: 'center',
            width:'100%',
            height:'100vh'
          }}
        />

    </Grid>

  
  );
};

export default VerifyAccount;
