import React from "react";
import { makeStyles, Hidden } from "@material-ui/core";
// import { Route } from "react-router";
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

//Components
import CustomAppBar from "../components/app-bar/app-bar.component";
import CustomDrawer from "../components/drawer/drawer-body/drawer.component";

//Screens
import Wellbeing from "../screens/wellbeing/wellbeing";
import CovidSurvey from "../screens/covidscreening/covidsurvey";
import ReturnToWork from "../screens/returntowork/returntowork";
import Organizations from "../screens/organizations/organizations";
import Users from "../screens/users/user";
import HelpDesk from "../screens/help-desk/helpDesk";
import UserSettings from "../screens/settings/settings";
import Login from "../screens/authentication/login/login";
import Register from "../screens/authentication/register/register";
import ResetPassword from "../screens/authentication/reset-password/resetPassword";
import NewPassword from "../screens/authentication/new-password/newPassword";
import ResendPassword from "../screens/authentication/resend-verification/resendVerification";
import VerifyAccount from "../screens/authentication/verifyaccount/verifyaccount";
import CreatePassword from "../screens/authentication/createpassword/createpassword";
import NoMatch from "../screens/nomatch/nomatch";
import FAQ from "../screens/faq/faq";
import CustomSnackBar from "../components/snackbar/snackbar.component";
import Provinces from "../screens/provinces/provinces";
import { useSelector } from "react-redux";
import NodataUser from '../screens/nodata/nodataUsers';
import NodataCountdown from '../screens/nodata/nodataCountdown';
import OnboardingTour from '../screens/nodata/onboardingtour';
import Onboardingtoursecond from '../screens/nodata/onboardingtoursecond';



const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex"
  },
  // offset: theme.mixins.toolbar,
  container: {
    flexGrow: 1,
    padding: theme.spacing(3),
    // paddingTop:0
  },
  body: {
    // height: "calc(100vh - 95px)",
  },
}));

const AppRoutes = (props) => {
  
  const classes = useStyles();
  const { user, isLoggedIn } = useSelector((state) => state.Authentication);
  const user_profile = user?.userdata?.profile_name;
  const usermanagementroles = ['isSuperAdmin', 'isGlobalAdmin', 'isDomainSuperAdmin', 'isDomainAdmin'];
  const analyticsroles = ['isSuperAdmin', 'isGlobalAdmin', 'isDomainSuperAdmin', 'isDomainAdmin'];
  const provinceroles = ['isSuperAdmin', 'isGlobalAdmin'];
  const organizationroles = ['isSuperAdmin', 'isGlobalAdmin'];

  return (
    <Router>
          <Switch>
            <Route exact path="/">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <Redirect to="/login" /> }
            </Route>
            <Redirect exact from="/" to="/login" />
            <Route exact path="/login" component={Login}></Route>
            
            <Route exact path="/register">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <Register /> }
            </Route>

            <Route exact path="/resetpassword">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <ResetPassword /> }
            </Route>

            <Route exact path="/resetpassword">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <ResetPassword /> }
            </Route>

            <Route exact path="/verifyaccount">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <VerifyAccount /> }
            </Route>

            <Route exact path="/createpassword">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <CreatePassword /> }
            </Route>

            <Route exact path="/newpassword">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <NewPassword /> }
            </Route>

            <Route exact path="/resendcode">
              { isLoggedIn === true ? <Redirect to="/wellbeing" /> : <ResendPassword /> }
            </Route>

            <Route exact path="/unauthorized" component={NoMatch}></Route>

            <div className={classes.root}>
      
              <Hidden xsDown>
                <CustomDrawer variant="permanent" />
              </Hidden>

              <div className={classes.container}>

                <div className={classes.body}>
                    
                    
                        <Route exact path="/wellbeing">
                            { analyticsroles.includes(user_profile) && isLoggedIn === true ? <Wellbeing/> : <Redirect to="unauthorized"/> }
                        </Route>

                        <Route exact path="/covidsurvey">
                          { organizationroles.includes(user_profile) && isLoggedIn === true ? <CovidSurvey/> : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/nodatausers">
                          { usermanagementroles.includes(user_profile) && isLoggedIn === true ? <NodataUser /> : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/nodatacountdown">
                          { usermanagementroles.includes(user_profile) && isLoggedIn === true ? <NodataCountdown /> : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/onboardingtour">
                          { usermanagementroles.includes(user_profile) && isLoggedIn === true ? <OnboardingTour /> : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/onboardingtoursecond">
                          { usermanagementroles.includes(user_profile) && isLoggedIn === true ? <Onboardingtoursecond /> : <Redirect to="/unauthorized"/> }
                        </Route>
                        
                        <Route exact path="/returntowork">
                          { analyticsroles.includes(user_profile) && isLoggedIn === true ? <ReturnToWork/> : <Redirect to="/unauthorized"/> }
                        </Route>
      
                        <Route exact path="/organizations">
                          { organizationroles.includes(user_profile) && isLoggedIn === true ? <Organizations/> : <Redirect to="/unauthorized"/> }
                        </Route>
                        <Route exact path="/users" component={Users}>
                            { usermanagementroles.includes(user_profile) && isLoggedIn === true ? null : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/faq" component={FAQ}>
                            { usermanagementroles.includes(user_profile) && isLoggedIn === true ? null : <Redirect to="/unauthorized"/> }
                        </Route>
                    
                        <Route exact path="/settings" component={UserSettings}>
                            { isLoggedIn === true ? null : <Redirect to="/unauthorized"/> }
                        </Route>

                        <Route exact path="/help-desk" component={HelpDesk}></Route>

                </div>
                
              </div>
            </div>

          </Switch>
        
          <div>
            <CustomSnackBar />
          </div>
    </Router>
  );
};

export default AppRoutes;
