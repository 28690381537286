import React, { useState, useEffect } from "react";
import useStyles from "./userForm.styles";
import TextField from "@mui/material/TextField";
import OrganizationSelect from "../../select/select-organization.component";
import StatusSelect from "../../select/select-status.component";
import {
  getProvinces,
  getCities,
} from "../../../redux/actions/provinces.action";
import { useSelector, useDispatch } from "react-redux";
import SaveButton from "../../buttons/save-button/saveButton.component";
import {
  getOrganization,
  getMetaData,
  addDepartment
} from "../../../redux/actions/organization.action";
import {
  adminCreateUser,
  adminUpdateUser,
} from "../../../redux/actions/authentication.action";
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import moment from "moment";
import { useHistory } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { alpha, styled } from "@mui/material/styles";
import empathowhitelogo from "../../../assets/images/empathowhitelogo.png";
import empathoactionableresults from "../../../assets/images/actionableresults.png";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { ArrowDownward, AddCircle, PersonAdd, Close } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../../redux/actions/loading.action";
import CircularProgress from "@mui/material/CircularProgress";
import {
  getUsers,
  adminDeleteUser,
} from "../../../redux/actions/authentication.action";
import Autocomplete from "@mui/material/Autocomplete";
import CustomDialog from "../../dialog/dialog.component";

const AdminUserForm = ({ userData, add, closeAddEditUserDialog, edit }) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, permissions, impersonating, impersonatedorganization } =
    useSelector((state) => state.Authentication);
  const { provinces, cities } = useSelector((state) => state.Provinces);
  const { organizations, meta_data } = useSelector(
    (state) => state.Organization
  );
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [permissionsList, setPermissionsList] = useState(permissions);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);
  const [filtercitieslist, setFilteredCitiesList] = useState([]);
  const [citiesdisabled, setDisableCities] = useState(true);
  const [openDeleteDialog, setOpenDeleteDialog] = useState({ open: false, items: null });
  const [showAddDepartment, setShowAddDepartment] = useState(false);


  const accessLevels = [
    { profile_name: "isGlobalAdmin", name: "Global Admin" },
    { profile_name: "isDomainSuperAdmin", name: "Organization Admin" },
    { profile_name: "isDomainAdmin", name: "Admin" },
    { profile_name: "isEndUser", name: "Employee" },
  ];

  useEffect(() => {
    dispatch(getMetaData(user?.email, () => {}, () => {}));
  }, []);

  const returnLocation = (location_id) => {
    let locationobject = provinces.filter(
      (province) => Number(province?.id) === Number(location_id)
    );
    
    if (locationobject.length > 0) {
      return locationobject[0];
    } else {
      return {};
    }
  };

  const closeDeleteDialog = () => {
    setOpenDeleteDialog({ open: false, items: null });
  };

  const returnContractType = (id) => {
    let contractobject = meta_data?.contract_type.filter((c) => c.id == id);

    if (contractobject.length > 0) {
      return contractobject[0];
    }
  };

  const returnJobTitle = (id) => {
    let jobtitleobject = meta_data?.job_title.filter((j) => j.id == id);

    if (jobtitleobject.length > 0) {
      return jobtitleobject[0];
    }
  };

  const returnDepartment = (id) => {
    let departmentobject = meta_data?.department.filter((d) => d.id == id);

    if (departmentobject.length > 0) {
      return departmentobject[0];
    }
  };

  const returnCity = (id) => {
    let cityobject = cities.filter((d) => d.id == id);

    if (cityobject.length > 0) {
      return cityobject[0];
    }
  };

  useEffect(() => {
    if (edit === true) {
    
      setValue("organization", returnOrganization(userData?.organization_id));
      setValue("organization_id", userData?.organization_id);
      setValue("profile", returnProfileId(userData?.profile_name));
      setValue("location", returnLocation(userData?.location_id));
      setValue("location_id", userData?.location_id);
      setValue("email", userData?.username);
      setValue("firstname", userData?.first_name);
      setValue("lastname", userData?.last_name);
      setValue("contract_type", returnContractType(userData?.contract_type_id));
      setValue("contract_type_id", userData?.contract_type_id);
      // setValue("job_title", returnJobTitle(userData?.job_title_id));
      // setValue("job_title_id", userData?.job_title_id);
      setValue("department", returnDepartment(userData?.department_id));
      setValue("department_id", userData?.department_id);
      setValue("city", returnCity(userData?.city_id));
      setValue("city_id", userData?.city_id);
      filterCityList(userData?.location_id);

      if (userData?.location_id !== null) {
        setDisableCities(false);
      } else {
        setDisableCities(true);
      }
      // setValue("Location", provinces.data.state_or_province);
      filterPermissionsList(returnOrganization(userData?.organization_id));
      disabledAccessLevel(false);
    } else {
      // setValue("organization", returnOrganization(user?.userdata?.organization_id).name);
      // setValue("organization_id", user?.userdata?.organization_id);
      // setValue("location_id", returnOrganization(user?.userdata?.organization_id).location_id);
      setValue("location", {});
      setValue("location_id", "");
      setValue("city", {});
      setValue("city_id", "");
    }
  }, [user]);

  const filterCityList = (location_id) => {
    let cityarray = cities.filter((d) => d.location_id == location_id);

    if (cityarray.length > 0) {
      setFilteredCitiesList(cityarray);
    }
  };

  useEffect(() => {
    if (user?.email) {
      dispatch(getOrganization(user?.email));
    }
  }, [dispatch, user]);

  const returnOrganization = (organization_id) => {
    let organizationobject = organizations.filter(
      (organization) => organization.id == organization_id
    );

    if (organizationobject.length > 0) {
      return organizationobject[0];
    }
  };

  const returnProfileId = (userAcessLevel) => {
    
    let profileid = permissions.filter(
      (permission) => permission.name === userAcessLevel
    )[0];

    return profileid;
  };

  const filterPermissionsList = (data) => {
    if (data && !!data.id) {
      let org = returnOrganization(data.id);
      if (org.default === true) {
        setPermissionsList(permissions);
      } else {
        let filteredList = permissions.filter(
          (permission) =>
            permission.name !== "isSuperAdmin" &&
            permission.name !== "isGlobalAdmin"
        );
        setPermissionsList(filteredList);
      }
    } else {
      setPermissionsList(permissions);
    }
  };

  const submitAddEditUser = (data) => {
  
    if (add === true) {
      
      let body = {
        email: user?.userdata?.user_email,
        user_email: data?.email,
        first_name: data?.firstname,
        last_name: data?.lastname,
        location_id: data?.location_id,
        organization_id: data?.organization_id,
        profile_id: returnProfileId(data?.profile?.name).id,
        // job_title: data?.job_title_id,
        contract_type: data?.contract_type_id,
        department: data?.department_id,
        city: Number(data?.city_id),
      };

      setActionLoading(true);

      dispatch(
        adminCreateUser(
          body,
          () => {
            dispatch(
              getUsers(
                user?.email,
                user?.userdata?.organization_id,
                impersonating,
                impersonatedorganization
              )
            );
            setActionLoading(false);
            closeAddEditUserDialog();
          },
          () => {
            setActionLoading(false);
          }
        )
      );
    } else {
      
      let body = {
        email: user?.userdata?.user_email,
        user_email: data?.email,
        first_name: data?.firstname,
        last_name: data?.lastname,
        location_id: data?.location_id,
        organization_id: data?.organization_id,
        profile_id: returnProfileId(data?.profile?.name).id,
        is_active: true,
        // job_title: data?.job_title_id,
        contract_type: data?.contract_type_id,
        department: data?.department_id,
        city: Number(data?.city_id),
      };

      setActionLoading(true);

      dispatch(
        adminUpdateUser(
          body,
          () => {
            dispatch(
              getUsers(
                user?.email,
                user?.userdata?.organization_id,
                impersonating,
                impersonatedorganization
              )
            );
            setActionLoading(false);
            closeAddEditUserDialog();
          },
          () => {
            setActionLoading(false);
          }
        )
      );
    }
  };

  const deleteUser = (data) => {
    
    let body = {
      email: user?.userdata?.user_email,
      user_email: userData?.username,
      user_id: userData?.id,
    };

    setActionLoading(true);

    dispatch(
      adminDeleteUser(
        body,
        () => {
          dispatch(
            getUsers(
              user?.email,
              user?.userdata?.organization_id,
              impersonating,
              impersonatedorganization
            )
          );
          setActionLoading(false);
          closeAddEditUserDialog();
        },
        () => {
          setActionLoading(false);
        }
      )
    );
  };

  const LoginTextField = styled(TextField)({
    "label + &": {
      color: "pink",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#000",
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA",
      },
      "&:hover fieldset": {
        borderColor: "#C9C9C9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#533aed",
      },
    },
  });

  const [newdepartment, setNewDepartment] = useState("");
  const [newdepartmenterror, setNewDepartmentError] = useState(null);
  const [newdepartmenterrormessage, setNewDepartmentMessage] = useState(null);

  const [setdepartmenttonewdepartment, setDepartmentToNewDepartment] = useState(false);
  const [departmentjustadded, setDepartmentJustAdded] = useState({});

  const handleNewDepartmentChange = event => {
    
    setNewDepartment(event.target.value);
  };

  const clearNewDepartment = () => {
    setNewDepartmentError(false);
    setNewDepartmentMessage("")
  }

  useEffect(() => {
    
    if (setdepartmenttonewdepartment && Object.keys(departmentjustadded).length > 0 ){

      setValue("department", returnDepartment(departmentjustadded?.id));
      setValue("department_id", departmentjustadded?.id);

      setDepartmentToNewDepartment(false);
      setDepartmentJustAdded({})

    }
  
    return () => {}
  }, [meta_data, setdepartmenttonewdepartment, departmentjustadded])
  

  const submitNewDepartment = () => {

    clearNewDepartment();

    if (newdepartment === ""){

      setNewDepartmentError(true);
      setNewDepartmentMessage("Enter Department Name")

    }
    else{

      let body = {
        email: user?.userdata?.user_email,
        department: newdepartment
      };
  
      dispatch(
        addDepartment(
          body,
          (resp) => {

            setDepartmentJustAdded(resp)

            setDepartmentToNewDepartment(true);
            setActionLoading(false);
            setShowAddDepartment(false);
            setNewDepartment("");
            clearNewDepartment();

          },
          () => {
            setActionLoading(false);
          }
        )
      );

    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit(submitAddEditUser)}>
      <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Organization</div>

        <Controller
          rules={{ required: "Organization is Required" }}
          defaultValue={returnOrganization(userData?.organization_id) || []}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Autocomplete
              options={organizations}
              onChange={(_, data) => {
                
                setValue("organization_id", data ? data.id : "");

                if (data && !!data.id) {
                  disabledAccessLevel(false);
                  filterPermissionsList(data);
                } else {
                  disabledAccessLevel(true);
                  filterPermissionsList();
                }

                onChange(data);
              }}
              value={value}
              getOptionLabel={(option) => option.name || ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              // disabled={readOnly}
              renderInput={(params) => (
                <LoginTextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Organization"
                  readOnly={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
          name="organization"
          control={control}
        />

        {/* <Autocomplete
                options={organizations}
                value={organization}
                onChange={(event, newValue) => {
                  setOrganization(newValue || {});
                }}
                inputValue={organization.name || ''}
                getOptionLabel={(option) => option.name || ''}
                getOptionSelected={(option, value) => option?.id === value?.id }
                // disabled={readOnly}
                renderInput={(params) => (
                    <LoginTextField 
                      {...params} 
                      variant="outlined" 
                      placeholder="Select Organization"
                      readOnly={false}/>
                )}
              /> */}
      </div>

      {/* <div style={{ margin: 10 }}>

            <div className={classes.inputlabel}>
                Organization
            </div>

            <Controller
              name="organization"
              control={control}
              defaultValue=""
              rules={{ required: "Organization is Required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Organization"
                  value={value}
                  onChange={onChange}
                  error={!!error} 
                  disabled={true}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div> */}

      <Controller
        name="organization_id"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <input type="hidden" value={value} />
        )}
      />

      {/* <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <input type="hidden" value={value} />
        )}
      /> */}

      <Grid
        sx={{
          display: "flex",
          justifyContent:'space-between',
          flexDirection: "row",
          padding: "5px 0px 10px 0px",
        }}
      >
          <div style={{ flex:1, margin: 10 }}>
            <div className={classes.inputlabel}>First Name</div>

            <Controller
              name="firstname"
              control={control}
              defaultValue=""
              rules={{ required: "First Name is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Enter Employee First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ flex:1, margin: 10 }}>
            <div className={classes.inputlabel}>Last Name</div>

            <Controller
              name="lastname"
              control={control}
              defaultValue=""
              rules={{ required: "Last Name is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Enter Employee Last Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>
      </Grid>

      <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Email</div>

        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Email is required",
            validate: (value) => {
              if (isEmail(value)) {
                return true;
              } else {
                return "Please Enter a Valid Email Address";
              }
            },
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <LoginTextField
              className={classes.text_field}
              variant="outlined"
              placeholder="Enter Email"
              value={value}
              onChange={onChange}
              error={!!error}
              disabled={loading || edit}
              helperText={error ? error.message : null}
            />
          )}
        />
      </div>

      <Grid
        sx={{
          display: "flex",
          justifyContent:'space-between',
          flexDirection: "row",
          padding: "5px 0px 10px 0px",
        }}
      >
          <div style={{ flex:1, margin: 10 }}>
            <div className={classes.inputlabel}>State or Province</div>

            <Controller
              name="location"
              control={control}
              defaultValue={returnLocation(userData?.location_id) || {}}
              rules={{ required: "Location is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                  options={provinces}
                  onChange={(_, data) => {
                    
                    setValue("location_id", data ? data.id : "");
                    onChange(data);

                    if (data && !!data.id) {
                      setDisableCities(false);
                      filterCityList(data?.id);
                    } else {
                      setDisableCities(true);
                      filterPermissionsList();
                      setValue("city", {});
                      setValue("city_id", "");
                    }
                  }}
                  value={value}
                  getOptionLabel={(option) => option?.state_or_province || ""}
                  getOptionSelected={(option, value) => option?.id === value?.id}
                  // disabled={readOnly}
                  renderInput={(params) => (
                    <LoginTextField
                      {...params}
                      variant="outlined"
                      placeholder="Select Employee Location"
                      readOnly={false}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />

                // <LoginTextField
                //   className={classes.text_field}
                //   variant="outlined"
                //   placeholder="Enter Employee Location"
                //   value={value}
                //   onChange={onChange}
                //   error={!!error}
                //   disabled={loading}
                //   helperText={error ? error.message : null}
                // />
              )}
            />
            <Controller
              name="location_id"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <input type="hidden" value={value} />
              )}
            />
          </div>

          <div style={{ flex:1, margin: 10 }}>
            <div className={classes.inputlabel}>City</div>

            <Controller
              name="city"
              control={control}
              defaultValue={returnCity(userData?.city_id) || {}}
              rules={{ required: "City is required" }}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <Autocomplete
                  options={filtercitieslist}
                  onChange={(_, data) => {
                    
                    setValue("city_id", data ? data.id : "");

                    // if (data && !!data.id) {
                    //   disabledAccessLevel(false);
                    //   filterPermissionsList(data);
                    // } else {
                    //   disabledAccessLevel(true);
                    //   filterPermissionsList();
                    // }

                    onChange(data);
                  }}
                  value={value}
                  getOptionLabel={(option) => option.city || ""}
                  getOptionSelected={(option, value) => option?.id === value?.id}
                  disabled={citiesdisabled}
                  renderInput={(params) => (
                    <LoginTextField
                      {...params}
                      variant="outlined"
                      placeholder="Select City"
                      readOnly={citiesdisabled}
                      error={!!error}
                      helperText={error ? error.message : null}
                    />
                  )}
                />

                // <LoginTextField
                //   className={classes.text_field}
                //   variant="outlined"
                //   placeholder="Enter Employee Location"
                //   value={value}
                //   onChange={onChange}
                //   error={!!error}
                //   disabled={loading}
                //   helperText={error ? error.message : null}
                // />
              )}
            />
            <Controller
              name="city_id"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <input type="hidden" value={value} />
              )}
            />
          </div>

      </Grid>

      <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Contract Type</div>

        <Controller
          name="contract_type"
          control={control}
          defaultValue={userData?.contract_type_id || []}
          rules={{ required: "Contract Type is required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              options={meta_data.contract_type}
              onChange={(_, data) => {
                
                setValue("contract_type_id", data ? data.id : "");

                // if (data && !!data.id) {
                //   disabledAccessLevel(false);
                //   filterPermissionsList(data);
                // } else {
                //   disabledAccessLevel(true);
                //   filterPermissionsList();
                // }

                onChange(data);
              }}
              value={value}
              getOptionLabel={(option) => option.contract_type || ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              // disabled={readOnly}
              renderInput={(params) => (
                <LoginTextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Contract Type"
                  readOnly={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            // <LoginTextField
            //   className={classes.text_field}
            //   variant="outlined"
            //   placeholder="Enter Employee Contract Type"
            //   value={value}
            //   onChange={onChange}
            //   error={!!error}
            //   disabled={loading}
            //   helperText={error ? error.message : null}
            // />
          )}
        />

        <Controller
          name="contract_type_id"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <input type="hidden" value={value} />
          )}
        />
      </div>

      {/* <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Job Title</div>

        <Controller
          name="job_title"
          control={control}
          defaultValue={userData?.job_title_id || []}
          rules={{ required: "Job title is required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              options={meta_data.job_title}
              onChange={(_, data) => {
                
                setValue("job_title_id", data ? data.id : "");

                // if (data && !!data.id) {
                //   disabledAccessLevel(false);
                //   filterPermissionsList(data);
                // } else {
                //   disabledAccessLevel(true);
                //   filterPermissionsList();
                // }

                onChange(data);
              }}
              value={value}
              getOptionLabel={(option) => option.job_title || ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              // disabled={readOnly}
              renderInput={(params) => (
                <LoginTextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Job Title"
                  readOnly={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
            // <LoginTextField
            //   className={classes.text_field}
            //   variant="outlined"
            //   placeholder="Enter Job Title"
            //   value={value}
            //   onChange={onChange}
            //   error={!!error}
            //   disabled={loading}
            //   helperText={error ? error.message : null}
            // />
          )}
        />
        <Controller
          name="job_title_id"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <input type="hidden" value={value} />
          )}
        />
      </div> */}

      <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Department</div>

        <Controller
          name="department"
          control={control}
          defaultValue={userData?.department_id || []}
          rules={{ required: "Department is required" }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <Autocomplete
              options={meta_data.department}
              onChange={(_, data) => {
                
                setValue("department_id", data ? data.id : "");

                // if (data && !!data.id) {
                //   disabledAccessLevel(false);
                //   filterPermissionsList(data);
                // } else {
                //   disabledAccessLevel(true);
                //   filterPermissionsList();
                // }

                onChange(data);
              }}
              value={value}
              getOptionLabel={(option) => option.department || ""}
              getOptionSelected={(option, value) => option?.id === value?.id}
              // disabled={readOnly}
              renderInput={(params) => (
                <LoginTextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Department"
                  readOnly={false}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />

            // <LoginTextField
            //   className={classes.text_field}
            //   variant="outlined"
            //   placeholder="Enter Employee Department"
            //   value={value}
            //   onChange={onChange}
            //   error={!!error}
            //   disabled={loading}
            //   helperText={error ? error.message : null}
            // />
          )}
        />
        <Controller
          name="department_id"
          control={control}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <input type="hidden" value={value} />
          )}
        />

        {showAddDepartment && (
          <div
            style={{ display: "flex", flexDirection: "row", justifyContent:'space-between', paddingTop: 10 }}
          >
            <div style={{ flex: 1 }}>
              <TextField
                className={classes.text_field}
                variant="outlined"
                style={{ width: '100%' }}
                placeholder="Write new department"
                value={newdepartment}
                onChange={handleNewDepartmentChange}
                error={!!newdepartmenterror}
                helperText={newdepartmenterror ? newdepartmenterrormessage : null}
              />
            </div>

            <Button
              onClick={ () => submitNewDepartment() }
              style={{
                fontSize: 12,
                backgroundColor: "#533aed",
                color: "#FFF",
                margin: "5px 5px",
                borderRadius: 25,
                flex:0.6,
                height: 50
              }}
              variant="text"
              disabled={loading}
              disableElevation
            >
              Add department
            </Button>

            <div 
              onClick={() => {
                setShowAddDepartment(false);
                clearNewDepartment();
              }}
              style={{ flex:0.2, display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10, height:50   }}>
              <Button
                style={{
                  backgroundColor:'transparent',
                  height: 50
                }}
              >
                <Close style={{ color:'#533aed' }}/>
              </Button>
            </div>
          </div>
        )}

        {!showAddDepartment ? (
          <div>
            <p
              style={{
                fontStyle: "normal",
                fontWeight: 700,
                fontSize: "14px",
                lineHeight: "40px",
                color: "#533AED",
              }}
              onClick={() => {
                setNewDepartment("");
                setShowAddDepartment(true);
                clearNewDepartment();
              }}
            >
              + Add new department
            </p>
          </div>
        ) : null}

      </div>

      <div style={{ margin: 10 }}>
        <div className={classes.inputlabel}>Access Level</div>
        {/* <Autocomplete
              options={accessLevels}
              value={userAcessLevel}
              onChange={(event, newValue) => {
                setAccessLevel(newValue || {});
              }}
              inputValue={userAcessLevel.name || ''}
              getOptionLabel={(option) => option.name || ''}
              getOptionSelected={(option, value) => option?.profile_name === value?.profile_name }
              // disabled={readOnly}
              renderInput={(params) => (
                  <LoginTextField 
                    {...params} 
                    variant="outlined" 
                    placeholder="Select Access Level"
                    readOnly={false}/>
              )}
            /> */}

        <Controller
          rules={{ required: "Access Level is Required" }}
          defaultValue={returnProfileId(userData?.profile_name) || []}
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <Autocomplete
              options={permissionsList}
              onChange={(_, data) => {
                
                // setValue('location_id', data.location_id);
                // setValue('organization_id', data.id);
                onChange(data);
              }}
              getOptionLabel={(option) => option.description || ""}
              getOptionSelected={(option, value) =>
                option?.profile_name === value?.profile_name
              }
              disabled={accessleveldisabled}
              value={value}
              renderInput={(params) => (
                <LoginTextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Access Level"
                  readOnly={accessleveldisabled}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          )}
          name="profile"
          control={control}
        />
      </div>

      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 20,
        }}
      >
        <Button
          type="submit"
          style={{
            fontSize: 12,
            backgroundColor: "#533aed",
            color: "#FFF",
            margin: "5px 5px",
            borderRadius: 25,
            width: 250,
          }}
          variant="text"
          disabled={loading}
          disableElevation
        >
          <div style={{ padding: "10px 10px" }}>
            {add === true ? "Add User" : "Save Changes"}
          </div>

          {loading ? (
            <div style={{ justifyContent: "center", alignItems: "center" }}>
              <CircularProgress size={20} sx={{ color: "#FFF" }} />
            </div>
          ) : null}
        </Button>
      </div>

      {
        edit === true ?
          <Button
            onClick={() => setOpenDeleteDialog({ open: true, items: userData?.id })}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontWeight: "500",
              fontSize: 13,
              color: "red",
              marginTop: 20,
            }}
          >
            DELETE ACCOUNT
          </Button>
          :
          null
      }

      {openDeleteDialog && (
        <CustomDialog
          open={openDeleteDialog.open}
          handleClose={closeDeleteDialog}
          Title="Delete"
          Content={<span>Do you really want to delete this user?</span>}
          DialogAction={[
            {
              ActionName: "Yes",
              Action: () => deleteUser(),
            },
            {
              ActionName: "No",
              Action: () => closeDeleteDialog(),
            },
          ]}
        />
      )}
    </form>
  );
};

export default AdminUserForm;