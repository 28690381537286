import { Button } from "@material-ui/core";

const AddButton = ({title,callback}) => {
    return (
        <Button onClick={callback}>
            {title}
        </Button>
    )
}

export default AddButton;