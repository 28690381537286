import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import CircularProgress from '@mui/material/CircularProgress';
import { useSelector } from 'react-redux';
import empathowhitelogo from '../../assets/images/empathologo.png';
import Slide from '@mui/material/Slide';

 
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const Loading = ({ title }) => {

    const { loading } = useSelector((state) => state.Loading);
    
    return (
        <Dialog 
            PaperProps={{
                style: {
                    backgroundColor: '#533aed',
                    borderRadius:15,
                    padding:20,
                    minWidth: '15vw',
                    minHeight:'10vh',
                    justifyContent:'center',
                    zIndex:1000
                },
            }}
            TransitionComponent={Transition}
            keepMounted
            onClose={() => null} 
            open={loading}
            maxWidth={'xl'}>
            
            <div style={{ 
                backgroundImage: `url(${empathowhitelogo})`,
                backgroundRepeat: 'no-repeat',        
                backgroundSize: 'contain',
                height: '100px',
                width:'100px',
                left: -20,
                position:'absolute'
            }}/>

            <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>    
                <CircularProgress sx= {{ color:"#FFF" }} />
                <div style={{ textAlign:'center', padding:20, fontSize: 12, color:'#FFF', fontWeight:'bold' }}>
                    {title}
                </div>
            </div>
        
        </Dialog>
    );
};

export default Loading;
