// const endpointURL = "https://c7qkp0z69k.execute-api.us-east-1.amazonaws.com/api/";
// export const endpointURL = "http://localhost:80/api/";
// export const endpointURL = 'https://dev.empatho.com/api/';
// export const endpointURL = 'https://uat-api.empatho.com/api/'
// export const endpointURL = 'https://api.empatho.com/api/';

export const endpointURL = process.env.REACT_APP_ENDPOINT_URL;

export const appConfig = { 
    authenticationPath: `${endpointURL}auth/`,
    organizationPath: `${endpointURL}organization/`,
    locationPath: `${endpointURL}location/`,
    permissionPath: `${endpointURL}permission/`,
    analyticsPath: `${endpointURL}analytics/`,
    wellbeingPath:`${endpointURL}wellbeinganalytics/`,
    covidScreeningPath: `${endpointURL}survey/`,
    faqPath:`${endpointURL}faq/`,
}

