import { SETLOADING } from "../types/loading.types";

const initialState = {
    loading: false
}

export const LoadingReducer = (state=initialState,action) => {
    switch(action.type){
        case SETLOADING:
            return {
                ...state,
                loading: action.payload
            }
        default:
            return state;
    }
}