import React from "react";
import { Grid,Card,Button } from "@material-ui/core";
import useStyles from "./welcome.styles";
import { useHistory } from "react-router-dom";


const WelcomeScreen = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <Grid container className={classes.container} justifyContent="center" alignItems="center">
      <Grid item lg={12} className={classes.container_login}>
        <Grid item lg={4} style={{ width: "100%",display: "flex",justifyContent: "center",alignItems: "center" }}>
          <Card className={classes.card}>
              <div className={classes.detail_box}>
                <Button onClick={() => history.push({pathname: "/verifyaccount",state: {
                    new: true
                }}) }>Get Started</Button>
                <Button onClick={() => history.push({pathname: "/login",state: {
                    new: false
                }}) }>Already have an account? Sign In</Button>
              </div>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default WelcomeScreen;
