import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    '.super-app-theme--header': {
        backgroundColor: 'red'
    },

    '.user-table-headers': {
        fontSize:2
    }
}))

export default useStyles;