import {
    LOGIN,
    LOGOUT, 
    SET_CURRENT_USER,
    SET_PERMISSIONS,
    SET_USER_LIST,
    RESET_AUTH_STATE,
    SET_IMPERSONATING,
    SET_VERIFIED_USER_EMAIL,
    SET_FAQ_LIST,
    UPDATE_ORGANIZATION_LOGO,
    SET_FILE_UPLOAD_PROGRESS
} from "../types/authentication.types";

export const initialState = {
    isLoggedIn: false,
    loading: true,
    user: null,
    permissions: [],
    userlist:[],
    impersonating: false,
    impersonatingprofile_name:"isDomainSuperAdmin",
    impersonatedorganization: null,
    verifieduseremail: '',
    faq_list: [],
    upload_progress: 0
}

export const AuthenticationReducer = (state=initialState,action) => {
    switch(action.type){
        case SET_CURRENT_USER: 
            return {
                ...state,
                isLoggedIn: true,
                user: action.payload
            }
        case LOGIN:
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
            }
        case LOGOUT: 
            return {
                ...state,
                isLoggedIn: action.payload.isLoggedIn,
                loading: true,
                user: null,
                permissions: [],
                userlist:[],
                impersonating: false,
                impersonatingprofile_name:"isDomainSuperAdmin",
                impersonatedorganization:  null
            }
        case SET_PERMISSIONS: 
            return {
                ...state,
                permissions: action.payload.data
            }
        case SET_USER_LIST: 
            return {
                ...state,
                userlist: action.payload.data,
                loading: false
            }
        case SET_IMPERSONATING:
            return {
                ...state,
                impersonating: action.payload.impersonating,
                impersonatedorganization: action.payload.impersonatedorganization,
            }
        case SET_VERIFIED_USER_EMAIL:
            return {
                ...state,
                verifieduseremail: action.payload.verifieduseremail
            }
        case RESET_AUTH_STATE:
            return {
                ...initialState
            }
        
        case SET_FAQ_LIST: 
            return {
                ...state,
                faq_list: action.payload.faq_list
            }
            
        case UPDATE_ORGANIZATION_LOGO:
            return {
                ...state,
                user:{
                    ...state.user,
                    userdata:{
                      ...state.user.userdata,
                      logo: action.payload.logo
                    }
          
                  }
            }
        case SET_FILE_UPLOAD_PROGRESS: 
            return {
                ...state,
                upload_progress: action.payload.upload_progress
            }    
        default:
            return state;
    }
}
