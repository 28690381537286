import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        height: "100%",
        backgroundColor: "#64b5f6"
    },
    card: {
        width: "100%",
        padding: "20px 40px"
    },
    container_login: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    form: {
        display: "flex",
        flexDirection: "column",
    },
    title: {
        fontWeight: "600",
        fontSize: 24
    },
    box: {
        margin: "10px 0px"
    },
    text_field: {
        width: "100%"
    },
    text: {
        fontWeight: 600,
        fontSize: 16,
        margin: "15px 0px"
    },
    login_box: {
        backgroundColor: "#eeeeee",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    detail_box: {
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection:"column"
    },
    link: {
        textDecoration: "none"
    }
})

export default useStyles;