import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import './assets/fonts/Inter-VariableFont_slnt,wght.ttf';
import './assets/fonts/sofia-pro-cdnfonts/SofiaProRegularAz.ttf';
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PersistGate } from "redux-persist/integration/react";
//Theme
import { ThemeProvider } from "@material-ui/core";
import theme from "./styles/theme";

//Redux
import { Provider } from "react-redux";
import { store, persistor }  from "./redux/store/rootStore";

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <PersistGate persistor={persistor}>
          <App />
      </PersistGate>
    </ThemeProvider>
  </Provider>,
  document.getElementById("root")
);

reportWebVitals();
