
import React,{ useState, useEffect, useRef } from 'react';
import useStyles from "./App.styles";

//Redux
import {useSelector,useDispatch} from "react-redux"

//Routers
import AuthenticationRoutes from "./routes/authenticationRoutes.jsx";
import AppRoutes from "./routes/appRoutes.jsx";
import { setCurrentUser } from './redux/actions/authentication.action';
import Loading from './components/loading/loading';
import IdleTimer from 'react-idle-timer'
import { setLogout, resetAuthState } from './redux/actions/authentication.action';
import { persistor }  from "./redux/store/rootStore";
import { useHistory } from "react-router-dom";

const App = (props) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const idleTimer = useRef(null);
  const { user } = useSelector((state) => state.Authentication);
  const [timeout, setIdleTimeout] = useState(1000 * 3600 * 1);
  const [isTimedOut, setIsTimedOut] = useState(false);

  const onAction = (e) => {
    
    setIsTimedOut(false);
  }
 
  const onActive = (e) => {
    
    setIsTimedOut(false);
  }
 
  const onIdle = (e) => {
    
    const timedOut = isTimedOut
    if (isTimedOut) {
        
        dispatch(
          setLogout(user?.email, user?.accesstoken, async () => {
                        
            dispatch(resetAuthState());  
            
            localStorage.removeItem("currentUser");
            localStorage.removeItem("persist:Authentication");
            localStorage.clear();

            await persistor.flush();
            await persistor.purge();    

          })
        );
    } else {

      
      idleTimer.current.reset();
      
      setIsTimedOut(true);
    }
    
  }

  useEffect(() => {
    
    if(localStorage.currentUser){
    
      const _user = JSON.parse(localStorage.currentUser);
      dispatch(setCurrentUser(_user?.code,_user?.email, _user?.userdata));
    
    }

  }, [dispatch])

  return (
    <div className={classes.container}>
      <Loading/>
      <AppRoutes/>

      <IdleTimer
        // ref={ref => { this.idleTimer = ref }}
        ref={idleTimer}
        element={document}
        onActive={onActive}
        onIdle={onIdle}
        onAction={onAction}
        debounce={250}
        timeout={timeout} />
    </div>
  );
}

export default App;
