import React, { useState, useEffect } from "react";
import useStyles from "./formSettings.styles";
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import { adminCreateUser, adminUpdateUser } from "../../../redux/actions/authentication.action";
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import { alpha, styled } from '@mui/material/styles';
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathoactionableresults from '../../../assets/images/actionableresults.png'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../../redux/actions/loading.action";
import CircularProgress from '@mui/material/CircularProgress';
import { getUsers } from "../../../redux/actions/authentication.action";
import Autocomplete from '@mui/material/Autocomplete';

const FormSettings = ({ ProfileData, closeProfileDialog, edit }) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, impersonating, impersonatedorganization, permissions } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading]= useState(false);
  const [organization, setOrganization] = useState(false);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);
  const [permissionsList, setPermissionsList] = useState(permissions);


  const returnOrganization = (organization_id) => {
    
    let organizationobject = organizations.filter(organization => organization.id == organization_id );
    
    if (organizationobject.length > 0){
      return organizationobject[0]
    }
  }

  const filterPermissionsList = (data) => {
    if (data && !!data.id) {
      let org = returnOrganization(data.id)
      if (org.default === true ) {
        setPermissionsList(permissions)  
      }
      else {
        let filteredList = permissions.filter( permission => permission.name !== 'isSuperAdmin' && permission.name !== 'isGlobalAdmin')
        setPermissionsList(filteredList);
      }
    }
    else {
      setPermissionsList(permissions);
    }

  }

  const returnProfileId = (userAcessLevel) => {
    
    let profileid = permissions.filter( permission => permission.name === userAcessLevel)[0];
  
    return profileid
  }

  useEffect(() => {

    if (edit){
      
      // setValue("email", ProfileData.userdata.user_email);
      // setValue("user_email", ProfileData.email);
      // setValue("first_name", ProfileData.userdata.first_name);
      // setValue("last_name", ProfileData.userdata.last_name);
      // setValue("location_id", ProfileData.userdata.location_id);
      // setValue("organization_id", ProfileData.userdata.organization_id);
      // setValue("organization_name", returnOrganization(ProfileData.userdata.organization_id).name);
      // setValue("profile_id", returnProfileId(ProfileData.userdata.profile_name));
      // setValue("is_active", ProfileData.userdata.is_active);
      // filterPermissionsList(returnOrganization(ProfileData.userdata.organization_id));
      disabledAccessLevel(false);
    }

  },[user])


  const submitAddEditOrganization = (data) => {
    
      let body = { 
        "email": user?.userdata?.user_email,
        "user_email": data.email,
        "first_name": data.first_name,
        "last_name": data.last_name,
        "location_id": data.location_id,
        "organization_id": data.organization_id,
        "profile_id": returnProfileId(data.profile.name).id,
        "is_active": true

      }
      setActionLoading(true);

      dispatch(adminUpdateUser(
        body, 
        () => {
          dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization))
          setActionLoading(false);
          closeProfileDialog()

        },
        () => {
          setActionLoading(false);
        }  
      ))

    }

  

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInputBase-input':{
      height:15,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });

  return (

      <form className={classes.form} onSubmit={handleSubmit(submitAddEditOrganization)}>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Organization
            </div>

            <Controller
              name="organization_id"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <input
                  type="hidden"
                  value={value}
                />
              )}
          />

            <Controller
              name="organization_name"
              control={control}
              defaultValue=""
              rules={{ required: "Organization title is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  disabled={true}
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Organization Title"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                First Name
            </div>

            <Controller
              name="first_name"
              control={control}
              defaultValue=""
              rules={{ required: "First name is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

     
          <div style={{ margin:10 }}>

          <div className={classes.inputlabel}>
              Last Name
          </div>

      <Controller
      name="last_name"
      control={control}
      defaultValue=""
      rules={{ required: "Last name is required" }}
      render={({
        field: { onChange, value },
        fieldState: { error },
          }) => (
        <LoginTextField
          className={classes.text_field}
          variant="outlined"
          placeholder="Last Name"
          value={value}
          onChange={onChange}
          error={!!error}
          disabled={loading}
          helperText={error ? error.message : null}
        />
      )}
        />
    </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Employee Email
            </div>

            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: "Employee email is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Employee email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={true}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Access Level
            </div>

            <Controller
              defaultValue={returnProfileId(ProfileData.userdata?.profile_name || [])}
              rules={{ required: "Access level is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <Autocomplete
                    options={permissionsList}
                    onChange={(_, data) => {
                      
                      onChange(data)
                    }}
                    getOptionLabel={(option) => option.description || ''}
                    getOptionSelected={(option, value) => option?.profile_name === value?.profile_name }
                    disabled={true}
                    value={value}
                    renderInput={(params) => (
                        <LoginTextField 
                          {...params} 
                          variant="outlined" 
                          placeholder="Access Level"
                          readOnly={accessleveldisabled}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                    )}
                  />
              )}
              name="profile"
              control={control}
            />
          </div>

          <br/>
      
          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
            <Button 
              type="submit"
              style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 250 }}
              variant="text"  
              disabled={loading}
              disableElevation>
              <div style={{ padding:"10px 10px" }}>
                { edit ? 'Save' : 'Save Changes' }
              </div>
              {
                loading ?
                  <div style={{ justifyContent:'center', alignItems:'center' }}>
                    <CircularProgress 
                      size={20}
                      sx= {{ color:"#FFF" }}
                    />
                  </div>
                :
                null
              }

            </Button>
          </div>
      </form>    

  );
};

export default FormSettings;
