import { axiosService as axios } from "../../helpers/utils-authentication";
import { setSnackBar } from "./snackbar.action";
import { appConfig } from "../../config/appconfig";
import { store } from '..//store/rootStore';
import { setShowLoading } from "./loading.action";
import { SET_RETURN_TO_WORK_DATA, SET_WELLBEING_DATA } from "../types/analytics.types";

export const getReturnToWorkData = (email) => {
  
    let state = store.getState();
  
    return async (dispatch) => {
      return axios
        .get(
          `${appConfig.analyticsPath}getreturntoworkanalytics`,
            {
              params: {
                email: email,
              }
            }
          )
        .then((resp) => {
  
          dispatch({
            type: SET_RETURN_TO_WORK_DATA,
            payload: {
              data: resp.data.data,
            },
          });
        })
        .catch((error) => {
          
          // const { message } =
          //   error && error.response.data && error.response.data.error;
          // dispatch(
          //   setSnackBar({
          //     snackType: "error",
          //     snackbarMessage: message,
          //     key: "4",
          //     open: true,
          //   })
          // );
        });
    };
  };
  


  export const getWellbeingData = (email, filters, timezone) => {
  
    let state = store.getState();

    return async (dispatch) => {

      dispatch(setShowLoading(true));

      return axios
        .get(
          `${appConfig.wellbeingPath}getwellbeinganalytics`,
            {
              params: {
                email: email,
                filters: filters,
                timezone: timezone
              }
            }
          )
        .then((resp) => {
  
          dispatch({
            type: SET_WELLBEING_DATA,
            payload: {
              data: resp.data.data,
            },
          });
          dispatch(setShowLoading(false));

        })
        .catch((error) => {
  
          dispatch(setShowLoading(false));
          // const { message } =
          //   error && error.response.data && error.response.data.error;
          // dispatch(
          //   setSnackBar({
          //     snackType: "error",
          //     snackbarMessage: message,
          //     key: "4",
          //     open: true,
          //   })
          // );
        });
    };
  };
  