// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./assets/fonts/Inter-VariableFont_slnt,wght.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./assets/fonts/sofia-pro-cdnfonts/SofiaProRegularAz.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #EEE;\n}\n\n@font-face {\n  font-family: 'Inter-Regular';\n  src: local(\"Inter-Regular\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\n  font-weight: normal;\n}\n@font-face {\n  font-family: 'Sofia-Pro';\n  src: local(\"Sofia-Pro\"),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\n  font-weight: normal;\n}", "",{"version":3,"sources":["webpack://src/index.css"],"names":[],"mappings":"AAAA;EACE,SAAS;EACT;;cAEY;EACZ,mCAAmC;EACnC,kCAAkC;EAClC,sBAAsB;AACxB;;AAEA;EACE,4BAA4B;EAC5B;8DAC4E;EAC5E,mBAAmB;AACrB;AACA;EACE,wBAAwB;EACxB;8DACmF;EACnF,mBAAmB;AACrB","sourcesContent":["body {\n  margin: 0;\n  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',\n    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',\n    sans-serif;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n  background-color: #EEE;\n}\n\n@font-face {\n  font-family: 'Inter-Regular';\n  src: local(\"Inter-Regular\"),\n    url(\"./assets/fonts/Inter-VariableFont_slnt\\,wght.ttf\") format(\"truetype\");\n  font-weight: normal;\n}\n@font-face {\n  font-family: 'Sofia-Pro';\n  src: local(\"Sofia-Pro\"),\n    url(\"./assets/fonts/sofia-pro-cdnfonts/SofiaProRegularAz.ttf\") format(\"truetype\");\n  font-weight: normal;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
