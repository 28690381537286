import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles( theme => ({
    button: {
        background: "#FFF",
        color: theme.palette.primary.main,
        padding:15,
        fontSize:'1em',
        borderRadius:30
    }
}))

export default useStyles;