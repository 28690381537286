import useDrawerStyles from "./drawer.styles";
import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import Badge from "@mui/material/Badge";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import NotificationsIcon from "@mui/icons-material/Notifications";
import empathologo from "../../../assets/images/Empatho_logo.png";
import uatimage from "../../../assets/images/uatimage.png";
import devimage from "../../../assets/images/devimage.png";
import qaimage from "../../../assets/images/qaimage.png";
import InputAdornment from "@mui/material/InputAdornment";
//Components
import DrawerItems from "../drawer-items/drawerItems.component";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";

//Redux
import { useSelector, useDispatch } from "react-redux";
import { setToogleMenu } from "../../../redux/actions/navigation.action";
import { BrowserRouter, Route, useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import empathopurplelogo from "../../../assets/images/empathopurplelogo.png";
import { endpointURL } from "../../../config/appconfig";

const drawerWidth = 220;

const openedMixin = (theme) => ({
  width: drawerWidth,
  height: 100,
  background: "linear-gradient(223.66deg, #58D8F2 -31.58%, #583AED 63.08%)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  background: "linear-gradient(223.66deg, #58D8F2 -31.58%, #583AED 63.08%)",
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  // width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    // width: `calc(${theme.spacing(9)} + 1px)`,
    width: drawerWidth,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const CustomDrawer = (props) => {
  const classes = useDrawerStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const { user, permissions, impersonating, impersonatedorganization } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);

  const returnEnvironment = () => {
    if (
      endpointURL === "https://dev.empatho.com/api/" ||
      endpointURL === "http://localhost:80/api/"
    ) {
      return {
        image: devimage,
        env: "dev",
      };
    } else if (endpointURL === "https://uat-api.empatho.com/api/") {
      return {
        image: uatimage,
        env: "uat",
      };
    } else {
      return null;
    }
  };

  const returnOrganization = (organization_id) => {
    let organizationobject = organizations.filter(
      (organization) => organization.id == organization_id
    );

    if (organizationobject.length > 0) {
      return organizationobject[0];
    }
  };

  return (
    <Drawer variant="permanent" open={open}>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // flexDirection: "row",
        }}
      >
        <img
          src={empathologo}
          style={{ width: "120px", height: 'auto', marginTop: 40 }}
        />
      </div>

      <DrawerItems />
    </Drawer>
  );
};

export default CustomDrawer;
