// import axios from "axios";
import { axiosService as axios } from "../../helpers/utils-authentication";
import { 
  LOGIN, 
  SET_CURRENT_USER, 
  LOGOUT, 
  SET_PERMISSIONS,
  SET_USER_LIST,
  RESET_AUTH_STATE,
  SET_IMPERSONATING,
  SET_VERIFIED_USER_EMAIL,
  SET_FAQ_LIST,
  UPDATE_ORGANIZATION_LOGO,
  SET_FILE_UPLOAD_PROGRESS
} from "../types/authentication.types";
import { setSnackBar } from "./snackbar.action";
import FormData from "form-data";
import { appConfig } from "../../config/appconfig";
import { getProvinces  } from "./provinces.action";
import { getOrganization } from "./organization.action";
import { setShowLoading } from "./loading.action";
import { store } from "../store/rootStore";

export const setCurrentUser = (code, email, userdata) => {
  
  return {
    type: SET_CURRENT_USER,
    payload: {
      accesstoken:code,
      email:email,
      userdata: userdata
    },
  };
};


export const setImpersonating = (impersonating, organization) => {
  return {
    type: SET_IMPERSONATING,
    payload:{
      impersonating: impersonating,
      impersonatedorganization: organization
    }
  }
}

export const updateOrganizationLogo = (url) => {
  return {
    type: UPDATE_ORGANIZATION_LOGO,
    payload:{
        logo: url
    }
  }
}


export const setFileUploadProgress = (val) => {
  return {
    type: SET_FILE_UPLOAD_PROGRESS,
    payload:{
        upload_progess: val
    }
  }
}

export const setLogin = (email, password, successCallback) => {
  return async (dispatch) => {
    const body = {
      email,
      password,
    };

    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}signin`,
        body
      )
      .then((response) => {
        clearTimeout(timeout);

        const { success, userdata } = response.data;

        if (success) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              accesstoken: userdata.access_token,
              refreshtoken: userdata.refresh_token,
              email: userdata.user_email,
              userdata: userdata
            })
          );

          dispatch(setShowLoading(false));
          dispatch({ type: LOGIN, payload: { isLoggedIn: true } });
          dispatch(setCurrentUser(userdata.access_token, userdata.user_email, userdata ));
          dispatch(getProvinces(userdata.user_email));
          dispatch(getOrganization(userdata.user_email));
          dispatch(getFAQ(userdata.user_email))
          successCallback()

        }
        else {

          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: response.data.error.message,
              key: "4",
              open: true,
            })
          );

        }
      })
      .catch((error) => {

        dispatch(setShowLoading(false));
        if (error && error.response) {
          
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: 'Unable to authenticate user',
              key: "4",
              open: true,
            })
          );
        }
      });
  };
};

export const setVerifyAccount = ( code, cb) => {
  return async (dispatch) => {
    const body = {
      code:code.toUpperCase()
    };

    return await axios
      .post(
        `${appConfig.authenticationPath}verify`,
        body
      )
      .then((response) => {
        
        dispatch({ type: SET_VERIFIED_USER_EMAIL, payload: { verifieduseremail: response.data.user_email } });

        cb(response);
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const setSignUp = (data, cb) => {
  return async (dispatch) => {
    const { firstname, lastname, email, password } = data;

    const body = {
      email,
      password,
      name: {
        firstname,
        lastname,
      },
    };

    return axios
      .post(
        `${appConfig.authenticationPath}signup`,
        body
      )
      .then((response) => {
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage:
              "Congratulation, You have just registered an account !",
            key: "1",
            open: true,
          })
        );
        cb();
      })
      .catch((error) => {
        if(error.message){
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: error.message,
              key: "4",
              open: true,
            })
          );
          return;
        }

        const { message } = error && error?.response?.data && error?.response?.data?.error;
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: message,
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const setResetPassword = (email, successCallback) => {
  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    const body = {
      email,
    };

    return axios
      .post(
        `${appConfig.authenticationPath}forgotpassword`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {
        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Please check your email for instructions to reset your password",
            key: "4",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.message || "Unable to Reset Password",
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const setLogout = (email, code, successCallback) => {
  return async (dispatch) => {
    const body = {
      email,
      accesstoken: code,
    };

    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}signout`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {
        clearTimeout(timeout);
        const { success } = response.data;
        if (success) {

          dispatch({
            type: LOGOUT,
            payload: {
              isLoggedIn: false,
            },
          });

        }
        successCallback();
      })
      .catch((error) => {

        dispatch({
          type: LOGOUT,
          payload: {
            isLoggedIn: false,
          },
        });
      
        successCallback()

        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};

export const setNewPassword = ({ email, password, code }, successCallback) => {
  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    const body = {
      email,
      password,
      code,
    };
    return axios
      .post(
        `${appConfig.authenticationPath}confirmforgotpassword`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {
        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Password Changed Succesfully",
            key: "4",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
        const { message } =
          error && error.response.data && error.response.data.error;
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: message,
            key: "4",
            open: true,
          })
        );
      });
  };
};


export const createPassword = (email, password, successCallback) => {
  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    const body = {
      email: email,
      proposedpassword: password
    };
    
    return axios
      .post(
        `${appConfig.authenticationPath}createpassword`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {
        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Password Created Succesfully",
            key: "4",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
        const { message } =
          error && error.response.data && error.response.data.error;
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: message,
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const getPermission = (email) => {
  return async (dispatch) => {
    return axios
      .get(`${appConfig.permissionPath}getpermissions?email=${email}`)
      .then((response) => {
        
        dispatch({
          type: SET_PERMISSIONS,
          payload: {
            data: response?.data?.data,
          },
        });
      });
  };
};


export const getUsers = (email, organization_id, impersonating, impersonatedorganization) => {
  return async (dispatch) => {
      
      return axios.get(
        `${appConfig.authenticationPath}getusers`, 
          { params: { email: email, organization_id: organization_id, impersonating:impersonating , impersonating_organization_id: impersonatedorganization } })
          .then((response) => {
                  
          dispatch({
              type: SET_USER_LIST,
              payload: {
                  data: response.data.users
              }
          })
      }).catch((error) => {

      })
  }
}

export const adminCreateUser = (body, successCallback, errorCallback) => {

  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}registeruser`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "User Successfully Created",
            key: "4",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
        
        errorCallback();
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error.response.data.error.message || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };

}


export const resendVerificationCode = (email, successCallback) => {
  return async (dispatch) => {
    const body = {
      email: email,
    };
    return axios
      .post(
        `${appConfig.authenticationPath}resendverification`,
        body
      )
      .then((response) => {

        if (response.data.success) {
          dispatch(
            setSnackBar({
              snackType: "success",
              snackbarMessage: "Verification Code has been sent successfully",
              key: "4",
              open: true,
            })
          );
          successCallback();
        }
       
      })
      .catch((error) => {

        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error.response.data.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };
};

export const adminUpdateUser = (body, successCallback, errorCallback) => {
  
  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}adminupdateuser`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "User Successfully Updated",
            key: "4",
            open: true,
          })
        );

        successCallback();
      })
      .catch((error) => {
   
        errorCallback();
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };

}


export const uploadCSV = (email, file, organization_id, location_id, cb, errCb, cbProgress) => {

  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    let formData = new FormData();
    
    formData.append('email', email);
    formData.append('upload_organization_id', organization_id);
    formData.append('file', file);
    return axios
      .post(
        `${appConfig.authenticationPath}uploadcsv`,
        formData,
        { 
          headers:{ 'Content-Type': 'multipart/form-data' },
          cancelToken: abort.token,
          onUploadProgress: progressEvent => { 
            let progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            cbProgress(progress)
          }
        }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "File Successfully Processed and Users Created",
            key: "4",
            open: true,
          })
        );
        cb();
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.message || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

        if (error?.response.data){
          errCb(error.response.data.message)
        }
        
      });
  };

}

export const deactivateAccount = (data, successCallback) => {
  return async (dispatch) => {
    const body = {
      email: data.email,
      user_email: data.email
    };
    return axios
      .post(`${appConfig.authenticationPath}deactivateuser`, body)
      .then(async response => {
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: response.data.statusMessage + ", the account is deleted after 30 days.",
            key: "4",
            open: true,
          })
        );
        
        successCallback();
      })
      .catch(error => {
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );
        
      });
  };
};

export const setRefreshToken = (email, token, successCallback) => {
  return async (dispatch) => {
    const data = {
      email,
      token,
    };
    return axios
      .post(
        `${appConfig.authenticationPath}refreshsession`,
        data
      )
      .then((response) => {
        const { userdata, success } = response.data;
        if (success) {
          localStorage.setItem(
            "currentUser",
            JSON.stringify({
              code: userdata.access_token,
              email: userdata.user_email,
              refreshToken: JSON.parse(localStorage.getItem("currentUser"))
                .refreshToken,
              expiryTime: userdata.expiry_time,
            })
          );
          dispatch(getFAQ(userdata.user_email));
          
          successCallback();
          return;
        }
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: "Error Updating Token",
            key: "4",
            open: true
          }))
        });
    }
};

export const resetAuthState = () => {
  return {
    type: RESET_AUTH_STATE,
    payload: {}
  }
}



export const resendInvitation = (body, successCallback, errorCallback) => {

  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}resendinvitation`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Invitation Successfully Resent",
            key: "4",
            open: true,
          })
        );

        successCallback();
      })
      .catch((error) => {
   
        errorCallback();
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };

}


export const unInviteUser = (body, successCallback, errorCallback) => {

  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}uninviteuser`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Successfully Uninvited User",
            key: "4",
            open: true,
          })
        );

        successCallback();
      })
      .catch((error) => {

        errorCallback();
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };

}


export const getFAQ = (email) => {
  
  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.faqPath}getfaq`,
          {
            params: {
              email: email,
              faq_type: 'adminportal'
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: SET_FAQ_LIST,
          payload: {
            faq_list: resp.data.faq,
          },
        });
      })
      .catch((error) => {

      });
  };
};



export const adminDeleteUser = (data, successCallback) => {
  return async (dispatch) => {
    const body = {
      email: data.email,
      user_email: data.user_email,
      user_id: data.user_id,
    };
    return axios
      .post(`${appConfig.authenticationPath}admindeleteuser`, body)
      .then(async response => {
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "User Deleted",
            key: "4",
            open: true,
          })
        );
        
        successCallback();
      })
      .catch(error => {
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );
        
      });
  };
};


export const bulkResendInvitation = (body, successCallback, errorCallback) => {

  return async (dispatch) => {
    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 40000);

    return axios
      .post(
        `${appConfig.authenticationPath}bulkresendinvitation`,
        body,
        { cancelToken: abort.token }
      )
      .then((response) => {

        clearTimeout(timeout);
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Invitations Successfully Resent",
            key: "4",
            open: true,
          })
        );

        successCallback();
      })
      .catch((error) => {
   
        errorCallback();
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );

      });
  };

}