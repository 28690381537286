import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import CustomSnackBar from "../components/snackbar/snackbar.component";

//Screens
import Login from "../screens/authentication/login/login";
import Register from "../screens/authentication/register/register";
import ResetPassword from "../screens/authentication/reset-password/resetPassword";
import WelcomeScreen from "../screens/authentication/welcome/welcome";
import NewPassword from "../screens/authentication/new-password/newPassword";
import ResendPassword from "../screens/authentication/resend-verification/resendVerification";
import VerifyAccount from "../screens/authentication/verifyaccount/verifyaccount";
import CreatePassword from "../screens/authentication/createpassword/createpassword";
import NoMatch from "../screens/nomatch/nomatch";

const AuthenticationRoutes = () => {
  return (
    <Router>
        <Switch>
          <Redirect exact from="/" to="/login" />
          <Route exact path="/login" component={Login}></Route>
          <Route exact path="/register" component={Register}></Route>
          <Route exact path="/resetpassword" component={ResetPassword}></Route>
          <Route exact path="/verifyaccount" component={VerifyAccount}></Route>
          <Route exact path="/createpassword" component={CreatePassword}></Route>
          <Route exact path="/newpassword" component={NewPassword}></Route>
          <Route exact path="/resendcode" component={ResendPassword}></Route>
          <Route exact path="/unauthorized" component={NoMatch}></Route>
        </Switch>
        <CustomSnackBar />
    </Router>
  );
};

export default AuthenticationRoutes;
