import { TOOGLE_MENU } from "../types/navigation.types";

const initialState = {
    toogleMenu: false
}

export const NavigationReducer = (state=initialState,action) => {

    switch(action.type){
        case TOOGLE_MENU:
            return {
                ...state,
                toogleMenu: !state.toogleMenu
            }
        default:
            return state;
    }
}