import React, { useState, useEffect } from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { styled } from "@mui/material/styles";
import useStyles from "../../screens/organizations/organizations.styles";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import GarbageScreening from "../../assets/images/garbage-screening.png";
import Divider from "@mui/material/Divider";

const CovidQuestions = ({ id, question, callback, onDeleteCallBack }) => {
  
  const classes = useStyles();
  const { handleSubmit, control, setValue, getValues } = useForm({
    defaultValues: question,
  });
  const [loading, setActionLoading] = useState(false);

  const [radioBtn, setradioBtn] = useState("Option 1");
  const [changeToTextField, setChangeToTextField] = useState(false);

  const handleChange = (event) => {
    setradioBtn(event.target.value);
    setChangeToTextField(true);
  };

  const RadioButton = styled(RadioGroup)({
    "& .css-vqmohf-MuiButtonBase-root-MuiRadio-root.Mui-checked": {
      color: "#533AED",
    },
  });

  const LoginTextField = styled(TextField)({
    "label + &": {
      color: "pink",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInputBase-input": {
      height: 15,
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#000",
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA",
      },
      "&:hover fieldset": {
        borderColor: "#C9C9C9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#533aed",
      },
      "& input#mui-696684508": {
        textAlign: "center",
        fontSize: 10,
        letterSpacing: 1.5,
      },
      "& input#mui-257838946": {
        textAlign: "center",
        fontSize: 10,
        letterSpacing: 1.5,
      },
    },
  });

  return (
    <Grid
      item
      lg={12}
      md={12}
      xs={12}
      style={{
        width: "100%",
      }}
    >
      <Divider style={{ paddingTop: 20 }} />
      <Grid style={{ padding: "10px 55px", borderLeft: "7px solid #533AED" }}>
        <Grid item lg={12} md={12} xs={12} style={{ paddingBottom: 5 }}>
          Question {id + 1}
        </Grid>
        <Controller
          name="question1"
          control={control}
          defaultValue=""
          rules={{
            required: "question is required",
          }}
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <LoginTextField
              className={classes.text_field}
              variant="outlined"
              placeholder="Enter a screening question here"
              value={value}
              onChange={onChange}
              error={!!error}
              disabled={loading}
              helperText={error ? error.message : null}
            />
          )}
        />
        <FormControl component="fieldset" style={{ width: "100%" }}>
          {changeToTextField ? (
            <>
              <TextField
                id="standard-basic"
                label="Add option"
                variant="standard"
              />
              <TextField
                id="standard-basic"
                label="Add option"
                variant="standard"
              />
            </>
          ) : (
            <RadioButton
              aria-label="gender"
              name="controlled-radio-buttons-group"
              value={radioBtn}
              onChange={handleChange}
            >
              <FormControlLabel
                value="Option1"
                control={<Radio />}
                label="Option1"
              />
              <FormControlLabel
                value="Add option"
                control={<Radio />}
                label="Add option"
              />
            </RadioButton>
          )}
        </FormControl>
        <Grid
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
            paddingTop: 15,
          }}
        >
          <Grid style={{ flex: 1 }}>
            <span>Select the correct answer</span>
          </Grid>
          <Grid style={{ justifyContent: "flex-end", display: "flex" }}>
            <Button onClick={() => onDeleteCallBack()}>
              <img
                src={GarbageScreening}
                style={{ width: 18, height: 18, marginLeft: "auto" }}
              />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        item
        lg={12}
        md={12}
        xs={12}
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          paddingTop: 30,
        }}
      >
        <Button
          onClick={() => callback()}
          variant="contained"
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            width: "50%",
            backgroundColor: "white",
            color: "#533AED",
            fontSize: 14,
            paddingTop: 10,
            paddingBottom: 10,
            borderRadius: 1,
            borderColor: "black",
            disableElevation: true,
          }}
        >
          ADD A QUESTION
        </Button>
      </Grid>
    </Grid>
  );
};

export default CovidQuestions;
