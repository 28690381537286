import React from 'react';
import Button from '@material-ui/core/Button';

//Icons and Style
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import useStyles from "./registerBtn.styles"

const RegisterBtn = () => {

    const classes = useStyles();

    return (
        <Button
        variant="contained"
        color="default"
        className={classes.button}
        endIcon={<ArrowForwardIosIcon />}
        type="submit"
      >
        Register
      </Button>
    )
}

export default RegisterBtn;