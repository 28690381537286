import { makeStyles,createStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => 
    createStyles({
        search: {
            borderRadius: theme.shape.borderRadius,
            position: "relative",
            backgroundColor: "#808080a6",
            marginLeft: 0,
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                marginLeft: theme.spacing(1),
                width: "auto"
            }
        },
        searchIcon: {
            padding: theme.spacing(0,2),
            height: "100%",
            position: "absolute",
            pointerEvents: "none",
            display: "flex",
            alignItems: 'center',
            justifyContent: "center"
        },
        inputRoot: {
            color: "inherit"
        },
        input: {
            padding: theme.spacing(1,1,1,0),
            paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
            transition: theme.transitions.create("width"),
            width: "100%",
            [theme.breakpoints.up("sm")]: {
                width: '12ch',
                '&:focus': {
                    width: '20ch'
                }
            }
        }
}))

export default useStyles;