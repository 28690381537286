import React, { useState, useEffect } from "react";
import { Grid, Button } from "@mui/material";
import Clock from "../../components/clock/clock.component";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const NodataCountdown = () => {

  const { user } = useSelector((state) => state.Authentication);
  const { onboarding_tour_data } = useSelector( (state) => state.Organization );

  const date = onboarding_tour_data?.countdown_end || new Date();
  const [logoURL, setLogoURL] = useState(null);

  useEffect(() => {
    if (user?.userdata?.logo && user?.userdata?.logo !== null) {
      setLogoURL(user?.userdata?.logo);
    }
    return () => {};
  }, [user]);

  const history = useHistory();
  const [timerDays, setTimerDays] = useState();
  const [timerHours, setTimerHours] = useState();
  const [timerMinutes, setTimerMinutes] = useState();
  const [timerSeconds, setTimerSeconds] = useState();

  let interval;

  const startTimer = () => {
    const end = moment(onboarding_tour_data?.countdown_end);
    const now = moment(onboarding_tour_data?.now);

    interval = setInterval(() => {

      const distance = end.valueOf() - moment().valueOf();
      const days = isNaN(Math.floor(distance / (24 * 60 * 60 * 1000))) ? 0 :  Math.floor(distance / (24 * 60 * 60 * 1000)) ;
      const hours = isNaN(Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60))) ? 0 : Math.floor((distance % (24 * 60 * 60 * 1000)) / (1000 * 60 * 60));
      const minutes = isNaN(Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60))) ? 0 : Math.floor((distance % (60 * 60 * 1000)) / (1000 * 60));
      const seconds = isNaN(Math.floor((distance % (60 * 1000)) / 1000)) ? 0 : Math.floor((distance % (60 * 1000)) / 1000);

      if (distance < 0) {
        // Stop Timer
        clearInterval(interval.current);
      } else {
        // Update Timer
        setTimerDays(days);
        setTimerHours(hours);
        setTimerMinutes(minutes);
        setTimerSeconds(seconds);
      }
    });
  };

  useEffect(() => {
    startTimer();
  });

  return (
    <Grid
      direction="column"
      style={{ alignItems:"center", justifyContent:'center', flexDirection:'column', height: '100vh' }}
     >

      <Grid
        item
        xs={false}
        sm={false}
        md={10}
        lg={12}
        style={{ display:'flex', flexDirection:'row', alignItems:"flex-start", justifyContent:'flex-end', paddingTop:15 }}
      >
        {
          logoURL !== null ?
            <img
              alt="not found"
              width={"150px"}
              // height={"40px"}
              src={logoURL + "?" + new Date()}
            />
            :
            null
        }
      </Grid>

      <Grid>
        <div
          style={{
            fontSize: 18,
            fontWeight: 600,
            color: "#23262F",
            textAlign: "center",
            paddingTop: 180,
            fontFamily:'Sofia-Pro'
          }}
        >
          TIME REMAINING
        </div>
        <div
          style={{
            textAlign: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            color: "#F2F2F2",
            fontWeight: 400,
          }}
        >
          <Clock
            days={timerDays}
            hours={timerHours}
            minutes={timerMinutes}
            seconds={timerSeconds}
          />
        </div>
      </Grid>
      <div
        style={{
          fontWeight: 700,
          fontSize: 28,
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          paddingTop: 100,
          justifyContent: "center",
        }}
      >
        Get ready for your team’s wellbeing assessment
      </div>
      <div
        style={{
          position: "absolute",
          width: "617.88px",
          left: "621px",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHHeight: "17px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          color: "#9E9E9E",
          paddingTop: 20,
        }}
      >
        Empatho’s dashboard will show you data-driven insights to help you
        maximize your employees’ wellbeing after gathering one week of data from
        users’ mobile apps.
      </div>
      <div
        style={{
          position: "absolute",
          width: "617.88px",
          left: "621px",
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHHeight: "17px",
          display: "flex",
          alignItems: "center",
          textAlign: "center",
          color: "#9E9E9E",
          paddingTop: 70,
        }}
      >
        In the meantime, we invite you to take a quick tour to familiarize
        yourself with the key metrics you will see on the dashboard.
      </div>
      <div
        style={{
          paddingTop: 140,
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Button
          onClick={() => history.push("/onboardingtour")}
          style={{
            backgroundColor: "#533AED",
            borderRadius: 25,
            color: "#FFFFFF",
            width: 289,
            height: 50,
          }}
        >
          TAKE THE TOUR
        </Button>
      </div>
    </Grid>
  );
};

export default NodataCountdown;
