import React, { useState, useEffect, useRef } from "react";
import useStyles from "./organizationForm.styles";
import TextField from '@mui/material/TextField';
// import OrganizationSelect from "../../select/select-organization.component";
// import StatusSelect from "../../select/select-status.component";
import { getProvinces } from "../../redux/actions/provinces.action";
import { useSelector, useDispatch } from "react-redux";
import SaveButton from "../buttons/save-button/saveButton.component";
import { getOrganization, updateOrganization } from "../../redux/actions/organization.action";
import { adminCreateUser, adminUpdateUser } from "../../redux/actions/authentication.action";
import { setSnackBar } from "../../redux/actions/snackbar.action";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import { alpha, styled } from '@mui/material/styles';
import empathowhitelogo from '../../assets/images/empathowhitelogo.png';
import empathoactionableresults from '../../assets/images/actionableresults.png'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../redux/actions/loading.action";
import CircularProgress from '@mui/material/CircularProgress';
import { getUsers } from "../../redux/actions/authentication.action";
import Autocomplete from '@mui/material/Autocomplete';
import AddIcon from '@mui/icons-material/Add';
import { addOrganization, getOrganizationAdmins } from "../../redux/actions/organization.action";
import EditIcon from "@mui/icons-material/Edit";


const OrganizationForm = ({ organizationData, add, closeAddOrganizationDialog, edit }) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, permissions, impersonating, impersonatedorganization } = useSelector((state) => state.Authentication);
  const { organizations, organization_admins} = useSelector((state) => state.Organization); 
  const { provinces } = useSelector((state) => state.Provinces);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading]= useState(false);
  const [organization, setOrganization] = useState(false);
  const [permissionsList, setPermissionsList] = useState(permissions);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);
  const accessLevels = [ 
    { profile_name:"isGlobalAdmin", name:"Global Admin" },
    { profile_name:"isDomainSuperAdmin", name:"Organization Admin" },
    { profile_name:"isDomainAdmin",  name:"Admin" },
    { profile_name:"isEndUser", name:"Employee" }
  ]

  const hiddenImageInputRef = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [logoURL, setLogoURL] = useState(null)

  useEffect(() => {

    if (edit === true){
    
      setValue("name", organizationData?.name);
      setValue("website", organizationData?.website);
      setValue("address1", organizationData?.address1);
      setValue("address2", organizationData?.address2);
      setValue("address3", organizationData?.address3);
      setValue("street", organizationData?.street);
      setValue("contact_email", organizationData?.contact_email);
      setValue("contact_phone", organizationData?.contact_phone);
      setValue("licenses", organizationData?.licenses);
      setValue("location_id", organizationData?.location_id);
      setValue("location", returnLocation(organizationData?.location_id));
      setValue("organization_admin_user", returnOrganizationAdmin(organizationData?.organization_admin_user_id));
      setValue("organization_admin_user_id", organizationData?.organization_admin_user_id);
      if (organizationData?.logo !== null){
        setLogoURL(organizationData?.logo)
      }
    }
    else {

      // setValue("organization", returnOrganization(user?.userdata?.organization_id).name);
      // setValue("organization_id", user?.userdata?.organization_id);
      // setValue("location_id", returnOrganization(user?.userdata?.organization_id).location_id);
      
    }

  },[user])

  useEffect(() => {
    if (user?.email){
      dispatch(getOrganization(user?.email));
      dispatch(getProvinces(user?.email))
    }
  }, [dispatch, user]);
 
  const returnLocation = (location_id) => {
    
    let locationobject = provinces.filter(province => province.id == location_id );
    
    if (locationobject.length > 0){
      return locationobject[0]
    }
  }

  const submitAddEditOrganization = (data) => {
        
    if ( add === true ){

        let body = { 
          "email": user?.userdata?.user_email,
          "name": data.name,
          "location_id": data.location_id,
          "contact_email": data.contact_email,
          "contact_phone": data.contact_phone,
          "licenses": data.licenses,
          "address1": data.address1 || '',
          "address2": data.address2 || '',
          "address3": data.address3 || '',
          "street": data.street || '',
          "website": data.website || '',
          "default": false,
          "file": selectedImage,
          "logo": null,
          "organization_admin_user_id": data?.organization_admin_user_id || null
        }

        setActionLoading(true);

        dispatch(addOrganization(
          body, 
          () => {
            dispatch(getOrganization(user?.email));
            setActionLoading(false);
            closeAddOrganizationDialog();
          },
          () => {
            setActionLoading(false);
          }  
        ))
    }
    else {
      
      let body = { 
        "email": user?.userdata?.user_email,
        "id": organizationData?.id,
        "name": data?.name,
        "location_id": data?.location_id,
        "contact_email": data?.contact_email,
        "contact_phone": data?.contact_phone,
        "licenses": data?.licenses,
        "address1": data?.address1 || '',
        "address2": data?.address2 || '',
        "address3": data?.address3 || '',
        "street": data?.street || '',
        "website": data?.website || '',
        "default": false,
        "file": selectedImage,
        "logo": organizationData?.logo,
        "organization_admin_user_id": data?.organization_admin_user_id || null


      }
      setActionLoading(true);

      dispatch(updateOrganization(
        body, 
        () => {

          dispatch(getOrganization(user?.email));
          setActionLoading(false);
          closeAddOrganizationDialog()

        },
        () => {
          setActionLoading(false);
        }  
      ))

    }

  };

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInputBase-input':{
      height:15,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });

  const handleImageClick = event => {
    hiddenImageInputRef.current.click();
  };
  // Call a function (passed as a prop from the parent component)
  // to handle the user-selected file 
  const handleChange = event => {
    
    // const fileUploaded = event.target.files[0];
    setSelectedImage(event.target.files[0]);
    if (event?.target?.files[0]){
      // uploadOrganizationImageRequest(event?.target?.files[0])
    }
    // props.handleFile(fileUploaded);
  
  };

  const returnOrganizationAdmin = (id) => {

      
    let adminobject = organization_admins.filter( admin => admin?.id == id );
    
    if ( adminobject.length > 0){
      return adminobject[0]
    }
    else {
      return {}
    }

  }

  return (

      <form className={classes.form} onSubmit={handleSubmit(submitAddEditOrganization)}>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Organization
            </div>

            <Controller
              name="name"
              control={control}
              defaultValue=""
              rules={{ required: "Organization title is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Organization Title"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <Controller
              name="location_id"
              control={control}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <input
                  type="hidden"
                  value={value}
                />
              )}
            />


          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Corporate Website
            </div>

            <Controller
              name="website"
              control={control}
              defaultValue=""
              rules={{ required: "Corporate website is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Corporate Website"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>

            <Grid
              container
              lg={12}
              xl={12}
              md={12}
              style={{ display: "flex" }}
            >
               <Grid item lg={8} xl={8} md={8} style={{ paddingBottom:5 }}>
                  <Controller
                      name="address1"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Address is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Address"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                </Grid>
      
                <Grid item lg={4} xl={4} md={4} style={{ paddingLeft: 5, paddingBottom:5 }}>
                  <Controller
                      name="address2"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Suite is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Suite"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                </Grid>

                <Grid item lg={12} xl={12} md={12} style={{ paddingBottom:5 }}>
                  <Controller
                      name="address3"
                      control={control}
                      defaultValue=""
                      rules={{ required: "City is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="City"
                          value={value}
                          onChange={onChange}
                          error={!!error}
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                </Grid>


                <Grid item lg={5} xl={5} md={5} style={{ paddingBottom:5 }}>
                  <Controller
                      name="street"
                      control={control}
                      defaultValue=""
                      rules={{ required: "Postal Code is required" }}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <LoginTextField
                          className={classes.text_field}
                          variant="outlined"
                          placeholder="Postal Code"
                          value={value}
                          onChange={(e) => onChange(e.target.value.toUpperCase())}
                          error={!!error}
                          inputProps={{
                            maxLength:7
                          }}
                          disabled={loading}
                          helperText={error ? error.message : null}
                        />
                      )}
                    />
                </Grid>
                <Grid item lg={7} xl={7} md={7} style={{ paddingLeft: 5, paddingBottom:5 }}>
                  

                <Controller
                    rules={{ required: "Province is Required" }}
                    defaultValue={returnLocation(organizationData?.location_id) || []}
                    render={({
                      field: { onChange, onBlur, value, name, ref },
                      fieldState: { invalid, isTouched, isDirty, error },
                      formState,
                    }) => (
                      <Autocomplete
                          options={provinces}
                          onChange={(_, data) => {
                            
                            setValue('location_id', data ?  data.id : '');
                            onChange(data)
                          }}
                          value={value}
                          getOptionLabel={(option) => option.abbreviation || ''}
                          getOptionSelected={(option, value) => option?.id === value?.id }
                          renderOption={(props, option) => (
                            <div style={{ padding:5 }} {...props}>
                              {option.state_or_province}
                            </div>
                          )}
                          disableClearable
                          renderInput={(params) => (
                              <LoginTextField 
                                {...params} 
                                variant="outlined" 
                                placeholder="Province"
                                readOnly={false}
                                error={!!error}
                                helperText={error ? error.message : null}
                              />
                          )}
                        />
                    )}
                    name="province"
                    control={control}
                  />

                </Grid>


            </Grid>
          </div>        

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Email
            </div>

            <Controller
              name="contact_email"
              control={control}
              defaultValue=""
              rules={{ 
                required: "Email is required",
                validate: (value) => {
                  if ( isEmail(value) ) {
                    return true
                  } else {
                    return "Please Enter a Valid Email Address"
                  }
                }
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error} 
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>


          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Phone
            </div>

            <Controller
              name="contact_phone"
              control={control}
              defaultValue=""
              rules={{ required: "Phone is Required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Phone"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Organization Admin
            </div>

            <Controller
              name="organization_admin_user"
              control={control}
              defaultValue={returnOrganizationAdmin(organizationData?.organization_admin_user_id) || {}}
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Autocomplete
                    options={organization_admins.filter( o => o?.organization_id == organizationData?.id )}
                    onChange={(_, data) => {
                      
                      setValue('organization_admin_user_id', data ?  data.id : '');
                      onChange(data)
                    }}
                    value={value}
                    getOptionLabel={(option) => option?.first_name ? `${option.first_name} ${option.last_name}` : ''}
                    getOptionSelected={(option, value) => option?.id === value?.id }
                    // renderOption={(props, option) => (
                    //   <div style={{ padding:5 }} {...props}>
                    //     { `${option.first_name} ${option.last_name}`}
                    //   </div>
                    // )}
                    disableClearable
                    renderInput={(params) => (
                        <LoginTextField 
                          {...params} 
                          variant="outlined" 
                          placeholder="Organization Admin"
                          readOnly={false}
                          error={!!error}
                          helperText={error ? error.message : null}
                        />
                    )}
                  />
              )}
            />

            <Controller
              name="organization_admin_user_id"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <input type="hidden" value={value} />
              )}
            />

          </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Licenses
            </div>

            <Controller
              name="licenses"
              control={control}
              defaultValue=""
              rules={{ required: "Licenses is Requireed" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Licenses"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>
              <div style={{ textTransform:'uppercase', fontWeight:'bold' }}>Organization Logo</div>
              <div style={{ fontSize: 10 }}>Upload files no larger than 2MB</div>
              {
                selectedImage === null && logoURL === null?
                  <>
                    <Button 
                        onClick={handleImageClick}
                        variant="text"
                        style={{ border:'1px solid #999', borderRadius:18, display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
                          <AddIcon sx={{ color: "#000" }}/>
                    </Button>
                  </>
                  : 
                  (

                    logoURL !== null ?
                    <div style={{ display:'flex', flexDirection:'column' }}>
                      <img
                        alt="not found"
                        width={"110px"}
                        height={"auto"}
                        src={logoURL}
                      />
                      <Button onClick={handleImageClick} style={{ marginTop: -10 }}>
                        <EditIcon
                            style={{
                              color: "#AAA",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                        />
                      </Button>
                      <br />
                    </div>
                    :
                    <div style={{ display:'flex', flexDirection:'column', alignItems:'flex-start' }}>
                      <img
                        alt="not found"
                        width={"120px"}
                        height={"auto"}
                        src={URL.createObjectURL(selectedImage)}
                      />

                      <Button onClick={handleImageClick} style={{ marginTop: 5 }}>
                        <EditIcon
                            style={{
                              color: "#AAA",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                        />
                      </Button>
                      <br />
                    </div>
                  )
              }
              <input
                type="file"
                ref={hiddenImageInputRef}
                onChange={handleChange}
                style={{display: 'none'}}
              />
          </div>

          <br/>
      
          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
            <Button 
              type="submit"
              style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 250 }}
              variant="text"  
              disabled={loading}
              disableElevation> 
              <div style={{ padding:"10px 10px" }}>
                { add === true ? 'Add Organization' : 'Save Changes' }
              </div>

              {
                loading ?
                  <div style={{ justifyContent:'center', alignItems:'center' }}>
                    <CircularProgress 
                      size={20}
                      sx= {{ color:"#FFF" }}
                    />
                  </div>
                :
                null
              }

            </Button>
          </div>

          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', fontWeight:'500', fontSize:13, color:'red', marginTop:20 }}>
              REMOVE ORGANIZATION 
          </div>

      </form>    

  );
};

export default OrganizationForm;
