import { axiosService as axios } from "../../helpers/utils-authentication";
import { setSnackBar } from "./snackbar.action";
import { appConfig } from "../../config/appconfig";

export const setNewPassword = (
  { previouspassword, proposedpassword },
  email,
  code,
  cb
) => {
  return async (dispatch) => {
    const data = {
      email,
      previouspassword,
      proposedpassword,
      accesstoken: code,
    };


    return axios
      .post( `${appConfig.authenticationPath}changepassword`, data)
      .then((response) => {
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Password has been changed successfully ",
            key: "4",
            open: true,
          })
        );
        cb();
      })
      .catch((error) => {
        if (error && error.response) {
          const { message } = error && error.response.data && error.response.data.error;
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: message,
              key: "4",
              open: true,
            })
          );
        }
      });
  };
};
