import axios from "axios";
import { appConfig } from '../config/appconfig';
import { setCurrentUser } from '../redux/actions/authentication.action';


axios.interceptors.request.use(
  (config) => {

    const _user = JSON.parse(localStorage.getItem("currentUser"));
    let accesstoken = _user?.accesstoken;
    if (accesstoken) {
      
      config.headers["Authorization"] = `Bearer ${accesstoken}`//`Bearer ${token}`;
    }
    else {
      delete config.headers["Authorization"]
    }

    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axios.interceptors.response.use(
    (response) => {
      return response;
    },
    function (error) {
      const originalRequest = error.config;
      const _user = JSON.parse(localStorage.getItem("currentUser"));
      let refreshToken = _user?.refreshtoken;

    if (
        refreshToken &&
        error?.response?.status === 401 &&
        !originalRequest._retry
      ) {
        
        originalRequest._retry = true;

        const body = {
          email: _user?.email,
          token: refreshToken,
          user_id: _user?.userdata?.user_id
        };

        return axios
          .post(`${appConfig.authenticationPath}refreshsession`, body)
          .then((res) => {
            if (res.status === 200) {
              
              localStorage.setItem(
                "currentUser",
                JSON.stringify({
                  accesstoken: res.data.userdata.access_token,
                  refreshtoken: refreshToken,
                  email: res.data.userdata.user_email,
                  userdata: res.data.userdata
                })
              );
              
              setCurrentUser(res.data?.userdata.access_token, res.data?.userdata.user_email, res.data?.userdata );
              
              return axios(originalRequest);
            }
          }).
          catch(err => {
            
          })
      }
      return Promise.reject(error);
    }
  );

  export const axiosService = axios;