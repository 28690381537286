import React from 'react';
import Button from '@material-ui/core/Button';
import useStyles from "./loginBtn.styles"

const LoginBtn = ({ cb, label }) => {

    const classes = useStyles();

  
    return (
        <Button
          variant="contained"
          color="default"
          className={classes.button}
          onClick={cb}
          type={"submit"}
      >
        {label}
      </Button>
    )
}

export default LoginBtn;