import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const StatusSelect = ({data, selectedValue, setStatus, readOnly}) => {
  
  return (
    <Autocomplete
      disableClearable
      options={data}
      value={selectedValue}
      onChange={(event, newValue) => {
        
        setStatus(newValue);
      }}
      inputValue={selectedValue?.description || 'Active'}
      getOptionDisabled={(option) => {

          if (readOnly){ 
              return option.status === 'I' 
          }
      }}
      getOptionLabel={(option) => option?.description || 'Active'}
      getOptionSelected={(option, value) => option?.status === value?.status }
      renderInput={(params) => (
          <TextField {...params} label="Select Status" variant="outlined" size="small" />
      )}
    />
  );
};

export default StatusSelect;
