import React from "react";
import { GridOverlay } from "@material-ui/data-grid";
import { DataGrid, useGridSlotComponentProps } from '@mui/x-data-grid';
import LinearProgress from "@material-ui/core/LinearProgress";
import Card from '@mui/material/Card';
import { createStyles, makeStyles } from "@mui/styles";
import { createTheme } from '@mui/material/styles';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';

const CustomLoadingOverlay = () => {
  return (
    <GridOverlay>
      <div style={{ position: "absolute", top: 0, width: "100%" }}>
        <LinearProgress />
      </div>
    </GridOverlay>
  );
};

const defaultTheme = createTheme();

function CustomPagination() {
  const { state, apiRef } = useGridSlotComponentProps();

  return (
    <Pagination
      color="primary"
      variant="outlined"
      shape="rounded"
      page={state.pagination.page + 1}
      count={state.pagination.pageCount}
      renderItem={(props2) => <PaginationItem {...props2} disableRipple />}
      onChange={(event, value) => apiRef.current.setPage(value - 1)}
      sx={{ padding:10 }}
    />
  );
}

const useStyles = makeStyles( (theme) =>
  createStyles({
    root: {
      border:'none !important',
      "border-radius": "20px !important",
      '& .MuiDataGrid-withBorder': {
        border:'none'
      },
      '& .MuiDataGrid-columnsContainer': {
        borderTopRightRadius:20,
        borderTopLeftRadius:20
      },
      '& .MuiDataGrid-iconSeparator': {
        display: 'none',
      },
      '& .MuiDataGrid-columnHeader': {
        fontSize:14,
        backgroundColor:'#F8F8F8',
        color:'#888'
      },
      '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
        outline: 'none'
      }
    }
  }),
  { defaultTheme }
);

const CustomTable = ({ columns, rows,loading }) => {

  const dataGridStyles = useStyles();

  return (
      <Card style={{ height: "calc(70vh)", borderRadius:20, overflow:'hidden' }} >
        <DataGrid
          className={dataGridStyles.root}
          rows={rows}
          components={{
            LoadingOverlay: CustomLoadingOverlay,
          }}
          loading={loading ? loading: false}
          columns={columns}
          pageSize={10}
          disableSelectionOnClick
          components={{
            Pagination: CustomPagination
          }}
        />
      </Card>
  );
};

export default CustomTable;
