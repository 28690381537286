import {GETCOVIDSCREENING} from '../types/covidscreening.types';

const initialState = {
    covidscreening: [],
    isLoading: true
}

export const CovidScreeningReducer = (state = initialState, action) => {
    switch(action.type) {
        case GETCOVIDSCREENING:
            return {
                ...state,
                covidscreening: action.payload.data,
                isLoading: false
            }
        default:
            return state;
    }
}