import { 
  GETORGANIZATION, 
  SET_META_DATA,
  SET_FILTERED_META_DATA,
  GET_ONBOARDING_TOUR_DATA,
  GET_ORGANIZATION_ADMINS
} from "../types/organization.types";

const initialState = {
  organizations: [],
  isLoading: true,
  meta_data: {},
  filtered_meta_data: {},
  onboarding_tour_data: {},
  organization_admins: []
};

export const OrganizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GETORGANIZATION:
      return {
        ...state,
        organizations: action.payload.data,
        isLoading: false,
      };

    case SET_META_DATA:
      
      return {
        ...state,
        meta_data: action.payload.data,
      };
    
    case SET_FILTERED_META_DATA:
      return {
        ...state,
        filtered_meta_data: action.payload.data,
      };

    case GET_ONBOARDING_TOUR_DATA:
      return {
        ...state,
        onboarding_tour_data: action.payload.data,
      }

    case GET_ORGANIZATION_ADMINS:
      return {
        ...state,
        organization_admins: action.payload.data,
      }
    

    default:
      return state;
  }
};
