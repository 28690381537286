import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
    title: {
        fontWeight: 600,
        fontSize: "24px"
    },
    '.super-app-theme--header': {
        backgroundColor: 'red'
    },

    '.user-table-headers': {
        fontSize:2
    },
    '.filterbuttontext': {
        textTransform:'unset'
    }
}))

export default useStyles;