import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import useStyles from "./provinces.styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";

//Components
import CustomTable from "../../components/table/table.component";
import { EditAction } from "../../components/table/tableActions";
import AddButton from "../../components/buttons/add-button/addButton.component";
import Modal from "../../components/modal/modal.component";
import FormProvinces from "./form-provinces/formProvinces.component";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addProvince,
  getProvinces,
  updateProvince,
} from "../../redux/actions/provinces.action";

const Provinces = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [provinceData, setProvinceData] = useState(null);

  const { provinces,isLoading } = useSelector((state) => state.Provinces);
  const { user } = useSelector((state) => state.Authentication);

  useEffect(() => {
    if (user?.email){
      dispatch(getProvinces(user?.email));
    }
  }, [dispatch, user]);

  const columns = [
    {
      field: "locationName",
      headerName: "Province Name",
      width: 300,
    },
    {
      field: "capital",
      headerName: "Capital",
      width: 300,
    },
    {
      field: "data",
      headerName: "Abbreviation",
      width: 300,
    },
    {
      field: "country",
      headerName: "Country",
      width: 300,
    },
    // {
    //   field: "status",
    //   headerName: "Status",
    //   flex: 1,
    //   renderCell: ({row}) => {
    //       return ( <div>{row.status === "A" ? "Active" : "Inactive"}</div>)
    //   }
    // },
    {
      field: "Actions",
      headerName: "",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (params) => {
        return (
          <div>
            <EditAction
              callback={() => setProvinceData(params.row) & setOpenModal(true)}
            />
          </div>
        );
      },
    },
  ];

  return (
    <Grid container>
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "50px",
          marginBottom: "10px",
        }}
      >
        <Grid item className={classes.title} lg={6} xl={6} md={6} xs={6}>
          Provinces
        </Grid>
        <Grid
          item
          lg={6}
          xl={6}
          md={6}
          xs={6}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <ButtonGroup variant="contained" color="secondary">
            <AddButton
              title={"Create Provinces"}
              callback={() => setOpenModal(true)}
            />
          </ButtonGroup>
        </Grid>
      </Grid>
      <Grid item lg={12} xl={12} md={12} xs={12} style={{ width: "100%" }}>
        {/* <CustomTable columns={columns} rows={provinces} rowId={"pk"} loading={isLoading}/> */}
      </Grid>
      {openModal ? (
        <Modal
          title={!!provinceData ? "Edit Provinces" : "Create Provinces"}
          children={
            <FormProvinces
              data={provinceData || null}
              edit={!!provinceData ? true : false }
              onCreate={(_data) =>
                dispatch(
                  addProvince(_data,user?.email, () => {
                    setOpenModal(false);
                    setProvinceData(null);
                    dispatch(getProvinces(user?.email))
                  })
                )
              }
              onUpdate={(_data) =>
                {
                  
                  dispatch(
                    updateProvince( _data ,provinceData.pk,user?.email, () => {
                      setOpenModal(false);
                      setProvinceData(null);
                      dispatch(getProvinces(user?.email))
                    })
                  )
                }
              }
            />
          }
          cbOpen={() => {
            setOpenModal(false);
            setProvinceData(null);
          }}
        />
      ) : null}
    </Grid>
  );
};

export default Provinces;
