import React , { useEffect, useState } from "react";
import empathologinscreenimage from "../../assets/images/loginscreenimage2.png";
import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

const NodataUsers = () => {
  
  const dispatch = useDispatch();
  const {
    user
  } = useSelector((state) => state.Authentication);

  const [logoURL, setLogoURL] = useState(null);

  useEffect(() => {
    if (user?.userdata?.logo && user?.userdata?.logo !== null) {
      setLogoURL(user?.userdata?.logo);
    }
    return () => {};
  }, [user]);

  return (
    <Grid
      direction="column"
      style={{ alignItems:"center", justifyContent:'center', flexDirection:'column', height: '100vh' }}
     >

      <Grid
        item
        xs={false}
        sm={false}
        md={10}
        lg={12}
        style={{ display:'flex', flexDirection:'row', alignItems:"flex-start", justifyContent:'flex-end', paddingTop:15 }}
      >
        {
          logoURL !== null ?
            <img
              alt="not found"
              width={"150px"}
              // height={"40px"}
              src={logoURL + "?" + new Date()}
            />
            :
            null
        }
      </Grid>
      
      <Grid
        item
        xs={false}
        sm={false}
        md={10}
        lg={12}
        style={{ display:'flex', flexDirection:'column', alignItems:"center", justifyContent:'center', height:'100%' }}
      >
          <div
            style={{ alignItems:"center", justifyContent: "center" }}
            >
            <img
              src={empathologinscreenimage}
              style={{
                width: "auto",
                height: "50vh",
              }}
            />
          </div>
          <div
            style={{
              fontWeight: 'bold',
              fontSize: 28,
              textAlign: "center",
              paddingTop: 20,
            }}
          >
            Add users to Empatho
          </div>
          <div style={{ textAlign: "center", fontWeight: 400, fontSize: 14, color: '#9E9E9E', paddingTop: 20}}>
            Please talk to your account manager to add users to your organization.
          </div>
      </Grid>
    </Grid>
  );
};

export default NodataUsers;
