import { SETSNACKBAR, DELETESNACKBAR } from "../types/snackbar.types";

export const setSnackBar = (data) => async (dispatch) => {
  dispatch({
    type: SETSNACKBAR,
    payload: data,
  });
};

export const DeleteSnackbar = (data) => async (dispatch) => {
  dispatch({
    type: DELETESNACKBAR,
    payload: {
      data,
    },
  });
};
