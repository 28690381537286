import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import { useHistory } from "react-router-dom";
import useStyles from "./faq.styles";
import { useDispatch, useSelector } from "react-redux";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import wellbeingfaq from '../../assets/images/wellbeing-faq.png';
import userfaq from '../../assets/images/user-faq.png';
import covidfaq from '../../assets/images/covid-faq.png';
import accountfaq from '../../assets/images/account-faq.png';
import paymentfaq from '../../assets/images/payment-faq.png';
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Menu } from '../../components/drawer/drawer-body/drawer.component';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import empathologo from '../../assets/images/empathologo.png';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';


import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';


const drawerWidth = 150;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );
  
  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: window.innerWidth,
    height:100,
    backgroundColor:'#FFF',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));
  
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

const FAQ = ({ props }) => {

    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 601px)'
      })
      const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user, userlist, isLoading, faq_list } = useSelector((state) => state.Authentication);

  const [expanded, setExpanded] = React.useState(false);
  const [wellbeingexpanded, setWellbeingExpanded] = useState(false);
  const [usermanagementexpanded, setUserManagementExpanded] = useState(false);
  const [covidsurveymanagementexpanded, setCovidSurveyManagementExpanded] = useState(false);
  const [accountexpanded, setAccountExpanded] = useState(false);
  const [paymentexpanded, setPaymentExpanded] = useState(false);
  const [rewardsexpanded, setRewardsExpanded] = useState(false);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleWellbeingChange = (panel) => (even, isExpanded) => {
    setWellbeingExpanded(isExpanded ? panel : false)
  }

  const handleUserManagementChange = (panel) => (even, isExpanded) => {
    setUserManagementExpanded(isExpanded ? panel : false)
  }

  const handleCovidSurveyManagementChange = (panel) => (even, isExpanded) => {
    setCovidSurveyManagementExpanded(isExpanded ? panel : false)
  }

  const handleAccountChange = (panel) => (even, isExpanded) => {
    setAccountExpanded(isExpanded ? panel : false)
  }

  const handlePaymentChange = (panel) => (even, isExpanded) => {
    setPaymentExpanded(isExpanded ? panel : false)
  }

  const handleRewardsChange = (panel) => (even, isExpanded) => {
    setRewardsExpanded(isExpanded ? panel : false)
  }

  const returnFaqListCategory = (category) => {
 
    if (faq_list && faq_list.length > 0){
        return faq_list.filter(faq => faq.section === category)
    }

  }

  const accesstoken = user?.accesstoken || user?.userdata.access_token;

  return (

    <Grid container style={{ paddingTop: 40 }}>

        <Grid item className={classes.title} lg={12} xl={12} md={12} xs={12}>
            <div style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                <div style={{ letterSpacing: 1.5, fontSize: 50, marginBottom: 20, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>Frequently Asked Questions</div>
                
            </div>
        </Grid>

        <Grid container lg={12} xl={12} md={12} xs={12} direction="row">
 
            <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                        sx={{ borderRadius:10 }}
                    >
                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={wellbeingfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>Wellbeing Dashboard</div>
                        </div>
                    </AccordionSummary>
                    <AccordionDetails>

                        {
                            returnFaqListCategory('Wellbeing Dashboard') && returnFaqListCategory('Wellbeing Dashboard').map((faq, index) => (
                                <Accordion expanded={wellbeingexpanded === `wellbeing${index}`} onChange={handleWellbeingChange(`wellbeing${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ borderRadius:10 }}
                                    >
                                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                            <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {faq?.answer}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                        
                    
                    </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header">

                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={userfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>User Management</div>
                        </div>

                </AccordionSummary>

                <AccordionDetails>

                        {
                            returnFaqListCategory('User Management') && returnFaqListCategory('User Management').map((faq, index) => (
                                <Accordion expanded={usermanagementexpanded === `usermanagement${index}`} onChange={handleUserManagementChange(`usermanagement${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ borderRadius:10 }}
                                    >
                                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                            <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {faq?.answer}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header">

                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={covidfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>COVID-19 Survey Management</div>
                        </div>

                </AccordionSummary>
                <AccordionDetails>

                        {
                            returnFaqListCategory('COVID-19 Survey Management') && returnFaqListCategory('COVID-19 Survey Management').map((faq, index) => (
                                <Accordion expanded={covidsurveymanagementexpanded === `covidsurveymanagement${index}`} onChange={handleCovidSurveyManagementChange(`covidsurveymanagement${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1bh-content"
                                        id="panel1bh-header"
                                        sx={{ borderRadius:10 }}
                                    >
                                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                            <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        {faq?.answer}
                                    </AccordionDetails>
                                </Accordion>
                            ))
                        }
                    
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">

                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={accountfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>Account</div>
                        </div>

                </AccordionSummary>
                <AccordionDetails>

                    {
                        returnFaqListCategory('Account') && returnFaqListCategory('Account').map((faq, index) => (
                            <Accordion expanded={accountexpanded === `account${index}`} onChange={handleAccountChange(`account${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{ borderRadius:10 }}
                                >
                                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                        <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {faq?.answer}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }

                    
                </AccordionDetails>
            </Accordion>

            <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">

                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={paymentfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>Payment</div>
                        </div>

                </AccordionSummary>
                <AccordionDetails>

                    {
                        returnFaqListCategory('Payment') && returnFaqListCategory('Payment').map((faq, index) => (
                            <Accordion expanded={paymentexpanded === `payment${index}`} onChange={handlePaymentChange(`payment${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{ borderRadius:10 }}
                                >
                                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                        <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {faq?.answer}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }    
                </AccordionDetails>
            </Accordion>



            <Accordion expanded={expanded === 'panel6'} onChange={handleChange('panel6')} sx={{ width: '100%', padding: '30px' }} style={{ borderRadius: 20, margin:'10px 0px' }}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header">

                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                            <div> <img src={paymentfaq} style={{ width:'auto', height:20, marginRight:25 }}/> </div>
                            <div style={{ fontSize:'1.5em', fontWeight:'bold', fontFamily:'Sofia-Pro' }}>Rewards</div>
                        </div>

                </AccordionSummary>
                <AccordionDetails>

                    {
                        returnFaqListCategory('Rewards') && returnFaqListCategory('Rewards').map((faq, index) => (
                            <Accordion expanded={rewardsexpanded === `rewards${index}`} onChange={handleRewardsChange(`rewards${index}`)} sx={{ padding: '30px' }} style={{ margin:'10px 0px' }}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    sx={{ borderRadius:10 }}
                                >
                                    <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                                        <div style={{ fontSize:'1em', fontWeight:'bold', color:'#533AED' }}>{faq?.question}</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails>
                                    {faq?.answer}
                                </AccordionDetails>
                            </Accordion>
                        ))
                    }    
                </AccordionDetails>
            </Accordion>


        </Grid>

    </Grid>
  );
};

export default FAQ;
