import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
    container: {
        height: "100%"
    },
    background: {
        background: "#3f51b5",
        height: "100%"
    },
    container_register: {
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    form: {
        display: "flex",
        flexDirection: "column",
        width: "50%"
    },
    title: {
        fontWeight: "600",
        fontSize: 24
    },
    box: {
        margin: "10px 0px"
    },
    text_field: {
        width: "100%"
    }
})

export default useStyles;