import React, { useEffect } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import useStyles from "./newPassword.styles";
import Button from "@material-ui/core/Button";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useForm, Controller } from "react-hook-form";
import isEmail from 'validator/lib/isEmail';
import { useDispatch } from "react-redux";
import { createPassword } from "../../../redux/actions/authentication.action";
import { useHistory } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import LoginBtn from "../../../components/login-btn/loginBtn.component";
import validator from 'validator';
import { alpha, styled } from '@mui/material/styles';
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import empathopurplelogo from '../../../assets/images/empathopurplelogo.png';
import empathologinscreenimage from '../../../assets/images/loginscreenimage.png';
import empathocreateaccount from '../../../assets/images/empathocreateaccount.png';
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";

const CreatePassword = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { user, verifieduseremail } = useSelector((state) => state.Authentication);
  // const useremail = props?.location?.useremail;
  const {
    handleSubmit,
    control
  } = useForm({
    defaultValues: {
      email: verifieduseremail
    } 
  });


  const submitCreatePassword = (data) => {

    if (data.password !== data.confirmpassword){

      dispatch(
        setSnackBar({
          snackType: "error",
          snackbarMessage: "Passwords Do Not Match",
          key: "4",
          open: true,
        })
      )

    } else {

      dispatch(createPassword(data.email, data.password,() => {
          history.push({pathname: "/login"});
      }));
    }
    
  };

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& .MuiInputBase-input': {
      color: '#FFF'
    },
    '& label.Mui-focused': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#FFF',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#CCC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFF',
      },
    },
  });

  return (
  
    <Grid container spacing={0}>
        <CssBaseline/>
        <Grid
          item
          xs={false}
          sm={false}
          md={5}
          lg={7}
          sx={{
            // backgroundImage: `url(${empathologinscreenimage})`,
            // backgroundRepeat: 'no-repeat',        
            // backgroundSize: 'contain',
            // backgroundPosition: 'center',
            // width:'100%',
            // height:'100vh'
          }}
        >
          <div style={{ flex:0.5, paddingLeft:50,  marginTop:50, justifyContent:'center' }}>
              <img
                color={'none'}
                src={empathopurplelogo}
                style={{ flex:0.5, width: '200px', height:'auto'}}
              /> 
          </div>
          
          <div style={{ padding:20, fontSize:'3.5em', fontWeight:'bold', marginTop:30, display:'flex', alignItems:'center', justifyContent:'center', lineHeight:1.2 }}>
              <div>Welcome to <br/> Empatho Admin <br/> Portal!</div>
          </div>

          {isDesktopOrLaptop && <p><img src={empathocreateaccount} style={{ width:'auto', height:'50vh', position:'absolute', bottom:150, right:'38vw' }}/> </p>}

        </Grid>

        <Grid 
        item 
        xs={12} 
        sm={12} 
        md={7}
        lg={5}
        component="main">
              <div className={classes.container}>

                  <div style={{ flex: 4, display:'flex', justifyContent:'center', alignItems:'center' }}>

                    <div style={{ width: '60%', display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignSelf:'center' }}>
                        
                        <div style={{ fontWeight: 'bold', color:'#FFF', fontSize:'2.5em', margin:'20px 0px 20px 0px' }}>
                          Create Your Account
                        </div>

                        <form className={classes.form} onSubmit={handleSubmit(submitCreatePassword)}>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Your Email
                            </div>
                          
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              rules={{ 
                                required: "Email is required",
                                validate: (value) => {
                                  if ( isEmail(value) ) {
                                    return true
                                  } else {
                                    return "Please Enter a valid email address"
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  placeholder="Email"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error} 
                                  inputProps={{ readOnly: true }}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Your Name (Optional)
                            </div>
                          
                            <Controller
                              name="name"
                              control={control}
                              defaultValue=""
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  placeholder="What can we call you?"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error} 
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Password
                            </div>

                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              rules={{ 
                                required: "Password is required",
                                validate: (value) => {
                                  if (validator.isStrongPassword(value, {
                                    minLength: 8, minLowercase: 1,
                                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                                  })) {
                                    return true
                                  } else {
                                    return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  type="password"
                                  placeholder="Enter Your Password"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Confirm Password
                            </div>

                            <Controller
                              name="confirmpassword"
                              control={control}
                              defaultValue=""
                              rules={{ 
                                required: "Confirm Password",
                                validate: (value) => {
                                  if (validator.isStrongPassword(value, {
                                    minLength: 8, minLowercase: 1,
                                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                                  })) {
                                    return true
                                  } else {
                                    return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  type="password"
                                  placeholder="Confirm Password"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>
                          <br/>
                          <LoginBtn label={'Create Password'} />

                        </form>

                    </div>
                  </div>

                  <div style={{ flex:1, display:'flex', justifyContent:'center', alignItems:'flex-end', paddingBottom:30  }}>
                    <div style={{ padding:30, textAlign:'center', color:'#FFF', fontSize:'0.7em', fontWeight:'600' }}>
                        By tapping "continue" you agree to Empatho Corp's <br/>
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}>terms of service</a></span> and 
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}> privacy policy</a></span>.
                    </div>
                  </div>
                
              </div>
              
        </Grid>
        
    </Grid>
  );
};

export default CreatePassword;
