import useDrawerItemStyles from "./drawerItems.styles";
import { List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { Link, useLocation } from "react-router-dom";

//Icons
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import HelpRoundedIcon from "@mui/icons-material/HelpRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";

import LiveHelpIcon from "@material-ui/icons/LiveHelp";
import SettingsIcon from "@material-ui/icons/Settings";
import RoomIcon from "@material-ui/icons/Room";

import { useHistory } from "react-router-dom";

//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  setLogout,
  resetAuthState,
} from "../../../redux/actions/authentication.action";
import { persistor } from "../../../redux/store/rootStore";

import wellbeingicon from "../../../assets/images/wellbeingicon.png";
import organizationicon from "../../../assets/images/organizationicon.png";
import covidsurveyicon from "../../../assets/images/covidsurveyicon.png";
import usersicon from "../../../assets/images/usersicon.png";
import inactiveuser from "../../../assets/images/grayusericon.png";
import returntoworkicon from "../../../assets/images/returntoworkicon.png";
import faqicon from "../../../assets/images/faqicon.png";
import settingsicon from "../../../assets/images/settingsicon.png";
import logouticon from "../../../assets/images/logouticon.png";
// import Overview from '../../../assets/images/Overview.svg';
import { ReactComponent as Covid19 } from "../../../assets/images/covidsurvey.svg";


const DrawerItems = ({ variant }) => {
  const classes = useDrawerItemStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  let location = useLocation();

  const { user } = useSelector((state) => state.Authentication);

  const usermanagementroles = [
    "isSuperAdmin",
    "isGlobalAdmin",
    "isDomainSuperAdmin",
    "isDomainAdmin",
  ];
  const analyticsroles = [
    "isSuperAdmin",
    "isGlobalAdmin",
    "isDomainSuperAdmin",
  ];
  const provinceroles = ["isSuperAdmin", "isGlobalAdmin"];
  const organizationroles = ["isSuperAdmin", "isGlobalAdmin"];

  const accesstoken = user?.accesstoken || user?.userdata.access_token;

  const checkRole = (roleName) => {
    if (roleName === "isDomainAdmin") {
      return false;
    } else if (roleName === "isDomainSuperAdmin") {
      return false;
    }
    return true;
  };

  return (
    <div className={classes.container}>
      <List
        component="nav"
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          <ListItem
            button
            onClick={() => history.push("/wellbeing")}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <ListItemIcon
              style={{
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: -40,
              }}
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M1.93595 14.0002C3.54645 16.4534 6.27262 19.1614 10.5004 22C14.7281 19.1614 17.4543 16.4534 19.0648 14.0002H15.7506C15.6195 14.0001 15.4914 13.9602 15.3829 13.8856C15.2744 13.811 15.1903 13.7051 15.1415 13.5815L13.2292 8.7256L11.1317 16.1828C11.0942 16.3165 11.0165 16.435 10.9094 16.5216C10.8023 16.6083 10.6712 16.6587 10.5345 16.6659C10.3978 16.6731 10.2623 16.6367 10.147 16.5618C10.0316 16.4869 9.94225 16.3772 9.89133 16.2481L7.72956 10.7576L5.79617 13.7042C5.73614 13.7953 5.65491 13.87 5.55966 13.9217C5.46442 13.9733 5.3581 14.0003 5.25015 14.0002H1.93595ZM1.15367 12.6669C-3.18431 4.24036 5.78829 -0.666208 10.2693 3.52437C10.3481 3.59771 10.4255 3.6737 10.5004 3.75237C10.5744 3.67378 10.6515 3.59816 10.7314 3.52571C15.2124 -0.666209 24.185 4.24036 19.8457 12.6669H16.1929L13.7332 6.41898C13.6823 6.2899 13.5929 6.18022 13.4775 6.1053C13.3622 6.03039 13.2267 5.99398 13.09 6.00118C12.9533 6.00838 12.8222 6.05883 12.7151 6.14546C12.608 6.23209 12.5303 6.35057 12.4928 6.48431L10.3967 13.9415L8.48427 9.08559C8.43974 8.97295 8.36576 8.87479 8.27034 8.80172C8.17491 8.72865 8.06166 8.68343 7.94279 8.67094C7.82392 8.65845 7.70395 8.67917 7.59582 8.73087C7.4877 8.78256 7.39552 8.86326 7.32923 8.96426L4.89838 12.6669H1.15498H1.15367Z"
                  fill={
                    location.pathname === "/wellbeing" ? "#FFFFFF" : "#D1D0D7"
                  }
                />
              </svg>

              {location.pathname === "/wellbeing" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: checkRole(user?.userdata.profile_name) ? 5 : -33,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
            </ListItemIcon>{" "}
            <span
              style={{
                fontWeight: location.pathname === "/wellbeing" ? "700" : "500",
                fontSize: 14,
                color:
                  location.pathname === "/wellbeing" ? "#FFFFFF" : "#D1D0D7",
                letterSpacing: 0.375,
                lineHeight: 2,
              }}
            >
              Overview
            </span>
          </ListItem>

          {/* {checkRole(user?.userdata?.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/covidsurvey")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/covidsurvey" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -40,
                }}
              >
                {location.pathname === "/covidsurvey" ? (
                  <img
                    src={covidsurveyicon}
                    style={{ width: "auto", height: 23 }}
                  />
                ) : (
                  <Covid19 />
                )}
              </ListItemIcon>
              <span
                style={{
                  fontWeight:
                    location.pathname === "/covidsurvey" ? "700" : "500",
                  fontSize: 14,
                  color:
                    location.pathname === "/covidsurvey"
                      ? "#FFFFFF"
                      : "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                Covid-19
              </span>
            </ListItem>
          ) : null} */}

          {checkRole(user?.userdata?.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/users")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/users" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -60,
                }}
              >
                {location.pathname === "/users" ? (
                  <img src={usersicon} style={{ width: "auto", height: 20 }} />
                ) : (
                  <img
                    src={inactiveuser}
                    style={{ width: "auto", height: 20 }}
                  />
                )}
              </ListItemIcon>
              <span
                style={{
                  fontWeight: location.pathname === "/users" ? "700" : "500",
                  fontSize: 14,
                  color: location.pathname === "/users" ? "#FFFFFF" : "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                Users
              </span>
            </ListItem>
          ) : null}

          {organizationroles.includes(user?.userdata?.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/organizations")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/organizations" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -8,
                }}
              >
                {/* <img
                  src={organizationicon}
                  style={{ width: "auto", height: 20 }}
                /> */}

                <svg
                  width="21"
                  height="23"
                  viewBox="0 0 21 23"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    y="8.29349"
                    width="20.6496"
                    height="14.1966"
                    rx="1"
                    fill={
                      location.pathname === "/organizations"
                        ? "#FFFFFF"
                        : "#D1D0D7"
                    }
                  />
                  <rect
                    x="4.12648"
                    y="1"
                    width="12.6996"
                    height="17.445"
                    rx="5.04262"
                    stroke={
                      location.pathname === "/organizations"
                        ? "#FFFFFF"
                        : "#D1D0D7"
                    }
                    stroke-width="2"
                  />
                </svg>
              </ListItemIcon>
              <span
                style={{
                  fontWeight:
                    location.pathname === "/organizations" ? "700" : "500",
                  fontSize: 14,
                  color:
                    location.pathname === "/organizations"
                      ? "#FFFFFF"
                      : "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                Organizations
              </span>
            </ListItem>
          ) : null}

          {/* {checkRole(user?.userdata?.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/returntowork")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/returntowork" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#FFFFFF",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <svg
                  width="20"
                  height="18"
                  viewBox="0 0 20 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_373_94444)">
                    <path
                      d="M11.3333 9.48228H14.3333V6.48228H17.8444V9.48228H18.6444C18.6889 9.41561 18.7111 9.37116 18.7556 9.3045C20.0889 6.83783 19.7111 4.19339 18.5333 2.54894V2.52672C18.1778 2.03783 17.7556 1.63783 17.3111 1.34894C12.5333 -1.53995 9.77777 3.86005 9.77777 3.86005C9.77777 3.86005 7.04444 -1.53995 2.31111 1.34894C0.311108 2.59339 -0.911114 6.08228 0.844442 9.3045C1.37777 10.2823 2.11111 11.2378 2.91111 12.1045C5.8 15.2156 9.77777 17.4823 9.77777 17.4823C9.77777 17.4823 12 16.2156 14.3111 14.2823V12.9934H11.3356V9.48228H11.3333Z"
                      fill={
                        location.pathname === "/returntowork"
                          ? "#FFFFFF"
                          : "#D1D0D7"
                      }
                    />
                    <path
                      d="M17 10.3267V7.32672H15.1556V10.3267H12.1556V12.1489H15.1556V15.1712H17V12.1489H20V10.3267H17Z"
                      fill={
                        location.pathname === "/returntowork"
                          ? "#FFFFFF"
                          : "#D1D0D7"
                      }
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_373_94444">
                      <rect
                        width="20"
                        height="16.9844"
                        fill="#FFFFFF"
                        transform="translate(0 0.497833)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </ListItemIcon>
              <span
                style={{
                  fontWeight:
                    location.pathname === "/returntowork" ? "700" : "500",
                  fontSize: 14,
                  color:
                    location.pathname === "/returntowork"
                      ? "#FFFFFF"
                      : "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                Return to Work
              </span>
            </ListItem>
          ) : null} */}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 20,
          }}
        >
          {checkRole(user?.userdata.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/faq")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/faq" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#D1D0D7",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -70,
                }}
              >
                <img src={faqicon} style={{ width: "auto", height: 20 }} />
              </ListItemIcon>
              <span
                style={{
                  fontWeight: location.pathname === "/faq" ? "700" : "500",
                  fontSize: 14,
                  color: "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                FAQ
              </span>
            </ListItem>
          ) : null}

          {checkRole(user?.userdata.profile_name) ? (
            <ListItem
              button
              onClick={() => history.push("/settings")}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "10px 0px",
              }}
            >
              {location.pathname === "/settings" ? (
                <div
                  style={{
                    width: 4,
                    height: 31.3,
                    left: 5,
                    backgroundColor: "#D1D0D7",
                    position: "absolute",
                    borderRadius: "100px 0px 0px 100px",
                    transform: "matrix(-1, 0, 0, 1, 0, 0)",
                  }}
                ></div>
              ) : null}
              <ListItemIcon
                style={{
                  color: "#FFF",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  marginLeft: -40,
                }}
              >
                <img src={settingsicon} style={{ width: "auto", height: 20 }} />
              </ListItemIcon>
              <span
                style={{
                  fontWeight: location.pathname === "/settings" ? "700" : "500",
                  fontSize: 14,
                  color: "#D1D0D7",
                  letterSpacing: 0.375,
                  lineHeight: 2,
                }}
              >
                Settings
              </span>
            </ListItem>
          ) : null}

          <ListItem
            button
            onClick={() => {
              dispatch(
                setLogout(user?.email, accesstoken, async () => {
                  dispatch(resetAuthState());

                  localStorage.removeItem("currentUser");
                  localStorage.removeItem("persist:Authentication");
                  localStorage.clear();

                  await persistor.flush();
                  await persistor.purge();

                  history.push({ pathname: "/" });
                })
              );
            }}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "10px 0px",
            }}
          >
            <ListItemIcon
              style={{
                color: "#FFF",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: -45,
              }}
            >
              <img src={logouticon} style={{ width: "auto", height: 20 }} />
            </ListItemIcon>
            <span
              style={{
                fontWeight: "500",
                fontSize: 14,
                color: "#D1D0D7",
                letterSpacing: 0.375,
                lineHeight: 2,
              }}
            >
              Log out
            </span>
          </ListItem>
        </div>
      </List>
    </div>
  );
};

export default DrawerItems;
