import React, {useEffect, useState} from "react";
import Grid from '@mui/material/Grid';
import { TextField, Typography } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import useStyles from "./settings.styles";
import { useSelector, useDispatch } from "react-redux";
import SaveButton from "../../components/buttons/save-button/saveButton.component";
import { setNewPassword } from "../../redux/actions/settings.action";
import validator from 'validator'
import Paper from '@mui/material/Paper';
import EditIcon from "@material-ui/icons/Edit";
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import { getOrganization } from "../../redux/actions/organization.action";
import LockSetting from "../../assets/images/organization-setting.png";
import LetterSetting from "../../assets/images/letter-setting.png";
import AccesLevelSetting from "../../assets/images/acceslevel-setting.png";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import FormSetting from "./form-settings/formSetting.component";
import CancelIcon from '@mui/icons-material/Cancel';
import FormChangePassword from "./form-changepassword/formChangePassword.component";
import FormDesactiveAccount from "./form-desactiveaccount/formDesactiveAccount";
import ShoppingCard from "../../assets/images/shopping-cart.png";
import CreditCard from "../../assets/images/credit-card.png";
import Calendar from "../../assets/images/calendar.png";
import Button from '@mui/material/Button';
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Menu } from '../../components/drawer/drawer-body/drawer.component';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import empathologo from '../../assets/images/empathologo.png';
import { useHistory } from "react-router-dom";

import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 150;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: window.innerWidth,
  height:100,
  backgroundColor:'#FFF',
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const UserSettings = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const classes = useStyles();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);
  const history = useHistory();
  const [openEditProfileDialog, setOpenEditProfileDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editProfileData, setEditProfileData] = useState(null);

  const [openChangePassDialog, setOpenChangePassDialog] = useState(false);
  const [editChangePass, setEditChangePass] = useState(false);
  const [editChangePassData, setChangePassData] = useState(null);

  const [openDeactiveAccountDialog, setOpenDeactiveAccountDialog] = useState(false);
  const [editDeactiveAccount, setDeactiveAccount] = useState(false);
  const [editDeactiveAccountData, setDeactiveAccountData] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  

  useEffect(() => {
    if (user?.email){
      dispatch(getOrganization(user?.email));
    }
  }, [dispatch, user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };


  const closeEditProfileDialog = () => {
    
    setOpenEditProfileDialog(false)

  }

  const closeChangePassDialog = () => {
    
    setOpenChangePassDialog(false)

  }

  const closeDeactiveAccountDialog = () => {
    
    setOpenDeactiveAccountDialog(false)

  }

  const openChagePassDataDialog = (user, edit) => {

    if (edit) {
      setChangePassData(user);
      setEditChangePass(true);
    }
    
    setOpenChangePassDialog(true)

  }

  const openDataDialog = (user, edit) => {

    if (edit) {
      setEditProfileData(user);
      setEdit(true);
    }
    
    setOpenEditProfileDialog(true)

  }

  const openDeactiveAccDialog = (user, edit) => {

    if (edit) {
      setDeactiveAccountData(user);
      setDeactiveAccount(true);
    }
    
    setOpenDeactiveAccountDialog(true)

  }

  const returnOrganization = (id) => {
    if (organizations.length > 0){
      let name = organizations.filter(org => org.id === id)
      if (name.length > 0){
        return organizations.filter(org => org.id === id)[0].name
      }
      else {
        return ''
      }
    }
    else { 
      return ''
    }
  }


  return (
   
    <Grid container style={{ paddingTop: 40 }}>      
        
        <Grid item lg={12} xl={12} md={12} xs={12}>
            <Grid style={{ display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center' }}>
                <span style={{ fontSize: 40, marginBottom:20, fontFamily:'Sofia-Pro', fontWeight:'bold' }}>Welcome to your account, <span style={{ fontWeight:'bold' }}>{user?.userdata?.first_name}</span></span>
            </Grid>
        </Grid>

        <Grid container lg={4} xl={4} md={4} xs={12} direction="row"
        style={{marginTop: 20, paddingLeft: 10, marginBottom: 10}}
        >

          <Paper sx={{ minHeight:200, height:'auto', borderTopLeftRadius: 15, borderTopRightRadius: 15, display:'flex', flex:1, overflow:'hidden' }}>

            <Grid style={{ display:'flex', flexDirection:'column', width:'100%' }}>

                  <Grid style={{ paddingRight:20, paddingTop:20, display:'flex', flexDirection:'row', justifyContent:'flex-end' }}>
                      <IconButton onClick={() => openDataDialog(user, true)} aria-label="edit" sx={{ backgroundColor:'#EEE' }}>
                        <EditIcon style={{ color:'#533AED' }}/>
                      </IconButton> 
                  </Grid>

                  <Grid style={{ padding:5, display:'flex', flexDirection:'row' }}>
                      <span style={{ fontSize: 24, marginLeft: 30, fontWeight:'600' }}>{user?.userdata?.first_name} {user?.userdata?.last_name}</span>
                  </Grid>
                  <Divider />

                  <Grid style={{ padding:10, display:'flex', flexDirection:'column' }}>

                        <Grid style={{ padding:10 }}>
                          <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                            <img color={'none'} src={LockSetting} /> 
                            <span style={{marginRight: 'auto', marginLeft: 10 }}>{returnOrganization(user?.userdata?.organization_id)}</span>
                          </Grid>
                            <span style={{ color:'#999', marginLeft: 25, fontWeight:'600', fontSize:14 }}>Organization</span>
                        </Grid>

                        <Grid style={{ padding:10 }}>
                            <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                            <img color={'none'} src={LetterSetting} />
                            <span style={{marginRight: 'auto', marginLeft: 10}}>{user?.userdata?.user_email}</span>
                            </Grid>
                            <span style={{ color:'#999', marginLeft: 25, fontWeight:'600', fontSize:14  }}>Contact Email</span>
                        </Grid>

                        <Grid style={{ padding:10 }}>
                          <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                            <img colot={'none'} src={AccesLevelSetting} />
                            <span style={{marginRight: 'auto', marginLeft: 10}}>{user?.userdata?.profile_description}</span>
                          </Grid>
                            <span style={{ color:'#999', marginLeft: 25, fontWeight:'600', fontSize:14 }}>Access Level</span>
                        </Grid>

                        <Grid style={{ padding:10 }}>
                          <Grid style={{justifyContent: 'space-between', flexDirection: 'column'}}>
                            <Button onClick={() => openChagePassDataDialog(user, true)} style={{ marginRight: 'auto', letterSpacing: 1.5, color:'#533AED', fontSize: 14 }}>
                              <div>CHANGE PASSWORD</div>
                            </Button>
                          </Grid>
                          {/* <Grid>
                          <span style={{ color:'#999', marginLeft: 25 }}>Password last changed 2 days ago</span>
                          </Grid> */}
                        </Grid>

                        <Grid style={{ padding:10 }}>
                          <Button onClick={() => openDeactiveAccDialog(user, true)} style={{ color:'red', letterSpacing: 1.5, fontSize: 14}}>
                            <div>DEACTIVATE ACCOUNT</div>
                          </Button>
                        </Grid>

                  </Grid>

            </Grid>            
            
          </Paper>

        </Grid>
      
  <Grid container lg={4} xl={4} md={4} xs={12} direction="row" style={{marginTop: 20, paddingLeft: 10, marginBottom: 10}}>

{/* <Paper sx={{ minHeight:200, height:'auto', borderTopLeftRadius: 15, borderTopRightRadius: 15, display:'flex', flex:1, overflow:'hidden' }}>

  <Grid style={{ display:'flex', flexDirection:'column', width:'100%' }}>

        <Grid style={{ paddingRight:20, paddingTop:20, display:'flex', flexDirection:'row', justifyContent:'flex-end' }}>
            <IconButton aria-label="edit" sx={{ backgroundColor:'#EEE' }}>
              <EditIcon style={{ color:'#533AED' }}/>
            </IconButton> 
        </Grid>

        <Grid style={{ padding:5, display:'flex', flexDirection:'row' }}>
            <span style={{ fontSize: 20, marginLeft: 30, fontWeight:'500', color: '#533AED' }}>Your plan</span>
        </Grid>
        <Divider />

        <Grid style={{ padding:10, display:'flex', flexDirection:'column' }}>

              <Grid style={{ padding:10 }}>
                <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                  <img color={'none'} src={ShoppingCard} style={{width: 20, height: 20}} /> 
                  <span style={{marginRight: 'auto', marginLeft: 10 }}>400</span>
                </Grid>
                  <span style={{ color:'#999', marginLeft: 25 }}>Individual Subscriptions</span>
              </Grid>

              <Grid style={{ padding:10 }}>
                  <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                  <img color={'none'} src={CreditCard} style={{width: 20,height:20}} />
                  <span style={{marginRight: 'auto', marginLeft: 10}}>Visa ending ****3826</span>
                  </Grid>
                  <span style={{ color:'#999', marginLeft: 25 }}>Contact Email</span>
              </Grid>

              <Grid style={{ padding:10 }}>
                <Grid style={{flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 5}}>
                  <img colot={'none'} src={Calendar} style={{width: 20,height:20}} />
                  <span style={{marginRight: 'auto', marginLeft: 10}}>CAD $9.99pp/mo</span>
                </Grid>
                  <span style={{ color:'#999', marginLeft: 25}}>Next payment on Oct 16, 2021</span>
              </Grid>
              <Grid style={{paddingBottom: 15, marginTop: -10}}>
                  <span style={{ color:'#999', marginLeft: 34}}>Annual plan, paid monthly</span>
              </Grid>

              <Grid style={{ padding:10 }}>
              <Button 
                    sx={{ fontSize:12, backgroundColor:'#533AED', color:'#FFF', padding: '10px 30px', borderRadius: 25, letterSpacing: 1.5, }}
                    variant="text"  
                    disableElevation> 
                    CONTACT EMPATHO
                </Button>
              </Grid>

        </Grid>

  </Grid>             
  
</Paper> */}

</Grid>
  
<Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:5,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openEditProfileDialog}
          disableEscapeKeyDown={true}
          maxWidth={'lg'}
        >
        <Grid style={{ paddingRight:20, paddingLeft:20, width:'40vw', borderRadius:5 }}>
          <Grid style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeEditProfileDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </Grid>
          <Grid style={{fontSize:25, textAlign:'center', marginBottom:30 }}>Edit Profile</Grid>
          <Grid style={{ justifyContent:'center', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center' }}>
            <Grid style={{ width:'60%' }}>
              <FormSetting edit={edit} closeProfileDialog={closeEditProfileDialog} ProfileData={editProfileData}/>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:5,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openChangePassDialog}
          disableEscapeKeyDown={true}
          maxWidth={'md'}
        >
        <Grid style={{ paddingRight:20, paddingLeft:20, width:'40vw', borderRadius:5 }}>
          <Grid style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeChangePassDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </Grid>
          <Grid style={{fontSize:25, textAlign:'center', marginBottom:30, fontWeight:'bold' }}>Change your Password</Grid>
          <Grid style={{ justifyContent:'center', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center' }}>
            <Grid style={{ width:'60%' }}>
              <FormChangePassword edit={editChangePass} closeUserDialog={closeChangePassDialog} UserData={editChangePassData}/>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>

      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:5,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openDeactiveAccountDialog}
          disableEscapeKeyDown={true}
          maxWidth={'lg'}
        >
        <Grid style={{ paddingRight:20, paddingLeft:20, width:'40vw', borderRadius:5 }}>
          <Grid style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeDeactiveAccountDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </Grid>
          <Grid style={{fontSize:25, textAlign:'center', marginBottom:30, fontWeight:'bold' }}>Deactivate Account</Grid>
          <Grid style={{ justifyContent:'center', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center' }}>
            <Grid style={{ width:'60%' }}>
              <FormDesactiveAccount edit={editDeactiveAccount} closeUserDialog={closeDeactiveAccountDialog} UserData={editDeactiveAccountData}/>
            </Grid>
          </Grid>
        </Grid>
      </Dialog>


    </Grid>
    
  );
};

export default UserSettings;
