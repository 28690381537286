import { combineReducers, createStore,applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { LOGOUT } from '../types/authentication.types'

//Reducers
import { AuthenticationReducer } from "../reducers/authentication.reducer";
import { NavigationReducer } from '../reducers/navigation.reducer';
import { OrganizationReducer } from '../reducers/organization.reducer';
import { ProvincesReducer } from '../reducers/provinces.reducer';
import { SnackBarReducer } from '../reducers/snackbar.reducer';
import { LoadingReducer } from '../reducers/loading.reducer';
import { AnalyticsReducer } from '../reducers/analytics.reducer';
import { initialState } from '../reducers/authentication.reducer';
import {CovidScreeningReducer} from '../reducers/covidscreening.reducer';

const rootReducer = combineReducers({
    Authentication: AuthenticationReducer,
    Navigation: NavigationReducer,
    SnackBars: SnackBarReducer,
    Organization: OrganizationReducer,
    Provinces: ProvincesReducer,
    Loading: LoadingReducer,
    AnalyticsReducer: AnalyticsReducer,
    CovidScreeningReducer: CovidScreeningReducer,
});

const persistConfig = {
    key: 'Authentication',
    storage: storage,
    whitelist: ['Authentication']
}


const persistentReducer = persistReducer(persistConfig, rootReducer);
const middleware = applyMiddleware(thunk);
const store = createStore(persistentReducer, middleware);
const persistor = persistStore(store);


export { persistor, store };