import React, { useState } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import useStyles from "./login.styles";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import LoginBtn from "../../../components/login-btn/loginBtn.component";
import { useDispatch } from "react-redux";
import {
  setLogin,
} from "../../../redux/actions/authentication.action";
import validator from 'validator'
import isEmail from 'validator/lib/isEmail';
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathologinscreenimage from '../../../assets/images/loginscreenimage2.png'
import CssBaseline from '@mui/material/CssBaseline';
import Button from '@mui/material/Button';
import InputAdornment from '@mui/material/InputAdornment';
import { setShowLoading } from "../../../redux/actions/loading.action";
import { useMediaQuery } from 'react-responsive'
const Login = (props) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 1224px)'
  })
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [showPassword, setShowPassword] = useState(false); 
  const { handleSubmit, control } = useForm();

  const onSubmitLoginIn = (data) => {
    
    const { email, password } = data;
    setShowPassword(false);
    dispatch(setShowLoading(true));
    if (!!email && password){
      dispatch(setLogin(email, password, () => {

          history.push({pathname: "/wellbeing"});
          
      }))
    }

  };

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& .MuiInputBase-input': {
      color: '#FFF'
    },
    '& label.Mui-focused': {
      color: '#FFF',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#FFF',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#FFF',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#CCC',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#FFF',
      },
    },
  });

  
  return (

    
    

    <Grid container spacing={0}>
        <CssBaseline/>
        <Grid 
        item 
        xs={12} 
        sm={12} 
        md={7}
        lg={5}
        component="main">
              <div className={classes.container}>

                  <div style={{ flex:0.5, paddingLeft:50,  marginTop:50, justifyContent:'center' }}>
                      <img
                        color={'none'}
                        src={empathowhitelogo}
                        style={{ flex:0.5, width: '40%', height:'auto'}}
                      /> 
                  </div>
                  <div style={{ flex: 4, display:'flex', justifyContent:'center', alignItems:'center' }}>

                    <div style={{ width: '60%', display:'flex', flexDirection:'column', justifyContent:'center', alignContent:'center', alignSelf:'center' }}>
                        
                        <div style={{color:'#FFF', fontSize:'4em', margin:'20px 0px 20px 0px', fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
                          Welcome!
                        </div>

                        <form className={classes.form} onSubmit={handleSubmit(onSubmitLoginIn)}>

                          <div style={{ margin:'20px 0px 10px 0px' }}>

                            <div className={classes.inputlabel}>
                                Your Email
                            </div>
                          
                            <Controller
                              name="email"
                              control={control}
                              defaultValue=""
                              rules={{ 
                                required: "Email is required",
                                validate: (value) => {
                                  if ( isEmail(value) ) {
                                    return true
                                  } else {
                                    return "Please Enter a Valid Email Address"
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  variant="outlined"
                                  placeholder="Enter Your Email"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error} 
                                  helperText={error ? error.message : null}
                                />
                              )}
                            />
                          </div>
                          <div style={{ margin:'20px 0px 20px 0px' }}>

                            <div className={classes.inputlabel}>
                                Password
                            </div>

                            <Controller
                              name="password"
                              control={control}
                              defaultValue=""
                              rules={{ 
                                required: "Password is required",
                                validate: (value) => {
                                  if (validator.isStrongPassword(value, {
                                    minLength: 8, minLowercase: 1,
                                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                                  })) {
                                    return true
                                  } else {
                                    return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
                                  }
                                }
                              }}
                              render={({
                                field: { onChange, value },
                                fieldState: { error },
                              }) => (
                                <LoginTextField
                                  className={classes.text_field}
                                  type={showPassword ? 'text' : 'password'}
                                  variant="outlined"
                                  placeholder="Enter Your Password"
                                  value={value}
                                  onChange={onChange}
                                  error={!!error}
                                  helperText={error ? error.message : null}
                                  InputProps={{
                                    endAdornment:
                                      <InputAdornment>
                                        <Button style={{ color:'#FFF' }} onClick={() => setShowPassword(!showPassword)}>
                                        {showPassword ? 'Hide' : 'Show'}
                                        </Button>
                                      </InputAdornment>
                                  }}
                                />
                              )}
                            />
                          </div>

                          <LoginBtn label={'Continue'} />

                        </form>


                        <div style={{ textAlign:'center', fontWeight:'bold', marginTop:20, marginBottom:20 }}>
                              <a href="/resetpassword" style={{ color:"#57bff1", textDecoration:'none' }}> FORGOT YOUR PASSWORD? </a>
                        </div>

                        {/* <div style={{ textAlign:'center', fontWeight:'bold', marginTop:30, marginBottom:20 }}>
                              <a href="/verifyaccount" style={{ color:"#FFF", textDecoration:'none' }}> DON'T HAVE AN ACCOUNT? <span style={{ color:"#57bff1" }}>GET STARTED NOW</span> </a>
                        </div> */}

                    </div>
                  </div>

                  <div style={{ flex:1, display:'flex', justifyContent:'center', alignItems:'flex-end', paddingBottom:30  }}>
                    {/* <div style={{ padding:30, textAlign:'center', color:'#FFF', fontSize:'0.7em', fontWeight:'600' }}>
                        By tapping "continue" you agree to Empatho Corp's <br/>
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}>terms of service</a></span> and 
                        <span><a href="" style={{ color:"#57bff1", textDecoration:'none' }}> privacy policy</a></span>.
                    </div> */}
                  </div>    
                
              </div>
              
        </Grid>
        
        <Grid
          item
          xs={false}
          sm={false}
          md={5}
          lg={7}
          sx={{
            backgroundImage: `url(${empathologinscreenimage})`,
            backgroundRepeat: 'no-repeat',        
            backgroundSize: 'contain',
            backgroundPosition:'center',
            width:'100%',
            height:'100vh'
          }}
        >
          {/* {isDesktopOrLaptop && <p> <img src={empathologinscreenimage} style={{ flex: 0.5, width:'auto', height:'110vh', right:0, top: -150, position:'absolute'}}/></p>} */}
           
        </Grid>

    </Grid>

  );
};

export default Login;
