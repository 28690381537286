import {createTheme} from "@material-ui/core/styles";
const theme = createTheme({
    palette: {
        primary: {
            main: '#533AED',
            // dark: blue[900],
            // light: blue[700]
        }
    }
})

export default theme;