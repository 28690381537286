import { DELETESNACKBAR, SETSNACKBAR } from "../types/snackbar.types";

const initialState = {
    snackbars: []
}

export const SnackBarReducer = (state=initialState,action) => {
    switch(action.type){
        case SETSNACKBAR:
            return {
                ...state,
                snackbars: [...state.snackbars,action.payload]
            }
        case DELETESNACKBAR:
            return {
                ...state,
                snackbars: state.snackbars.filter(snackbar => snackbar.key !== action.payload.data.key)
            }
        default:
            return state;
    }
}