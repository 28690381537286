import React, { useState, useEffect } from "react";
import useStyles from "./formChangePassword.style";
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import { createPassword } from "../../../redux/actions/authentication.action";
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import { alpha, styled } from '@mui/material/styles';
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathoactionableresults from '../../../assets/images/actionableresults.png'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../../redux/actions/loading.action";
import CircularProgress from '@mui/material/CircularProgress';
import { getUsers } from "../../../redux/actions/authentication.action";
import Autocomplete from '@mui/material/Autocomplete';
import validator from 'validator'

const FormChangePassword = ({ UserData, closeUserDialog, edit }) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, impersonating, impersonatedorganization, permissions } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading]= useState(false);
  const [organization, setOrganization] = useState(false);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);
  const [permissionsList, setPermissionsList] = useState(permissions);



  useEffect(() => {

    if (edit){
      
      setValue("email", UserData.userdata.user_email);
      setValue("currentpassword", "");
      setValue("password", "");
      setValue("confirmpassword", "");
    }

  },[user])

  

  const submitChangePass = (data) => {

    if (data.password !== data.confirmpassword){

        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: "Passwords Do Not Match",
            key: "4",
            open: true,
          })
        )
  
      } else {
          dispatch(createPassword(data.email, data.password, 
            () => {
    
              setActionLoading(false);
              closeUserDialog()
    
            },
            () => {
              setActionLoading(false);
            }  
          ))
      }

    }

  

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInputBase-input':{
      height:15,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });

  return (

      <form className={classes.form} onSubmit={handleSubmit(submitChangePass)}>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                You will change password for
            </div>

            <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: "Email is required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  disabled={true}
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Email"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
                Confirm Current Password
            </div>

            <Controller
              name="currentpassword"
              control={control}
              defaultValue=""
              rules={{ 
                required: "Current Password is Required",
                validate: (value) => {
                  if (validator.isStrongPassword(value, {
                    minLength: 8, minLowercase: 1,
                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                  })) {
                    return true
                  } else {
                    return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
                  }
                }
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Current Password"
                  type="password"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

     
          <div style={{ margin:10 }}>

          <div className={classes.inputlabel}>
              Enter New Password
          </div>

      <Controller
      name="password"
      control={control}
      defaultValue=""
      rules={{ 
        required: "New Password is required",
        validate: (value) => {
          if (validator.isStrongPassword(value, {
            minLength: 8, minLowercase: 1,
            minUppercase: 1, minNumbers: 1, minSymbols: 1
          })) {
            return true
          } else {
            return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
          }
        }
      }}
      render={({
        field: { onChange, value },
        fieldState: { error },
          }) => (
        <LoginTextField
          className={classes.text_field}
          variant="outlined"
          placeholder="New Password"
          type="password"
          value={value}
          onChange={onChange}
          error={!!error}
          disabled={loading}
          helperText={error ? error.message : null}
        />
      )}
        />
    </div>

          <div style={{ margin:10 }}>

            <div className={classes.inputlabel}>
            </div>

            <Controller
              name="confirmpassword"
              control={control}
              defaultValue=""
              rules={{ 
                required: "Retype New Password",
                validate: (value) => {
                  if (validator.isStrongPassword(value, {
                    minLength: 8, minLowercase: 1,
                    minUppercase: 1, minNumbers: 1, minSymbols: 1
                  })) {
                    return true
                  } else {
                    return "Password Should be minimum 8 characters with at least 1 uppercase letter, 1 number and 1 symbol"
                  }
                }
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <LoginTextField
                  className={classes.text_field}
                  variant="outlined"
                  placeholder="Retype new password"
                  type="password"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  disabled={loading}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </div>

          <br/>
      
          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
            <Button 
              type="submit"
              style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 250 }}
              variant="text"  
              disabled={loading}
              disableElevation>
              <div style={{ padding:"10px 10px", fontSize: 13, letterSpacing: 1.5}}>
                { edit ? 'CHANGE PASSWORD' : 'CHANGE PASSWORD' }
              </div>
              {
                loading ?
                  <div style={{ justifyContent:'center', alignItems:'center' }}>
                    <CircularProgress 
                      size={20}
                      sx= {{ color:"#FFF" }}
                    />
                  </div>
                :
                null
              }

            </Button>
          </div>
            <Button style={{color: 'black', fontSize: 14, marginTop: 20}} onClick={() => closeUserDialog()}>Cancel</Button>
      </form>    

  );
};

export default FormChangePassword;
