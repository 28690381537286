import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import VisibilityIcon from '@material-ui/icons/Visibility';

export const EditAction = ({ callback }) => {
  return (
    <Button variant="text" onClick={callback}>
      <EditIcon />
    </Button>
  );
};

export const DeleteAction = ({ callback }) => {
  return (
    <Button variant="text" onClick={callback}>
      <DeleteIcon />
    </Button>
  );
};

export const ShowAction = ({ callback }) => {
  return (
    <Button variant="text" onClick={callback}>
      <VisibilityIcon />
    </Button>
  );
};
