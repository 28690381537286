import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";
import { useDispatch, useSelector } from "react-redux";
import { DeleteSnackbar } from "../../redux/actions/snackbar.action";

const Alert = (props) => {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
};

const CustomSnackBar = () => {
  const dispatch = useDispatch();
  const { snackbars } = useSelector((state) => state.SnackBars);

  const { snackbarMessage, snackType, key } = snackbars[0] || {};

  const toggle = (e, reason) => {
    if (reason === "clickaway") {
      return;
    }

    dispatch(DeleteSnackbar(snackbars[0]));
  };

  return (
    <div>
      {snackbars.length > 0 && (
        <Snackbar
          open={snackbars[0].open}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          autoHideDuration={2000}
          onClose={() => toggle()}
          key={key ? key : ""}
        >
          <Alert onClose={() =>toggle()} severity={snackType}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
      )}
    </div>
  );
};

export default CustomSnackBar;
