import React, { useState, useEffect } from "react";
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { alpha, styled } from '@mui/material/styles';
import useStyles from "./nomatch.styles";
import { useHistory } from "react-router-dom";
import empathowhitelogo from '../../assets/images/empathowhitelogo.png';
import empathologinscreenimage from '../../assets/images/loginscreenimage2.png'
import CssBaseline from '@mui/material/CssBaseline';
import { useSelector } from "react-redux";

const NoMatch = () => {

    const classes = useStyles();
    const history = useHistory();
    const { user, isLoggedIn } = useSelector((state) => state.Authentication);
    
    useEffect(() => {
        history.push({ pathname: "/" });
    }, [])

    return (

    <Grid container spacing={0}>
        <CssBaseline/>
        <Grid 
        item 
        xs={12} 
        sm={12} 
        md={7}
        lg={5}
        component="main">
            <div className={classes.container}>

                <div style={{ flex:0.5, paddingLeft:50,  marginTop:50, justifyContent:'center' }}>
                    <img
                        color={'none'}
                        src={empathowhitelogo}
                        style={{ flex:0.5, width: '200px', height:'auto'}}
                    /> 
                </div>
                <div style={{ flex: 4, display:'flex', justifyContent:'center' }}>

                    <div style={{ width: '60%', display:'flex', flexDirection:'column', justifyContent:'center' }}>
                        
                        <div style={{ fontWeight: 'bold', color:'#FFF', fontSize:'3em', margin:'20px 0px 20px 0px' }}>
                            Unauthorized!
                        </div>
                        <div style={{ fontWeight: 'bold', color:'#FFF', fontSize:'1em', margin:'20px 0px 20px 0px' }}>
                            It appears that you don't have permission to access this page. Please make sure you're authorized to view this content. If you think you 
                            should be able to view this page, please notify support of the problem.
                        </div>

                    </div>
                    
                </div>  
                
            </div>
            
        </Grid>
        
        <Grid
        item
        xs={false}
        sm={false}
        md={5}
        lg={7}
        sx={{
            // backgroundImage: `url(${empathologinscreenimage})`,
            // backgroundRepeat: 'no-repeat',        
            // backgroundSize: 'contain',
            // backgroundPosition:'center',
            // width:'100%',
            // height:'100vh'
        }}
        >
            <img src={empathologinscreenimage} style={{ width:'auto', height:'110vh', right:-10, top: -150, position:'absolute'}}/>
        </Grid>

    </Grid>
        
    )
}

export default NoMatch;