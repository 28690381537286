import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import useStyles from "./users.styles";
import CustomTable from "../../components/table/table.component";
import { EditAction } from "../../components/table/tableActions";
import AddButton from "../../components/buttons/add-button/addButton.component";
import Modal from "../../components/modal/modal.component";
//import FormOrganization from "./form-organization/formOrganization.component";
import ScreeningSurveyForm from "../../components/covidSurveyForm/covidSurveyForm.component";
import { useHistory } from "react-router-dom";
import { ArrowDownward, AddCircle, PersonAdd } from "@mui/icons-material";
import Button from "@mui/material/Button";
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganization,
  updateOrganization,
  getOrganization,
} from "../../redux/actions/organization.action";
import { getProvinces } from "../../redux/actions/provinces.action";
import empathologopurple from "../../assets/images/empathologopurple.png";
import { setImpersonating } from "../../redux/actions/authentication.action";
import impersonate from "../../assets/images/impersonate.png";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import CancelIcon from "@mui/icons-material/Cancel";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import ProviceIcon from "../../assets/images/location-pin.png";
import ResourceIcon from "../../assets/images/globe.png";
import StatusIcon from "../../assets/images/statusicon.png";
import LastUpdateIcon from "../../assets/images/clock.png";
import UpdateIcon from "../../assets/images/refreshcovidscree.png";
import actionicon from "../../assets/images/actionicon.png";
import { getCovidScreening } from "../../redux/actions/covidscreening.action";
import moment from "moment";
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";
import { styled, useTheme } from '@mui/material/styles';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import empathologo from '../../assets/images/empathologo.png';
import empathopurplelogo from '../../assets/images/empathopurplelogo.png';

import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const CovidSurvey = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [organization, setOrganization] = useState(null);
  const [covidScreening, setCovidScreening] = useState(null);
  // const { organizations,isLoading } = useSelector((state) => state.Organization);
  const { provinces } = useSelector((state) => state.Provinces);
  const { covidscreening, isLoading } = useSelector(
    (state) => state.CovidScreeningReducer
  );
  const {
    user,
    userlist,
    permissions,
    impersonating,
    impersonatedorganization,
  } = useSelector((state) => state.Authentication);
  const history = useHistory();
  const [showConfirmImpersonating, setShowConfirmImpersonating] =
    useState(false);
  const [impersonatingOrganiztion, setImpersonatingOrganization] =
    useState(null);
  const [
    openAddEditScreeningSurveyDialog,
    setOpenAddEditScreeningSurveyDialog,
  ] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editScreeningSurveyData, setEditScreeningSurveyData] = useState(null);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  
  useEffect(() => {
    dispatch(getCovidScreening(user?.email));
    // dispatch(getProvinces(user?.email));
  }, [dispatch, user]);

  const returnLocation = (location_id) => {
    let locationobject = provinces.filter(
      (province) => province.id == location_id
    );

    if (locationobject.length > 0) {
      return locationobject[0];
    }
  };

  const confirmImpersonatingOrganization = () => {
    
    dispatch(setImpersonating(true, impersonatingOrganiztion.id));
    // dispatch(getUsers(user?.email, user?.userdata?.organization_id, true, impersonatingOrganiztion.organization_id));
    setShowConfirmImpersonating(false);
  };

  const openConfirmImpersonatingDialog = (row) => {
    setShowConfirmImpersonating(true);
    setImpersonatingOrganization(row);
  };

  const closeImpersonationConfirmationDialog = () => {
    setImpersonatingOrganization(null);
    setShowConfirmImpersonating(false);
  };

  const stopImpersonating = () => {
    dispatch(setImpersonating(false, ""));
    // dispatch(getUsers(user?.email, user?.userdata?.organization_id, false, null));
  };

  const drawerWidth = 150;

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: window.innerWidth,
    height:100,
    backgroundColor:'#FFF',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));



  const openAddScreeningSurveyDialog = (row, edit) => {
    
    if (edit === true) {
      setEditScreeningSurveyData(row);
      setAdd(false);
      setEdit(true);
    } else {
      setEdit(false);
      setAdd(true);
    }

    setOpenAddEditScreeningSurveyDialog(true);
  };

  const closeAddScreeningSurveyDialog = () => {
    setOpenAddEditScreeningSurveyDialog(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const columns = [
    {
      field: "province",
      headerName: "Province",
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={ProviceIcon} style={{ width: 20 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        if (row.location) {
          return <div style={{ marginRight: 50 }}>{row.location}</div>;
        } else {
          return "";
        }
      },
    },
    {
      field: "resource",
      headerName: "Resource",
      minWidth: 350,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={ResourceIcon} style={{ width: 20 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        if (row.resource) {
          return (
            <div>
              <a href={row.resource} style={{ color: "#533AED" }}>
                {row.resource}
              </a>
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      flex: 1,
      minWidth: 150,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={StatusIcon} style={{ width: 20 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        if (row.is_active) {
          return (
            <div
              style={{
                color: row.is_active ? "#1CDC93" : "#B7B7B7",
                marginRight: 50,
                fontWeight: 'bold'
              }}
            >
              {row.is_active ? "Live" : "Draft"}
            </div>
          );
        } else {
          return "";
        }
      },
    },
    {
      field: "last_update",
      headerName: "Last update",
      sortable: true,
      minWidth: 150,
      flex: 1,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={LastUpdateIcon} style={{ width: 20 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        return (
          <div style={{ textAlign: "center", marginRight: 25 }}>
            {moment(row.updated_date).format("MMMM Do , YYYY")}
          </div>
        );
      },
    },
    {
      field: "update",
      headerName: "Update",
      sortable: false,
      hide:
        user?.userdata?.profile_name === "isSuperAdmin" &&
        impersonating === false
          ? false
          : true,
      flex: 1,
      minWidth: 150,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={UpdateIcon} style={{ width: 25 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        return (
          <div style={{ marginRight: 10 }}>
            {row.is_active ? (
              <Button
                style={{
                  backgroundColor: "#E767FF",
                  color: "#FFF",
                  fontSize: 10,
                  borderRadius: 25,
                  paddingRight: 40,
                  paddingLeft: 40,
                  letterSpacing: 1.5,
                }}
              >
                UPDATE
              </Button>
            ) : (
              <Button
                style={{
                  backgroundColor: "#533aed",
                  color: "#FFF",
                  fontSize: 10,
                  borderRadius: 25,
                  paddingRight: 40,
                  paddingLeft: 40,
                  letterSpacing: 1.5,
                }}
              >
                GO LIVE
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      headerAlign: "center",
      align: "center",
      renderHeader: (params) => {
        return (
          <div style={{ textTransform: "none", lineHeight: 1, padding: 10 }}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <img src={actionicon} style={{ width: 20 }} />
              </div>
              <div>{params.colDef.headerName}</div>
            </div>
          </div>
        );
      },
      renderCell: ({ row }) => {
        return (
          <div style={{ marginRight: 20 }}>
            <IconButton
              onClick={() => openAddScreeningSurveyDialog(row, true)}
              aria-label="edit"
              sx={{ backgroundColor: "#EEE" }}
            >
              <EditIcon style={{ color: "#533AED" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const accesstoken = user?.accesstoken || user?.userdata.access_token;


  return (
    <Grid container style={{ padding: 0 }}>

{isTabletOrMobile && <p>
  {/* <Grid container lg={12} xl={12} md={12} xs={12} direction="row"> */}
        <AppBar position="absolute" open={false}>
          <Toolbar
            sx={{
              paddingTop: 2,
              width: '100%',
              display:'flex',
              flexDirection:'row', 
              height: 100,
            }}
           >
            <div style={{ display:'flex', justifyContent: 'left', height: 100 }}>
                
              <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            sx={{color: '#533AED', marginBottom: 5, justifySelf: 'left'}}
            onClick={handleDrawerOpen}
          >
            <MenuIcon />
            </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#533AED',

          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={empathologo} style={{ width: '20%', height: 'auto',  }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/wellbeing")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={wellbeingicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/organizations")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={organizationicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/covidsurvey")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={covidsurveyicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/users")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={usersicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/returntowork")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={returntoworkicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>

        </List>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/faq")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={faqicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => history.push("/settings")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={settingsicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button  onClick={() => {
              dispatch(
                setLogout(user?.email, accesstoken, async () => {
                  
                  dispatch(resetAuthState());  
                  
                  localStorage.removeItem("currentUser");
                  localStorage.removeItem("persist:Authentication");
                  localStorage.clear();

                  await persistor.flush();
                  await persistor.purge();

                  history.push({ pathname: "/" });
          

                })
              );
              }}
              style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={logouticon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
              {/* <ListItemText primary={"Home"} /> */}
            </ListItem>
        </List>
      </Drawer>

        {/* </Grid> */}
  </p>}

  {isDesktopOrLaptop && <p>
    
    <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "50px",
          marginBottom: "10px",
        }}
      >
        <Grid item lg={12} xl={12} md={12} xs={12}>
          <div style={{ letterSpacing: 1.5, fontSize: 50, marginBottom: 20, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
            COVID-19 Screening
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          xl={12}
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "flex-end" }}
        >
          <Button
            style={{ fontSize: 12, backgroundColor: "#533aed", color: "#FFF", marginRight: 5, marginLeft: 5, borderRadius: 25, marginBottom:20 }}
            variant="text"
            onClick={() => openAddScreeningSurveyDialog()}
            disableElevation
          >
            <AddCircle />
            <div style={{ padding: "5px 10px", letterSpacing: 1 }}>
              Add Screening Survey
            </div>
          </Button>
        </Grid>
      </Grid>

      <Grid item lg={12} xl={12} md={12} xs={12}
      style={{marginTop: 120, width: window.innerWidth - 200}}
      >
        <CustomTable
          columns={columns}
          rows={covidscreening || []}
          rowId={"id"}
          loading={isLoading}
        />
      </Grid>
    </p>}

    {isTabletOrMobile && <p>
      
      <Grid
        container
        justifyContent={"space-between"}
        alignItems={"center"}
        style={{
          height: "50px",
          //marginBottom: "10px",
        }}
      >
        <Grid item lg={12} xl={12} md={12} xs={12}>
          <div style={{ letterSpacing: 1.5, fontSize: 30, marginBottom: 20, fontFamily: 'Sofia-Pro', fontWeight:'bold', marginTop: 20 }}>
            COVID-19 Screening
          </div>
        </Grid>

        <Grid
          item
          lg={12}
          xl={12}
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
          <Button
            style={{ fontSize: 12, backgroundColor: "#533aed", color: "#FFF", marginRight: 5, marginLeft: 5, borderRadius: 25, marginBottom:20 }}
            variant="text"
            onClick={() => openAddScreeningSurveyDialog()}
            disableElevation
          >
            <AddCircle />
            <div style={{ padding: "5px 10px", letterSpacing: 1 }}>
              Add Screening Survey
            </div>
          </Button>
        </Grid>
      </Grid>

      <Grid item lg={12} xl={12} md={12} xs={12}
      style={{marginTop: 90, width: '100%', height: window.innerHeight - 400}}
      >
        <CustomTable
          columns={columns}
          rows={covidscreening || []}
          rowId={"id"}
          loading={isLoading}
        />
      </Grid>
      </p>}
      

      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#FFF",
            borderRadius: 15,
            padding: 20,
            zIndex: 1,
          },
        }}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            //DO NOTHING IF BACKDROP CLICK
          }
        }}
        open={showConfirmImpersonating}
        disableEscapeKeyDown={true}
        maxWidth={"md"}
      ></Dialog>

      {/* ADMIN ADD USER DIALOG */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#FFF",
            borderRadius: 5,
            zIndex: 1,
            height: 800,
          },
        }}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            //DO NOTHING IF BACKDROP CLICK
          }
        }}
        open={openAddEditScreeningSurveyDialog}
        disableEscapeKeyDown={true}
        maxWidth={"lg"}
      >
        <div style={{ width: "40vw", borderRadius: 15, height: '100%', padding: '15px 0px' }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <a
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                padding: 10,
              }}
              onClick={() => closeAddScreeningSurveyDialog()}
            >
              <CancelIcon style={{ color: "#999" }} />
            </a>
          </div>
          <div style={{ fontSize: 25, textAlign: "center",}}>
            Screening Survey
          </div>
          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              height: "calc(100% - 85px)",
            }}
          >
            <div style={{ width: "100%", height: "100%" }}>
              <ScreeningSurveyForm
                add={add}
                edit={edit}
                closeAddScreeningSurveyDialog={closeAddScreeningSurveyDialog}
                ScreeningSurveyData={editScreeningSurveyData}
              />
            </div>
          </div>
        </div>
      </Dialog>
    </Grid>
  );
};

export default CovidSurvey;
