import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import { Grid } from "@material-ui/core";
import Piechart from "../../components/piechart/pierchart.component";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Divider from "@mui/material/Divider";
import {
  VictoryChart,
  VictoryAxis,
  VictoryScatter,
  VictoryLine,
  VictoryTooltip,
  VictoryPie,
} from "victory";
import moment from "moment";
import battery from "../../assets/images/batterychart.png";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import { width } from "@mui/system";

const Onboardingtoursecond = () => {
  const history = useHistory();
  return (
    <div>
      
      <div
        style={{
          fontWeight: 500,
          fontSize: 32,
          paddingTop: 70,
          marginLeft: "35px",
          paddingBottom: 30,
        }}
      >
        Onboarding Tour
      </div>

      <div>
        <Paper
          sx={{
            width: "75%",
            marginLeft: "35px",
            marginRight: "35px",
            padding: 8,
            minHeight: "321px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0px 4px 5px rgba(0, 0, 0, 0.15)",
            borderRadius: "17px",
            // display: "flex"
          }}
        >
          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            xs={12}
            direction="column"
            // spacing={2}
            container
          >
            <div
              style={{
                fontWeight: 700,
                fontSize: 28,
                width:'50%',
                color: "#23262F",
              }}
            >
              Domain scores will be the new way of talking seriously about your
              team’s wellbeing.
            </div>
          </Grid>

          <Grid
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop:30
            }}
          >

              <Grid
                item
                lg={12}
                xl={12}
                md={12}
                xs={12}
                direction="column"
                // spacing={2}
                container
              >
                  <div
                    style={{
                      fontWeight: 400,
                      fontSize: 14,
                      color: "#23262F",
                      width:'80%'
                    }}
                  >
                    <p>
                      Empatho will calculate the data from all of your employees using
                      the Empatho app to provide you with scores from different key
                      domains that make up the wellbeing score:
                    </p>
                    <ul>
                      <li>Risk of burnout</li>
                      <li>Energy level</li>
                      <li>Readiness for mental tasks</li>
                      <li>Readiness to handle stress</li>
                      <li>Readiness to be productive</li>
                    </ul>
                    <p>
                      We know that understanding scientific wellbeing scores is not
                      for everyone, so we placed a label underneath the score that
                      defines what each score means in just one word. There are
                      also colours to guide you. Try to stay in the green zone.
                    </p>
                  </div>
              </Grid>

              <Grid
                item
                lg={7}
                xl={7}
                md={7}
                xs={7}
                direction="column"
                // spacing={2}
                container
              >
                  <div style={{ display:'flex', flexDirection:'row', justifyContent:'flex-end' }}>
                      <Piechart
                        data={65}
                        dimension={""}
                        label={"SATISFACTORY"}
                        color={"#F7BC2D"}
                        radius={70}
                        strokeWidth={16}
                        scoreLabelSize={25}
                        scoreTextColor={"#23262F"}
                        dimensionLabelSize={12}
                        labelSize={28}
                        showLabel={false}
                        showGreyCircle={true}
                      />
                      <Piechart
                        data={80}
                        dimension={""}
                        label={"VERY GOOD"}
                        color={"#1CDC93"}
                        radius={70}
                        strokeWidth={16}
                        scoreLabelSize={25}
                        scoreTextColor={"#23262F"}
                        dimensionLabelSize={12}
                        labelSize={28}
                        showLabel={false}
                        showGreyCircle={true}
                      />
                  </div>
              </Grid>

          </Grid>

          <Grid
            style={{
              marginTop:20,
              height: 1,
              backgroundColor:'#D1D0D7',
            }}
          ></Grid>

          <Grid
            style={{
              marginTop:20,
              display: "flex",
              flexDirection: "row",
              justifyContent:"flex-end"
            }}
          >
            <Button
              onClick={() => history.push("/onboardingtour")}
              style={{
                backgroundColor: "#533AED",
                borderRadius: 25,
                color: "#EBEAED",
                width: 158,
                height: 46,
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              PREVIOUS
            </Button>
            <div style={{ paddingLeft: 4, paddingRight: 4 }}></div>
            <Button
              onClick={() => history.push("/wellbeing")}
              style={{
                backgroundColor: "#533AED",
                borderRadius: 25,
                color: "#EBEAED",
                width: 158,
                height: 46,
                fontWeight: 600,
                fontSize: "12px",
                lineHeight: "17px",
                display: "flex",
                alignItems: "center",
                textAlign: "center",
                letterSpacing: "2px",
              }}
            >
              FINISH
            </Button>
          </Grid>
        </Paper>
      </div>
    </div>
  );
};

export default Onboardingtoursecond;
