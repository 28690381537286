import Button from "@material-ui/core/Button";
import SaveIcon from "@material-ui/icons/Save";
import useStyles from "./saveButton.styles";

const SaveButton = ({ onClick }) => {

  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      variant="contained"
      color="primary"
      size="medium"
      type="submit"
      className={classes.button}
      startIcon={<SaveIcon />}
    >
      Save
    </Button>
  );
};

export default SaveButton;
