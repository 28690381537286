import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

const OrganizationSelect = ({data, selectedValue, setOrganization, readOnly}) => {

  return (
    <Autocomplete
      options={data}
      value={selectedValue}
      onChange={(event, newValue) => {
        setOrganization(newValue || {});
      }}
      inputValue={selectedValue?.organizationName || ''}
      getOptionLabel={(option) => option?.organizationName || ''}
      getOptionSelected={(option, value) => option?.pk === value?.pk }
      disabled={readOnly}
      renderInput={(params) => (
          <TextField {...params} label="Select Organization" variant="outlined" size="small" readOnly={readOnly}/>
      )}
    />
  );
};

export default OrganizationSelect;
