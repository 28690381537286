export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_PERMISSIONS = "SET_PERMISSIONS";
export const SET_USER_LIST = "SET_USER_LIST";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";
export const SET_IMPERSONATING = "SET_IMPERSONATING";
export const SET_VERIFIED_USER_EMAIL = 'SET_VERIFIED_USER_EMAIL';
export const SET_FAQ_LIST = 'SET_FAQ_LIST';
export const UPDATE_ORGANIZATION_LOGO = 'UPDATE_ORGANIZATION_LOGO';
export const SET_FILE_UPLOAD_PROGRESS = 'SET_FILE_UPLOAD_PROGRESS';