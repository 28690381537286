import React, { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import useStyles from "./organizations.styles";
import CustomTable from "../../components/table/table.component";
import { EditAction } from "../../components/table/tableActions";
import AddButton from "../../components/buttons/add-button/addButton.component";
import Modal from "../../components/modal/modal.component";
import FormOrganization from "./form-organization/formOrganization.component";
import OrganizationForm from "../../components/organization/organizationForm.component";
import { useHistory } from "react-router-dom";
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
//Redux
import { useDispatch, useSelector } from "react-redux";
import {
  addOrganization,
  updateOrganization,
  getOrganization,
  getOrganizationAdmins
} from "../../redux/actions/organization.action";
import {
  getProvinces
} from "../../redux/actions/provinces.action";
import empathologopurple from '../../assets/images/empathologopurple.png';
import { setImpersonating } from "../../redux/actions/authentication.action";
import impersonate from '../../assets/images/impersonate.png';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CancelIcon from '@mui/icons-material/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import IconButton from '@mui/material/IconButton';
import Lockicon from '../../assets/images/lockicon.png';
import Emailicon from '../../assets/images/lockicon.png';
import AddressIcon from '../../assets/images/addressorganization.png';
import UserIcon from '../../assets/images/usericon.png';
import actionicon from '../../assets/images/actionicon.png';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import empathologo from '../../assets/images/empathologo.png';
import empathopurplelogo from '../../assets/images/empathopurplelogo.png';
import InputAdornment from '@mui/material/InputAdornment';
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Menu } from '../../components/drawer/drawer-body/drawer.component';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";

import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';




const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const drawerWidth = 150;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: window.innerWidth,
  height:100,
  backgroundColor:'#FFF',
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));




const StyledTextField = styled(TextField)({
  'label + &': {
    color:'pink'
  },
  '& label.Mui-focused': {
    color: '#000',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#000',
  },
  '& .MuiInputBase-input':{
      height:'100%',
      width:300
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      color:'#000',
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "#AAA"
    },
    '&:hover fieldset': {
      borderColor: '#C9C9C9',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#533aed',
    },
  },
});


const Organizations = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const classes = useStyles();
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = useState(false);
  const [organization, setOrganization] = useState(null);
  
  const { organizations,isLoading } = useSelector((state) => state.Organization);
  const { provinces } = useSelector((state) => state.Provinces);
  const { user, userlist, permissions, impersonating, impersonatedorganization } = useSelector((state) => state.Authentication);
  const history = useHistory();
  const [showConfirmImpersonating, setShowConfirmImpersonating] = useState(false);
  const [impersonatingOrganiztion, setImpersonatingOrganization] = useState(null);
  const [openAddEditOrganizationDialog, setOpenAddEditOrganizationDialog] = useState(false);
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editOrganizationData, setEditOrganizationData] = useState(null);
  const [filteredOrganizationList, setFilteredOrganizationList] = useState(null)
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
;
  useEffect(() => {
    if (user?.email){
      dispatch(getOrganization(user?.email));
      dispatch(getProvinces(user?.email));
      getOrganizationAdminsRequest();
    }

  }, [dispatch, user]);



  const getOrganizationAdminsRequest = () => {
    dispatch(getOrganizationAdmins({ email: user?.email }, 
      () => {

      },
      () => {

      }
    ));

  }

  useEffect(() => {
    setFilteredOrganizationList(organizations);
  }, [organizations])

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const returnLocation = (location_id) => {
    
    let locationobject = provinces.filter(province => province.id == location_id );
    
    if (locationobject.length > 0){
      return locationobject[0]
    }
  }

  const confirmImpersonatingOrganization = () => {
    
    dispatch(setImpersonating( true, impersonatingOrganiztion.id ));
    // dispatch(getUsers(user?.email, user?.userdata?.organization_id, true, impersonatingOrganiztion.organization_id));
    setShowConfirmImpersonating(false)
  }

  const openConfirmImpersonatingDialog = (row) => {
    setShowConfirmImpersonating(true);
    setImpersonatingOrganization(row);
  }

  const closeImpersonationConfirmationDialog = () => {
    setImpersonatingOrganization(null);
    setShowConfirmImpersonating(false)
  }

  const stopImpersonating = () => {
    dispatch(setImpersonating( false, null));
    // dispatch(getUsers(user?.email, user?.userdata?.organization_id, false, null));

  }

  const openAddOrganizationDialog = (row, edit) => {

    if (edit === true ) {

      setEditOrganizationData(row)
      setAdd(false);
      setEdit(true);
    }
    else {
      setEdit(false);
      setAdd(true);
    }
    
    setOpenAddEditOrganizationDialog(true)

  }

  const closeAddOrganizationDialog = () => {
    
    setOpenAddEditOrganizationDialog(false)

  }

  const checkIfDefaultOrganization = (organization_id) => {

    let org = organizations.filter( org => org.id == organization_id)
    return org[0]?.default
  }

  const returnFullAddress = (row) => {
    let a1 = row.address1 !== null ? `${row.address1}, ` : '';
    let a2 = row.address2 !== null ? `${row.address2}, ` : '';
    let a3 = row.address3 !== null ? `${row.address3}` : '';

    return a1 + a2 + a3
  }

  const columns = [
    {
      field: "",
      headerName: "",
      width:100,
      renderCell: ({ row }) => {
        return <img src={empathologopurple} style={{ width:'auto', height: 20 }} />
      }
    },
    {
      field: "name",
      headerName: "Organization",
      //flex:1,
      minWidth: 200,
      flex:1,
      renderHeader: (params) => {
  
        return (
           <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
               <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                 <div>
                     <img src={Lockicon} style={{ width:20 }}/>
                 </div>
                 <div>{params.colDef.headerName}</div>
               </div>
           </div>
        )
     },
    },
    {
      field: "contact_email",
      headerName: "Contact",
      minWidth: 200,
      flex:1,
      renderHeader: (params) => {
  
        return (
           <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
               <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                 <div>
                     <img src={Emailicon} style={{ width:20 }}/>
                 </div>
                 <div>{params.colDef.headerName}</div>
               </div>
           </div>
        )
     },
    },
    {
      field: "address",
      headerName: "Address",
      sortable: true,
      minWidth: 250,
      flex: 1,
      //flex:1,
      renderHeader: (params) => {
  
        return (
           <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
               <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                 <div>
                     <img src={AddressIcon} style={{ width:20 }}/>
                 </div>
                 <div>{params.colDef.headerName}</div>
               </div>
           </div>
        )
     },
      renderCell: ({ row }) => {
        return <div>{ `${returnFullAddress(row)}, ${returnLocation(row.location_id)?.abbreviation}` }</div>;
      },
    },
    {
      field: "licenses",
      headerName: "# Employees",
      sortable: true,
      minWidth:200,
      flex:1,
      headerAlign:'right',
      align:'right',
      renderHeader: (params) => {
  
        return (
           <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
               <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                 <div>
                     <img src={UserIcon} style={{ width:20 }}/>
                 </div>
                 <div>{params.colDef.headerName}</div>
               </div>
           </div>
        )
     },
      renderCell: ({ row }) => {
          return <div style={{ paddingRight:10 }}>{ row.licenses }</div>
      },
    },
    {
      field: "Impersonate",
      headerName: "Impersonate",
      sortable: false,
      hide: user?.userdata?.profile_name === 'isSuperAdmin' && impersonating === false ? false : true,
      minWidth:200,
      disableClickEventBubbling: true,
      flex:1,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center',  }}>
                  <div>
                    <img src={impersonate} style={{ width:25 }}/> 
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({ row }) => {
        return (

          
          <div>
          
             {
               checkIfDefaultOrganization(row.id) === false ?
               
              <Button 
                style={{ 
                    backgroundColor:'#533aed', 
                    color:'#FFF', 
                    fontSize: 10, 
                    borderRadius: 25, 
                    paddingRight:20, 
                   
                    paddingLeft:20 }} onClick={() => openConfirmImpersonatingDialog(row) }>Impersonate</Button>
              :
              null
            }
          </div>
          
        );
      },
    },
    {
      field: "Actions",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex:1,
      disableClickEventBubbling: true,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                      <img src={actionicon} style={{ width:20 }}/> 
                    </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({ row }) => {
        return (
          <div>
            <IconButton onClick={() => openAddOrganizationDialog(row, true)} aria-label="edit" sx={{ backgroundColor:'#EEE' }}>
                <EditIcon style={{ color:'#533AED' }}/>
              </IconButton> 
          </div>
        );
      },
    },
  ];

  const returnOrganization = (organization_id) => {

    let organizationobject = organizations.filter(organization => organization.id == organization_id );
    
      if (organizationobject.length > 0){
      return organizationobject[0]
      }
  }


  const searchOrganization = (event) => {
    
    // setSearchUserValue(event.target.value);
    let templist = [];

    if (event.target.value.length > 0){
        let templist = []

        templist = organizations.filter(( s ) => s.name.toLowerCase().includes(event.target.value.toLowerCase()) ); 
    
        setFilteredOrganizationList(templist);

    }
    else {

      setFilteredOrganizationList(organizations)
    }

  }

  const accesstoken = user?.accesstoken || user?.userdata.access_token;

  return (
    
    <Grid 
    
    container style={{ padding: 0 }}>

    <Grid container lg={12} xl={12} md={12} xs={12} direction="row">
    {isDesktopOrLaptop && <p>
      <AppBar position="absolute" open={true}>
        
        <Toolbar
          sx={{
            background: '#FFF',
            height: 150,
            display:'flex',
            flexDirection:'row', 
            justifyContent: 'space-between'
          }}
         >
            {/* <div style={{ height: '50%', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'1.2em' }}>
                Hello, <span style={{ fontWeight:'bold' }}>{user?.userdata?.first_name}</span>
            </div> */}
          
            <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                  
                  <StyledTextField 
                      variant="outlined" 
                      placeholder="Search Organizations"
                      autoFocus
                      sx={{
                          marginLeft:20
                      }}
                      InputProps={{
                          startAdornment:
                          <InputAdornment>
                              <SearchIcon sx={{ color:"#533AED" }} />
                          </InputAdornment>
                      }}
                      onChange={searchOrganization}
                      // value={searchOrganizationsValue}
                  />

                  <div style={{ marginLeft:20}}>
                      {/* <div style={{ color:'#000', fontWeight:'bold'}}>Show Status</div>
                      <div style={{ display:'flex', flexDirection:'row'}}>
                          <IconButton onClick={() => filterByStatus('FAIL')}>
                              <CheckCircleIcon sx={{ color:'#FF6767', fontSize:'1.4em' }} />
                          </IconButton>
                          <IconButton onClick={() => filterByStatus('PASS')}>
                              <CheckCircleIcon sx={{ color:'#58D8F2', fontSize:'1.4em' }}/>
                          </IconButton>
                          <IconButton onClick={() => filterByStatus('NONE')}>
                              <CheckCircleIcon sx={{ color:'#BBB', fontSize:'1.4em' }}/>
                          </IconButton>
                      </div> */}
                      
                      {/* <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                        }}
                      >
                        <MenuItem onClick={handleClose}>Profile</MenuItem>
                        <MenuItem onClick={handleClose}>My account</MenuItem>
                        <MenuItem onClick={handleClose}>Logout</MenuItem>
                      </Menu> */}
                  </div>
    
                  {/* <Button
                  type="contained"
                  sx={{
                      marginLeft: '10px',
                      backgroundColor:'#EEE',
                      color:'#000',
                      fontSize:'0.8em',
                      height:'100%'
                      // padding:'5px 10px',
                  }}
                  >
                      Filter by Admins
                  </Button> */}            
          </div>
            
              {
                impersonating === false ?
                  <div style={{ height: '50%', display:'flex', flexDirection:'row', alignSelf:'center' }}> 
                      <img src={empathopurplelogo} style={{ width: 'auto', height: '100%' }} />
                  </div>
                :
                <div style={{ fontWeight:'bold', borderRadius:10, paddingRight:20, paddingLeft:20, height: '50%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', color:'#FFF', backgroundColor:'#2ECDED'}}>
                    Impersonating {returnOrganization(impersonatedorganization)?.name}
                </div>
              }

        </Toolbar>
      </AppBar>
          </p>}
      {isTabletOrMobile && <p>
        {/* <Box style={{width: window.innerWidth, height: 130}}> */}
        <AppBar position="absolute" open={false} >
        
          <Toolbar
            sx={{
              paddingTop: 2,
              width: '100%',
              display:'flex',
              flexDirection:'column', 
              height: 100,
            }}
           >
             <div style={{ display:'flex', flexDirection:'row', height: 100 }}>
             <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            sx={{color: '#533AED', marginBottom: 5}}
            onClick={handleDrawerOpen}
          >
            
            <MenuIcon />
          </IconButton>
                    
                    <StyledTextField 
                        variant="outlined" 
                        placeholder="Search Organizations"
                        autoFocus
                        sx={{width:'60%',
                        
                        }}
                        InputProps={{
                            startAdornment:
                            <InputAdornment>
                                <SearchIcon sx={{ color:"#533AED" }} />
                            </InputAdornment>
                        }}
                        onChange={searchOrganization}
                        // value={searchOrganizationsValue}
                    />
       
            </div>
              
                {/* {
                  impersonating === false ?
                    <div style={{ height: '35%', display:'flex', flexDirection:'row', alignSelf: 'flex-start', marginBottom: 5, marginTop: 10 }}> 
                        <img src={empathopurplelogo} style={{ width: 'auto', height: '100%' }} />
                    </div>
                  :
                  <div style={{ fontWeight:'bold', borderRadius:10, paddingRight:20, paddingLeft:20, height: '50%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', color:'#FFF', backgroundColor:'#2ECDED'}}>
                      Impersonating {returnOrganization(impersonatedorganization)?.name}
                  </div>
                } */}

          </Toolbar>
        </AppBar>
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#533AED',

          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={empathologo} style={{ width: '20%', height: 'auto',  }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/wellbeing")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={wellbeingicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/organizations")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={organizationicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/covidsurvey")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={covidsurveyicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/users")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={usersicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/returntowork")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={returntoworkicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>

        </List>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/faq")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={faqicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => history.push("/settings")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={settingsicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button  onClick={() => {
              dispatch(
                setLogout(user?.email, accesstoken, async () => {
                  
                  dispatch(resetAuthState());  
                  
                  localStorage.removeItem("currentUser");
                  localStorage.removeItem("persist:Authentication");
                  localStorage.clear();

                  await persistor.flush();
                  await persistor.purge();

                  history.push({ pathname: "/" });
          

                })
              );
              }}
              style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={logouticon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
              {/* <ListItemText primary={"Home"} /> */}
            </ListItem>
        </List>
      </Drawer>
      
        {/* </Box> */}
        </p>}

      </Grid>

{
    isDesktopOrLaptop && <>
    <Grid
          container
          // justifyContent={"space-between"}
          // alignItems={"center"}
          style={{
            // width: '100%',
            // height: "50px",
            marginBottom: "10px"            
          }}
        >
          <Grid item lg={12} xl={12} md={12} xs={12} style={{ marginTop:100 }}>
            <div style={{ letterSpacing: 1.5, fontSize: 50, marginBottom: 20, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>Organizations</div>
          </Grid>

          <Grid
            item
            lg={12}
            xl={12}
            md={12}
            xs={12}
          >
              <div style={{ display: "flex", justifyContent: "flex-end", flexDirection:'row', alignItems:"center" }}>

                <Button 
                  style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25 }}
                  variant="text" 
                  onClick={() => openAddOrganizationDialog() }
                  disableElevation>
                    <AddCircle/>
                    <div style={{ padding:"5px 10px" }}>Add Organization</div>
                </Button>

                {
                  impersonating === true ?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#000', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, paddingRight:20, paddingLeft:20 }}
                    variant="text" 
                    disableElevation
                    onClick={() => stopImpersonating()}>
                        <div style={{ padding:"5px 10px" }}>Stop Impersonating</div>
                    </Button>
                  :
                  null
                }

              </div>

          </Grid>
        </Grid>

        <Grid item lg={12} xl={12} md={12} xs={12} style={{marginTop: 10}}>
          <CustomTable 
            columns={columns} 
            rows={filteredOrganizationList || []} 
            rowId={"id"}           
            loading={isLoading}
          />
        </Grid>
    </>
}

  {isTabletOrMobile && <p>
    <Grid
        container
        justifyContent={"space-between"}
        //alignItems={'flex-start'}
        style={{
          //marginLeft: -35,
          width: window.innerWidth,
          height: "50px",
          //marginBottom: "10px",
        }}
      >
        <Grid item lg={12} xl={12} md={12} xs={12}>
          <div style={{ letterSpacing: 1.5, fontSize: 40, marginBottom: 20, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>Organizations</div>
        </Grid>

        <Grid
          item
          lg={12}
          xl={12}
          md={12}
          xs={12}
          style={{ display: "flex", justifyContent: "flex-start" }}
        >
             
            <Button 
              style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, marginBottom: 20 }}
              variant="text" 
              onClick={() => openAddOrganizationDialog() }
              disableElevation>
                <AddCircle/>
                <div style={{ padding:"5px 10px" }}>Add Organization</div>
            </Button>

            {
              impersonating === true ?
              <Button 
                style={{ fontSize:12, backgroundColor:'#000', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, paddingRight:20, paddingLeft:20 }}
                variant="text" 
                disableElevation
                onClick={() => stopImpersonating()}>Stop Impersonating</Button>
              :
              null
            }

        </Grid>
      </Grid>

      <Grid item lg={12} xl={12} md={12} xs={12}
      style={{marginTop: 90, width: '90%', height: window.innerHeight - 400}}
      >
        <CustomTable 
          columns={columns} 
          rows={filteredOrganizationList || []} 
          rowId={"id"}           
          loading={isLoading}
        />
      </Grid>
    </p>}
      

      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={showConfirmImpersonating}
          disableEscapeKeyDown={true}
          maxWidth={'md'}
        >
          <div style={{ minHeight: '20vh', minWidth: '25vw', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
             <div style={{ fontWeight:'bold', fontSize: '1.4em' }}>Impersonating {organizations.filter( org => org.id == impersonatingOrganiztion?.id )[0]?.name}</div>
             <div style={{ fontSize: '1em', marginTop:20 }}>Are you sure you want to continue?</div>
             <div style={{ display:'flex', justifyContent:'flex-end', alignItems:'flex-end' }}>
                <Button style={{ backgroundColor:'#533aed', color:'#FFF', margin:5 }} onClick={() => confirmImpersonatingOrganization() }>Yes</Button>
                <Button style={{ backgroundColor:'#CCC', color:'#777', margin:5 }} onClick={() => closeImpersonationConfirmationDialog() }>Cancel</Button>
             </div>
          </div>
      </Dialog>

      {/* ADMIN ADD USER DIALOG */}
      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openAddEditOrganizationDialog}
          disableEscapeKeyDown={true}
          maxWidth={'lg'}
        >
      
        <div style={{ paddingRight:20, paddingLeft:20, width:'40vw', borderRadius:15 }}>
          <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeAddOrganizationDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </div>
          <div style={{ fontWeight:'bold', fontSize:25, textAlign:'center', marginBottom:10 }}>{edit ? 'Edit Organization' : 'Add New Organization'}</div>
          <div style={{ justifyContent:'center', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center' }}>
            <div style={{ width:'60%' }}>
              <OrganizationForm add={add} edit={edit} closeAddOrganizationDialog={closeAddOrganizationDialog} organizationData={editOrganizationData}/>
            </div>
          </div>
        </div>
    
      </Dialog>
     
    </Grid>
  );
};

export default Organizations;
