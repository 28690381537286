// import axios from "axios";
import { axiosService as axios } from "../../helpers/utils-authentication";
import { setSnackBar } from "./snackbar.action";
import { GETORGANIZATION, SET_META_DATA, SET_FILTERED_META_DATA, GET_ONBOARDING_TOUR_DATA, GET_ORGANIZATION_ADMINS } from "../types/organization.types";
import { appConfig } from "../../config/appconfig";
import { store } from '..//store/rootStore';
import { setShowLoading } from "./loading.action";
import { updateOrganizationLogo } from "./authentication.action";


export const addOrganization = ( body, successCallback, errorCallback ) => {
  return async (dispatch) => {

    let formData = new FormData();
    
    formData.append('email', body.email);
    formData.append('name', body.name);
    formData.append('location_id', body.location_id);
    formData.append('contact_email', body.contact_email);
    formData.append('contact_phone', body.contact_phone);
    formData.append('licenses', body.licenses);
    formData.append('address1', body.address1);
    formData.append('address2', body.address2);
    formData.append('address3', body.address3);
    formData.append('street', body.street);
    formData.append('website', body.website);
    formData.append('default', body.default);
    formData.append('file', body.file);
    formData.append('logo', body.logo);
    formData.append('organization_admin_user_id', body.organization_admin_user_id)

    return axios
      .post( `${appConfig.organizationPath}addorganization?email=${body?.email}`, formData)
      .then((resp) => {
        const { success, statusMessage } = resp.data;
        if (!success) {
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: statusMessage,
              key: "4",
              open: true,
            })
          );
          return;
        }

        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Organization created successfully",
            key: "1",
            open: true,
          })
        );
        successCallback()
      })
      .catch((error) => {
        
        errorCallback();

        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const updateOrganization = (body, successCallback, errorCallback) => {
  return async (dispatch) => {

    let formData = new FormData();

    formData.append('email', body.email);
    formData.append('id', body.id);
    formData.append('name', body.name);
    formData.append('location_id', body.location_id);
    formData.append('contact_email', body.contact_email);
    formData.append('contact_phone', body.contact_phone);
    formData.append('licenses', body.licenses);
    formData.append('address1', body.address1);
    formData.append('address2', body.address2);
    formData.append('address3', body.address3);
    formData.append('street', body.street);
    formData.append('website', body.website);
    formData.append('default', body.default);
    formData.append('file', body.file);
    formData.append('logo', body.logo);
    formData.append('organization_admin_user_id', body.organization_admin_user_id)

    return axios
      .post(`${appConfig.organizationPath}updateorganization?email=${body?.email}`, formData)
      .then((resp) => {
        const { success, statusMessage } = resp && resp.data;
        
        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Organization Updated",
            key: "1",
            open: true,
          })
        );

        successCallback();

      })
      .catch((error) => {
        
        errorCallback();
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const getOrganization = (email) => {
  
  let state = store.getState();

  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.organizationPath}getorganization`,
          {
            params: {
              email: email,
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: GETORGANIZATION,
          payload: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {

        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};


export const uploadOrganizationImage = ( body, successCallback, errorCallback ) => {
  return async (dispatch) => {

    const abort = axios.CancelToken.source();
    const timeout = setTimeout(() => abort.cancel(), 50000);

    let formData = new FormData();
    
    formData.append('email', body.email);
    formData.append('organization_id', body.organization_id);
    formData.append('file', body.file);
    return axios
      .post(
        `${appConfig.organizationPath}uploadorganizationimage?email=${body?.email}`,
        formData,
        { 
          headers:{ 'Content-Type': 'multipart/form-data' },
          cancelToken: abort.token 
        }
      )
      .then((resp) => {
        
        clearTimeout(timeout);

        if (!resp?.data?.success) {
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: resp?.data?.statusMessage,
              key: "4",
              open: true,
            })
          );
          return;
        }

        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Image Uploaded Successfully",
            key: "1",
            open: true,
          })
        );

        dispatch(updateOrganizationLogo(resp?.data?.image_url))
        successCallback(resp?.data?.image_url)
      })
      .catch((error) => {
        
        errorCallback();

        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage || "Our system is currently unavailable. Please try again in a few minutes",
            key: "4",
            open: true,
          })
        );
      });
  };
};



export const getMetaData = (email, successCallback, errorCallback) => {
  
  let state = store.getState();

  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.organizationPath}getusermetadata`,
          {
            params: {
              email: email,
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: SET_META_DATA,
          payload: {
            data: resp.data.data,
          },
        });

        successCallback()
      })
      .catch((error) => {

        errorCallback(error);
        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};


export const getFilteredMetaData = (email, filters) => {
  
  let state = store.getState();

  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.organizationPath}getfilteredusermetadata`,
          {
            params: {
              email: email,
              filters: filters
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: SET_FILTERED_META_DATA,
          payload: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {

        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};


export const getOrganizationOnboardingData = (email, successCallback, errorCallback) => {

  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.organizationPath}getorganizationonboardingdata`,
          {
            params: {
              email: email,
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: GET_ONBOARDING_TOUR_DATA,
          payload: {
            data: resp.data.data,
          },
        });
        successCallback()

      })
      .catch((error) => {

        errorCallback(error)
        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};


export const addDepartment = ( data, successCallback, errorCallback) => {
  return async (dispatch) => {
    const body = {
      department: data.department,
      email: data.email,
      user_entered: true
    };

    return axios
      .post(`${appConfig.organizationPath}adddepartment`, body)
      .then((resp) => {
        const { success, statusMessage } = resp && resp.data;
        if (!success) {
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: statusMessage,
              key: "4",
              open: true,
            })
          );
          return;
        }

        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Department Created Successfully",
            key: "1",
            open: true,
          })
        );

        dispatch({
          type: SET_META_DATA,
          payload: {
            data: resp?.data?.meta_data,
          },
        });

        successCallback(resp?.data?.department);
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.message,
            key: "4",
            open: true,
          })
        );

        errorCallback()

      });
  };
};


export const getOrganizationAdmins = (data, successCallback, errorCallback) => {

  return async (dispatch) => {
    return axios
      .get(
        `${appConfig.organizationPath}getorganizationadmins`,
          {
            params: {
              email: data.email
            }
          }
        )
      .then((resp) => {

        dispatch({
          type: GET_ORGANIZATION_ADMINS,
          payload: {
            data: resp.data.data,
          },
        });
        successCallback()

      })
      .catch((error) => {

        errorCallback(error)
        // const { message } =
        //   error && error.response.data && error.response.data.error;
        // dispatch(
        //   setSnackBar({
        //     snackType: "error",
        //     snackbarMessage: message,
        //     key: "4",
        //     open: true,
        //   })
        // );
      });
  };
};