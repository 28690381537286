import React from "react";
import {
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
} from "@material-ui/core/";

const CustomDialog = ({ open, handleClose, Title, Content, DialogAction }) => {
  const buttoms =
    DialogAction &&
    DialogAction.map((action, index) => {
      
      return (
        <Button
          key={index}
          onClick={action.Action}
          style={{
            color: action.ActionName === "Yes" ? "#FFFFFF" : "#000000",
            padding: 5,
            backgroundColor:
              action.ActionName === "Yes" ? "#533aed" : "#DCDCDC",
            borderRadius: 25,
            width: 200,
            boxShadow: "none",
          }}
          variant="contained"
        >
          {action.ActionName}
        </Button>
      );
    });

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{Title}</DialogTitle>
        <DialogContent>
          <DialogContentText>{Content}</DialogContentText>
        </DialogContent>
        <DialogActions
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {buttoms}
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CustomDialog;
