import { GETPROVINCES, GETCITIES, SET_FILTER_LOCATIONS} from "../types/province.types";

const initialState = {
    isLoading: true,
    provinces: [],
    cities: [],
    filter_locations: []
}

export const ProvincesReducer = (state=initialState,action) => {

    switch(action.type){
        case GETPROVINCES:
            return {
                ...state,
                provinces: action.payload.data,
                isLoading: false
            }
        
        case GETCITIES:
            return {
                ...state,
                cities: action.payload.data,
                isLoading: false
            }
        
        case SET_FILTER_LOCATIONS:
            return {
                ...state,
                filter_locations: action.payload.data
            }
        default:
            return state;
    }
}