import React, { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import useStyles from "./users.styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CustomTable from "../../components/table/table.component";
import { EditAction, ShowAction } from "../../components/table/tableActions";
import AddButton from "../../components/buttons/add-button/addButton.component";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  getPermission,
} from "../../redux/actions/authentication.action";
import Button from "@mui/material/Button";
import { getProvinces } from "../../redux/actions/provinces.action";
import { getOrganization } from "../../redux/actions/organization.action";
import { ArrowDownward, AddCircle, PersonAdd } from "@mui/icons-material";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import UserForm from "../../components/user/user-form/userForm.component";
import AdminUserForm from "../../components/user/user-form/adminUserForm.component";
import Slide from "@mui/material/Slide";
import usericon from "../../assets/images/usericon.png";
import lockicon from "../../assets/images/lockicon.png";
import contacticon from "../../assets/images/contacticon.png";
import lockicon2 from "../../assets/images/lockicon2.png";
import statusicon from "../../assets/images/statusicon.png";
import userplus from "../../assets/images/userplus.png";
import impersonate from "../../assets/images/impersonate.png";
import actionicon from "../../assets/images/actionicon.png";
import { setImpersonating } from "../../redux/actions/authentication.action";
import Card from "@mui/material/Card";
import Paper from "@mui/material/Paper";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  ResponsiveContainer,
  Label,
  BarChart,
  Bar,
  XAxis,
  AreaChart,
  Area,
  YAxis,
  CartesianGrid,
  Tooltip,
} from "recharts";
import { getReturnToWorkData } from "../../redux/actions/analytics.action";
import moment from "moment";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled, useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import SearchIcon from "@mui/icons-material/Search";
import empathologo from "../../assets/images/empathologo.png";
import empathopurplelogo from "../../assets/images/empathopurplelogo.png";
import InputAdornment from "@mui/material/InputAdornment";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import IconButton from "@mui/material/IconButton";
import { ExportJsonCsv } from "react-export-json-csv";
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";

import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const data01 = [{ name: "Group A", value: 400 }];
const data02 = [{ name: "A1", value: 90 }];

const monthData = [
  { name: "Apr", value: 20 },
  { name: "May", value: 40 },
  { name: "Jun", value: 10 },
  { name: "Jul", value: 25 },
  { name: "Aug", value: 50 },
  { name: "Sep", value: 20 },
  { name: "Oct", value: 10 },
  { name: "Nov", value: 40 },
  { name: "Dec", value: 20 },
];

const drawerWidth = 150;
const AGE_COLORS = ["#58D8F2", "#EEE"];

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  }),
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: window.innerWidth,
  height: 100,
  backgroundColor: "#FFF",
  zIndex: theme.zIndex.drawer - 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const StyledTextField = styled(TextField)({
  "label + &": {
    color: "pink",
  },
  "& label.Mui-focused": {
    color: "#000",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#000",
  },
  "& .MuiInputBase-input": {
    height: "100%",
    width: 300,
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      color: "#000",
      borderRadius: 5,
      borderWidth: 1,
      borderColor: "#AAA",
    },
    "&:hover fieldset": {
      borderColor: "#C9C9C9",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#533aed",
    },
  },
});

const ReturnToWork = ({ props }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const { organizations } = useSelector((state) => state.Organization);
  const { returntoworkdata } = useSelector((state) => state.AnalyticsReducer);
  const {
    user,
    permissions,
    impersonating,
    impersonatedorganization,
    isLoading,
    userlist,
  } = useSelector((state) => state.Authentication);
  const [statusFilterOn, setStatusFilterOn] = useState(false);

  const [filteredDetails, setFilteredDetails] = useState(null);
  const returnOrganization = (organization_id) => {
    let organizationobject = organizations.filter(
      (organization) => organization.id == organization_id
    );

    if (organizationobject.length > 0) {
      return organizationobject[0];
    }
  };
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    if (user?.email) {
      dispatch(getReturnToWorkData(user?.email));
    }
  }, [dispatch, user]);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    setFilteredDetails(returntoworkdata?.details);
  }, [returntoworkdata]);

  const returnResultStatus = (result) => {
    if (result === "FAIL") {
      return (
        <div
          style={{ color: "#FF6767", padding: "0px 25px", borderRadius: 50 }}
        >
          failed
        </div>
      );
    } else if (result === "PASS") {
      return (
        <div
          style={{ color: "#58D8F2", padding: "0px 25px", borderRadius: 50 }}
        >
          passed
        </div>
      );
    }
  };

  const searchUser = (event) => {

    if (event.target.value.length > 0) {
      let templist = [];

      templist = returntoworkdata?.details.filter(
        (s) =>
          s.first_name
            .toLowerCase()
            .includes(event.target.value.toLowerCase()) ||
          s.last_name.toLowerCase().includes(event.target.value.toLowerCase())
      );

      setFilteredDetails(templist);
    } else {
      setFilteredDetails(returntoworkdata?.details);
    }
  };

  const filterByStatus = (status) => {
    if (status === "FAIL" || status === "PASS") {
      let templist = [];

      templist = returntoworkdata?.details.filter(
        (s) => s.result_status === status
      );
      
      setStatusFilterOn(true);
      setFilteredDetails(templist);
    } else {
      setFilteredDetails(returntoworkdata?.details);
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "User Name",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>{`${row.first_name} ${row.last_name}`}</div>;
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: true,
      minWidth: 200,
      flex: 1, 
      renderCell: ({ row }) => {
        return returnResultStatus(row.result_status);
      },
    },
    {
      field: "valid_until",
      headerName: "Screening Date",
      minWidth: 200,
      flex: 1,
      renderCell: ({ row }) => {
        return <div>{moment(row.valid_until).format("MMMM DD, YYYY")}</div>;
      },
    },
    {
      field: "username",
      headerName: "Contact",
      minWidth: 200,
      flex: 1,
    },
    {
      field: "Actions",
      headerName: "",
      sortable: false,
      minWidth: 200,
      flex:1,
      disableClickEventBubbling: true,
      renderCell: ({ row }) => {
        return (
          <div>
            <IconButton aria-label="edit" sx={{ backgroundColor: "#EEE" }}>
              <EditIcon style={{ color: "#533AED" }} />
            </IconButton>
          </div>
        );
      },
    },
  ];

  const headers = [
    { key: "first_name", name: "First Name" },
    { key: "last_name", name: "Last Name" },
    { key: "username", name: "Contact" },
    { key: "result_status", name: "Status" },
    { key: "valid_until", name: "Screening Date" },
  ];

  const sectionStyle = {
    background: "#58D8F2",
    color: "white",
    border: "none",
    fontSize: 15,
    fontWeight: 600,
    padding: "10px 15px",
    borderRadius: 25,
    marginBottom:20
  };

  let date = moment().format("YYYYMMDD");

  const accesstoken = user?.accesstoken || user?.userdata.access_token;

  return (
    <Grid container style={{ paddingTop: 0 }}>

      {isDesktopOrLaptop && <p>
        <Grid container lg={12} xl={12} md={12} xs={12} direction="row">
        <AppBar position="absolute" open={true}>
          <Toolbar
            sx={{
              background: "#FFF",
              height: 150,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            {/* <div style={{ height: '50%', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'1.2em' }}>
                  Hello, <span style={{ fontWeight:'bold' }}>{user?.userdata?.first_name}</span>
              </div> */}

            <div
              style={{
                height: "50%",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                
              }}
            >
              <StyledTextField
                variant="outlined"
                placeholder="Search Users"
                sx={{
                  marginLeft: 20,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon sx={{ color: "#533AED" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={searchUser}
              />

              <div style={{ marginLeft: 20 }}>
                <div style={{ color: "#000", fontWeight: "bold" }}>
                  Show Status
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <IconButton onClick={() => filterByStatus("FAIL")}>
                    <CheckCircleIcon
                      sx={{ color: "#FF6767", fontSize: "1.4em" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => filterByStatus("PASS")}>
                    <CheckCircleIcon
                      sx={{ color: "#58D8F2", fontSize: "1.4em" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => filterByStatus("NONE")}>
                    <CheckCircleIcon
                      sx={{ color: "#BBB", fontSize: "1.4em" }}
                    />
                  </IconButton>
                </div>
              </div>
              {/* <Button
                    type="contained"
                    sx={{
                        marginLeft: '10px',
                        backgroundColor:'#EEE',
                        color:'#000',
                        fontSize:'0.8em',
                        height:'100%'
                        // padding:'5px 10px',
                    }}
                    >
                        Filter by Admins
                    </Button> */}
            </div>

            {impersonating === false ? (
              <div
                style={{
                  height: "50%",
                  display: "flex",
                  flexDirection: "row",
                  alignSelf: "center",
                }}
              >
                <img
                  src={empathopurplelogo}
                  style={{ width: "auto", height: "100%" }}
                />
              </div>
            ) : (
              <div
                style={{
                  fontWeight: "bold",
                  borderRadius: 10,
                  paddingRight: 20,
                  paddingLeft: 20,
                  height: "50%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  color: "#FFF",
                  backgroundColor: "#2ECDED",
                }}
              >
                Impersonating{" "}
                {returnOrganization(impersonatedorganization)?.name}
              </div>
            )}
          </Toolbar>
        </AppBar>
      </Grid>
        </p>}

        {isTabletOrMobile && <p>
          
        <AppBar position="absolute" open={true}
        style={{width: '100%'}}
        >
          <Toolbar
            sx={{
              paddingTop: 2,
              width: '100%',
              display:'flex',
              flexDirection:'column', 
              height: 100,
            }}
          >
           

            <div style={{ display:'flex', flexDirection:'row', height: 100 }}>
             <IconButton
            size="large"
            color="inherit"
            aria-label="menu"
            sx={{color: '#533AED', marginBottom: 5, marginLeft:18}}
            onClick={handleDrawerOpen}
          >
            
            <MenuIcon />
          </IconButton>
              <StyledTextField
                variant="outlined"
                placeholder="Search Users"
                sx={{
                  width: '25%',
                  marginRight: 2
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment>
                      <SearchIcon sx={{ color: "#533AED" }} />
                    </InputAdornment>
                  ),
                }}
                onChange={searchUser}
              />

              <div style={{ marginLeft: 0 }}>
                <div style={{ color: "#000", fontWeight: "bold" }}>
                  Show Status
                </div>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <IconButton onClick={() => filterByStatus("FAIL")}>
                    <CheckCircleIcon
                      sx={{ color: "#FF6767", fontSize: "1.4em" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => filterByStatus("PASS")}>
                    <CheckCircleIcon
                      sx={{ color: "#58D8F2", fontSize: "1.4em" }}
                    />
                  </IconButton>
                  <IconButton onClick={() => filterByStatus("NONE")}>
                    <CheckCircleIcon
                      sx={{ color: "#BBB", fontSize: "1.4em" }}
                    />
                  </IconButton>
                </div>
              </div>
              
            </div>

            
          </Toolbar>
        </AppBar>
        <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
            backgroundColor: '#533AED',

          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <DrawerHeader>
          <img src={empathologo} style={{ width: '20%', height: 'auto',  }} />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/wellbeing")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={wellbeingicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/organizations")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={organizationicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/covidsurvey")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={covidsurveyicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/users")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={usersicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>
              <ListItem button onClick={() => history.push("/returntowork")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
                <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                  <img src ={returntoworkicon} style={{ width:'auto', height:20 }}/>
                </ListItemIcon>
              </ListItem>

        </List>
        <Divider />
        <List component="nav" style={{ display:'flex', justifyContent:'space-between', flexDirection:'column' }}>
        <ListItem button onClick={() => history.push("/faq")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={faqicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button onClick={() => history.push("/settings")} style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={settingsicon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
            </ListItem>

            <ListItem button  onClick={() => {
              dispatch(
                setLogout(user?.email, accesstoken, async () => {
                  
                  dispatch(resetAuthState());  
                  
                  localStorage.removeItem("currentUser");
                  localStorage.removeItem("persist:Authentication");
                  localStorage.clear();

                  await persistor.flush();
                  await persistor.purge();

                  history.push({ pathname: "/" });
          

                })
              );
              }}
              style={{ display:'flex', alignItems:'center', justifyContent:'center', margin:'10px 0px' }}>
              <ListItemIcon style={{ color:'#FFF', display:'flex', alignItems:'center', justifyContent:'center' }} >
                <img src ={logouticon} style={{ width:'auto', height:20 }}/>
              </ListItemIcon>
              {/* <ListItemText primary={"Home"} /> */}
            </ListItem>
        </List>
      </Drawer>
      
      </p>}

      {isDesktopOrLaptop && <p>
        <Grid item className={classes.title} lg={12} xl={12} md={12} xs={12} style={{paddingTop: 100}}>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: 'center',
            alignItems: "center",
            paddinTop: 50,
          }}
        >
          <div style={{ letterSpacing: 1.5, fontSize: 50, fontFamily: 'Sofia-Pro', fontWeight:'bold', paddingRight: 1000,  }}>
            
          </div>
          <ExportJsonCsv
            style={sectionStyle}
            headers={headers}
            items={returntoworkdata.details}
            fileTitle={"returntoworkreport" + "-" + date}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: 5}} >
              <ArrowDownward style={{ paddingRight: 8}} />
              Download Results
            </div>
          </ExportJsonCsv>
        </div>
      </Grid>
        </p>}

        {isTabletOrMobile && <p>
          
          <Grid item className={classes.title} lg={12} xl={12} md={12} xs={12}
          
          >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            
          }}
        >
          <div style={{ letterSpacing: 1.5, paddingTop: 10, fontSize: 40, marginBottom: 10, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
            Return To Work
          </div>
          <ExportJsonCsv
            style={sectionStyle}
            headers={headers}
            items={returntoworkdata.details}
            fileTitle={"returntoworkreport" + "-" + date}
          >
            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between",}} >
              <ArrowDownward style={{ paddingRight: 8}} />
              Download Results
            </div>
          </ExportJsonCsv>
        </div>
      </Grid>
          </p>}
      
      {isDesktopOrLaptop && <p>
        
        <Grid container lg={12} xl={12} md={12} xs={12} direction="row">
        <Grid container lg={3} xl={3} md={3} xs={8} spacing={1}>
          <Grid item xs={12}>
            <Paper
              sx={{
                minHeight: 150,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1, fontSize: "4em", fontWeight: "bold" }}>
                  {returntoworkdata?.summary?.total_users || 0}
                </div>
                <div style={{ color: "#999" }}>
                  Screened {moment().format("MMMM DD, YYYY")}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper
              sx={{
                minHeight: 150,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1, fontSize: "4em", fontWeight: "bold" }}>
                  {returntoworkdata?.summary?.total_users_pass || 0}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: "#999" }}>Passes</div>
                  <div
                    style={{
                      color: "#1CDC93",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDropUpIcon />
                    Up{" "}
                    {`${returntoworkdata?.summary?.percent_users_pass || 0}%`}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{
                minHeight: 150,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
                backgroundColor: "#FF6767",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontSize: "4em",
                    fontWeight: "bold",
                    color: "#FFF",
                  }}
                >
                  {returntoworkdata?.summary?.total_users_fail || 0}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: "#FFF" }}>Failed</div>
                  <div
                    style={{
                      color: "#FFF",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDropUpIcon />
                    Up{" "}
                    {`${returntoworkdata?.summary?.percent_users_fail || 0}%`}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container lg={9} xl={9} md={9} xs={8}>
          <Grid item xs={12} style={{ paddingLeft: 20 }}>
            <Paper
              sx={{
                height: "100%",
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "1.8em", fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
                    SaRS COVID Overview
                  </div>
                  <div>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Year
                    </Button>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Gender
                    </Button>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Age
                    </Button>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    paddingTop: 20,
                    
                  }}
                >
                  <ResponsiveContainer width="100%" height="100%">
                    {/* <PieChart width={'auto'} height={'auto'}>
                                        

                                            <text x={'50%'} y={'52%'} dy={8} textAnchor="middle" fill={true} style={{ fontSize:40, fontWeight:'bold' }}>
                                                {returntoworkdata?.summary?.percent_users_pass} %
                                            </text>
                                            <Pie data={[
                                                    { name: 'A1', value: parseInt(returntoworkdata?.summary?.percent_users_fail) },
                                                ]} 
                                                dataKey="value" 
                                                cx="50%" cy="50%" 
                                                innerRadius={70} 
                                                outerRadius={90} 
                                                startAngle={180}
                                                endAngle={-180}
                                                fill="#EEE"/> 
                                            <Pie data={[
                                                    { name: 'A2', value: parseInt(returntoworkdata?.summary?.percent_users_pass) },
                                                ]} 
                                                dataKey="value" 
                                                cx="50%" cy="50%" 
                                                innerRadius={70} 
                                                outerRadius={90} 
                                                startAngle={180}
                                                endAngle={-90}
                                                fill="#58D8F2"/>
                                            <Label 
                                                    value="6" position="centerBottom"  className='label-top' fontSize='27px'
                                                    />
                                                    <Label 
                                                    value="tasks left" position="centerTop" className='label'
                                                    />
                                            
                                        </PieChart> */}
                    <PieChart width={"auto"} height={"auto"}>
                      <text
                        x={"50%"}
                        y={"48%"}
                        dy={8}
                        textAnchor="middle"
                        fill={'#000'}
                        style={{ fontSize: 40, fontWeight: "bold" }}
                      >
                        {returntoworkdata?.summary?.percent_users_pass} %
                      </text>
                      <text
                        x={"50%"}
                        y={"58%"}
                        dy={8}
                        textAnchor="middle"
                        fill={'#AAA'}
                        style={{ fontSize: 25, fontWeight: "bold" }}
                      >
                        Passed
                      </text>
                      <Pie
                        data={[
                          {
                            name: "Pass",
                            value: parseInt(returntoworkdata?.summary?.percent_users_pass),
                          },
                          {
                            name: "Fail",
                            value: parseInt(returntoworkdata?.summary?.percent_users_fail == 0 ? 100 : returntoworkdata?.summary?.percent_users_fail),
                          },
                        ]}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={80}
                        outerRadius={100}
                        fill="#E767FF"
                        paddingAngle={1}
                        startAngle={0}
                        endAngle={360}
                        dataKey="value"
                      >
                        {[
                          {
                            name: "Pass",
                            value: parseInt( returntoworkdata?.summary?.percent_users_pass ),
                          },
                          {
                            name: "Fail",
                            value: parseInt(returntoworkdata?.summary?.percent_users_fail),
                          },
                        ].map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={AGE_COLORS[index % AGE_COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>

                  <ResponsiveContainer width="100%" height="100%">
                    <AreaChart
                      width={"auto"}
                      height={"auto"}
                      data={monthData}
                      margin={{
                        top: 10,
                        right: 30,
                        left: -60,
                        bottom: 0,
                        
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#8884d8"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
        </p>}

        {isTabletOrMobile && <p>
          <Grid container lg={12} xl={12} md={12} xs={12} direction="row"
          style={{marginLeft: -15}}>
        <Grid container lg={12} xl={12} md={12} xs={12} spacing={1}>
          <Grid item xs={6}>
            <Paper
              sx={{
                minHeight: 180,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1, fontSize: "4em", fontWeight: "bold" }}>
                  {returntoworkdata?.summary?.total_users || 0}
                </div>
                <div style={{ color: "#999" }}>
                  Screened {moment().format("MMMM DD, YYYY")}
                </div>
              </div>
            </Paper>
          </Grid>
          <Grid item xs={6}>
            <Paper
              sx={{
                minHeight: 180,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div style={{ flex: 1, fontSize: "4em", fontWeight: "bold" }}>
                  {returntoworkdata?.summary?.total_users_pass || 0}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: "#999" }}>Passes</div>
                  <div
                    style={{
                      color: "#1CDC93",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDropUpIcon />
                    Up{" "}
                    {`${returntoworkdata?.summary?.percent_users_pass || 0}%`}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper
              sx={{
                minHeight: 150,
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
                backgroundColor: "#FF6767",
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    flex: 1,
                    fontSize: "4em",
                    fontWeight: "bold",
                    color: "#FFF",
                  }}
                >
                  {returntoworkdata?.summary?.total_users_fail || 0}
                </div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: "#FFF" }}>Failed</div>
                  <div
                    style={{
                      color: "#FFF",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <ArrowDropUpIcon />
                    Up{" "}
                    {`${returntoworkdata?.summary?.percent_users_fail || 0}%`}
                  </div>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>

        <Grid container lg={12} xl={12} md={12} xs={12}>
          <Grid item xs={12} style={{ paddingLeft: 0 }}>
            <Paper
              sx={{
                height: 700,
                width: "100%",
                borderRadius: 8,
                display: "flex",
                flex: 1,
                overflow: "hidden",
                marginTop: 5,
              }}
            >
              <div
                style={{
                  padding: 20,
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ fontSize: "1.8em", fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
                    SaRS COVID Overview
                  </div>
                  <div>
                    <div style={{marginTop: 2}}>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                        paddingTop: 5,
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Year
                    </Button>
                    </div>
                    <div style={{marginTop: 2}}>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Gender
                    </Button>
                    </div>
                      <div style={{marginTop: 2}}>
                    <Button
                      style={{
                        margin: "0px 10px",
                        backgroundColor: "#533AED",
                        borderRadius: 20,
                        color: "#FFF",
                        fontSize: 10,
                        padding: "10px 20px",
                      }}
                      endIcon={<KeyboardArrowDownIcon />}
                    >
                      Age
                    </Button>
                    </div>
                  </div>
                </div>

                <div
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "column",
                    paddingTop: 20,
                  }}
                >
                  <ResponsiveContainer width="100%" height="60%">
                    
                    <PieChart width={"auto"} height={"auto"}>
                      <text
                        x={"50%"}
                        y={"48%"}
                        dy={8}
                        textAnchor="middle"
                        fill={'#000'}
                        style={{ fontSize: 40, fontFamily: 'Sofia-Pro', fontWeight: "bold" }}
                      >
                        {returntoworkdata?.summary?.percent_users_pass} %
                      </text>
                      <text
                        x={"50%"}
                        y={"58%"}
                        dy={8}
                        textAnchor="middle"
                        fill={'#AAA'}
                        style={{ fontSize: 20, fontWeight: "500" }}
                      >
                        Passed
                      </text>
                      <Pie
                        data={[
                          {
                            name: "Pass",
                            value: parseInt(returntoworkdata?.summary?.percent_users_pass),
                          },
                          {
                            name: "Fail",
                            value: parseInt(returntoworkdata?.summary?.percent_users_fail == 0 ? 100 : returntoworkdata?.summary?.percent_users_fail),
                          },
                        ]}
                        cx={"50%"}
                        cy={"50%"}
                        innerRadius={80}
                        outerRadius={100}
                        fill="#E767FF"
                        paddingAngle={1}
                        startAngle={0}
                        endAngle={360}
                        dataKey="value"
                      >
                        {[
                          {
                            name: "Pass",
                            value: parseInt( returntoworkdata?.summary?.percent_users_pass ),
                          },
                          {
                            name: "Fail",
                            value: parseInt(returntoworkdata?.summary?.percent_users_fail),
                          },
                        ].map((entry, index) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={AGE_COLORS[index % AGE_COLORS.length]}
                          />
                        ))}
                      </Pie>
                    </PieChart>
                  </ResponsiveContainer>

                  <ResponsiveContainer width="100%" height="60%">
                    <AreaChart
                      width={"auto"}
                      height={"auto"}
                      data={monthData}
                      margin={{
                        top: 10,
                        right: 10,
                        left: -30,
                        bottom: 10,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="name" />
                      <YAxis />
                      <Tooltip />
                      <Area
                        type="monotone"
                        dataKey="value"
                        stroke="#8884d8"
                        fill="#8884d8"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
          </p>}
      
{isDesktopOrLaptop && <p>
  
  <Grid container
      style={{marginTop: 20, width: window.innerWidth}}
      >
        <div
          style={{
            marginTop: 20,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "85%",
          }}
        >
          <CustomTable
            columns={columns}
            rows={filteredDetails || []}
            rowId={"id"}
            loading={isLoading}
          />
        </div>
      </Grid>
  </p>}

{isTabletOrMobile && <p>
  <Grid item lg={12} xl={12} md={12} xs={12}
      style={{width: window.innerWidth}}
      >
        <div
          style={{
            marginTop: 10,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <CustomTable
            columns={columns}
            rows={filteredDetails || []}
            rowId={"id"}
            loading={isLoading}
          />
        </div>
      </Grid>
  </p>}
      
    </Grid>
  );
};

export default ReturnToWork;
