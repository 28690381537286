import { axiosService as axios } from "../../helpers/utils-authentication";
import { GETCOVIDSCREENING } from "../types/covidscreening.types";
import { appConfig } from "../../config/appconfig";
import { store } from "../store/rootStore";

export const getCovidScreening = (email) => {
  let state = store.getState();
  return async (dispatch) => {
    return axios
      .get(`${appConfig.covidScreeningPath}getsurveys`, {
        headers: {
          Authorization:
            "Bearer " + state.Authentication.user.userdata.access_token,
        },
        params: {
          email: email,
        },
      })
      .then((resp) => {
        dispatch({
          type: GETCOVIDSCREENING,
          payload: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {

      });
  };
};
