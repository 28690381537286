import React, { Fragment } from "react";
import useStyles from "./clock.styles";

const Clock = ({ days, hours, minutes, seconds }) => {
  const classes = useStyles();

    return (
      <Fragment>
        <section className="timer-container"></section>
        <section className="timer">
          <div className={classes.clock}>
            <section>
              <p>{days}</p>
              <small>Days</small>
            </section>
            <span>:</span>
            <section>
              <p>{hours}</p>
              <small>Hours</small>
            </section>{" "}
            <span>:</span>
            <section>
              <p>{minutes}</p>
              <small>Minutes</small>
            </section>{" "}
            <span>:</span>
            <section>
              <p>{seconds}</p>
              <small>Seconds</small>
            </section>
          </div>
        </section>
      </Fragment>
    );
};

export default Clock;
