import { makeStyles } from "@material-ui/core";

const drawerWitdth = 275;

const useDrawerStyles = makeStyles((theme) => ({
    drawer: {
        width: drawerWitdth,
        flexShrink: 0
    },
    drawerPaper: {
        width: drawerWitdth
    },
    toolbar: theme.mixins.toolbar
}))

export default useDrawerStyles;