import React, { useState, useEffect } from "react";
import useStyles from "./formDesactiveAccount.style";
import TextField from '@mui/material/TextField';
import { useSelector, useDispatch } from "react-redux";
import { createPassword } from "../../../redux/actions/authentication.action";
import { setSnackBar } from "../../../redux/actions/snackbar.action";
import moment from 'moment';
import { useHistory } from "react-router-dom";
import isEmail from 'validator/lib/isEmail';
import { alpha, styled } from '@mui/material/styles';
import empathowhitelogo from '../../../assets/images/empathowhitelogo.png';
import empathoactionableresults from '../../../assets/images/actionableresults.png'
import CssBaseline from '@mui/material/CssBaseline';
import Grid from '@mui/material/Grid';
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import Button from '@mui/material/Button';
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../../redux/actions/loading.action";
import CircularProgress from '@mui/material/CircularProgress';
import { getUsers } from "../../../redux/actions/authentication.action";
import Autocomplete from '@mui/material/Autocomplete';
import {setLogout, deactivateAccount, resetAuthState} from '../../../redux/actions/authentication.action';
import { persistor }  from "../../../redux/store/rootStore";

const FormDesactiveAccount = ({ UserData, closeUserDialog, edit }) => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, impersonating, impersonatedorganization, permissions } = useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading]= useState(false);
  const [desactiveAccount, setDesactiveAccount] = useState(false);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);

  const accesstoken = user?.accesstoken || user?.userdata.access_token;

  useEffect(() => {

    if (edit){
      
      setValue("email", UserData.userdata.user_email);
      setValue("currentpassword", "");
      setValue("password", "");
      setValue("confirmpassword", "");
    }

  },[user])

  const submitChangePass = (data) => {

    }

    const deleteAccount = () => {
      let data = {
        email: user.email,
      };
      closeUserDialog();
      dispatch(setShowLoading(true));
      dispatch(
        deactivateAccount(
          data,
          () => {
            
            dispatch(setShowLoading(false));
            dispatch(
                setLogout(user?.email, accesstoken, async () => {
            
                  resetAuthState();  
                  
                  localStorage.removeItem("currentUser");
                  localStorage.removeItem("persist:Authentication");
                  localStorage.clear();

                  await persistor.flush();
                  await persistor.purge();

                  history.push({ pathname: "/" });
          
                  
                })
              );
          },
          error => {
            dispatch(setShowLoading(false));
            
          },
        ),
      );
    };

  const LoginTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInputBase-input':{
      height:15,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1.6,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });

  return (

      <form className={classes.form} onSubmit={handleSubmit(submitChangePass)}>

          <div style={{ margin:10, width: '100%', flexDirection: 'row' }}>
                <span>Are you sure you want to deactivate your account?<br/>If you deactivate your account you will permanently lose access to empatho app and the admin portal
                </span>
          </div>

          <br/>
      
          <div style={{ display:'flex', justifyContent:'center', alignItems:'center', marginTop:20 }}>
            <Button 
              type="submit"
              style={{ fontSize:12, backgroundColor:'#FF6767', color:'#FFF', margin:'5px 5px', borderRadius: 25, width: 250 }}
              variant="text"  
              disabled={loading}
              disableElevation
              onClick={() => deleteAccount()}
              >
              <div style={{ padding:"10px 10px", fontSize: 14, letterSpacing: 1.5}}>
                { edit ? 'DEACTIVATE ACCOUNT' : 'DEACTIVATE ACCOUNT' }
              </div>
              {
                loading ?
                  <div style={{ justifyContent:'center', alignItems:'center' }}>
                    <CircularProgress 
                      size={20}
                      sx= {{ color:"#FFF" }}
                    />
                  </div>
                :
                null
              }

            </Button>
          </div>
            <Button style={{color: 'black', fontSize: 14, letterSpacing: 1.5, marginTop: 20}} onClick={() => closeUserDialog() }>NEVERMIND, KEEP ACCOUNT</Button>
      </form>    

  );
};

export default FormDesactiveAccount;
