import { makeStyles } from "@material-ui/core";

const useDrawerItemStyles = makeStyles(({
    container: {
        height: "100%",
        justifyContent:'center',
        paddingTop:50,
        display:'flex' 
    },
    header: {
        fontSize: "24px",
        height: "70px",
        fontWeight: "bold",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "5px 15px"
    }
}))

export default useDrawerItemStyles;