import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles( theme => ({
  container: {
    height: '100vh',
    display:'flex',
    backgroundColor: theme.palette.primary.main,
    flexDirection:'column'
  },
  card: {
    width:'500px',
    minHeight: '200px',
    padding: "20px 40px"
  },
  container_login: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  form: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    fontWeight: "600",
    fontSize: 24,
  },
  box: {
    margin: "10px 0px",
  },  
  text_field: {
    width: "100%",
  },
  text: {
    fontWeight: 600,
    fontSize: 16,
    margin: "15px 0px"
  },
  login_box: {
    backgroundColor: "#eeeeee",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  detail_box: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  btn_styles: {
    textDecoration: "none",
  },

  inputlabel: {
    color:'#FFF',
    marginBottom:10
  }

}));

export default useStyles;
