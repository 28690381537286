import { Grid, TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useForm, Controller } from "react-hook-form";
import useStyles from "../organizations.styles";
import Button from "@material-ui/core/Button";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import { getProvinces } from "../../../redux/actions/provinces.action";

const FormOrganization = ({ data, onCreate, onUpdate, edit }) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const { provinces } = useSelector((state) => state.Provinces);
  const { organizations } = useSelector((state) => state.Organization);

  const returnLocationObject = (locationid) => {
    let location = provinces.filter(location => location.pk === locationid );
    
    if (location.length > 0){
      return location[0]
    }
  }

  const { handleSubmit, control, getValues } = useForm({ 
    defaultValues: {
      province: { pk: returnLocationObject(data?.location)?.pk , locationName: returnLocationObject(data?.location)?.locationName },
    } 
  });

  const { user } = useSelector((state) => state.Authentication);

  useEffect(() => {
    // dispatch(getProvinces(user?.email));
  }, [dispatch, user ]);

  const onSubmit = (_data) => {
  
      !!data ? onUpdate(_data) : onCreate(_data)
    
  } ;

  const changeValue = (data, cb) => {
    
    const pk = data?.pk || '';
    const locationName = data?.locationName || '';
  
    const _data = {
      pk,
      locationName,
    };
  
    cb(_data);
  };

  return (
    <Grid container direction="row" wrap="wrap" spacing={2}>
      <form
        style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            name="organizationname"
            control={control}
            defaultValue={data ? data.organizationName : ""}
            rules={{ 
              required: "Organization Name required",
              validate: (value) => {
                
                var match = organizations.filter(org => org?.organizationName.toLowerCase().trim() == getValues()?.organizationname.toLowerCase().trim() )
                if ( match.length === 0 || edit === true ) {
                  return true
                } else {
                  return "Organization Already Exists"
                }
              }
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Organization Name"
                variant="outlined"
                value={value}
                className={classes.input}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            rules={{ 
              required: "Province is Required",
              validate: (value) => {
                
                if (value.pk === "" || value.locationName === "" || value.pk === undefined || value.locationName === undefined){
                    return "Please Select a Province"
                }
              }
             
            }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <Autocomplete
                options={provinces}
                defaultValue={data ? returnLocationObject(data.location) : ""}
                // value={data?.province || value}
                onChange={(_, data) => {
                    changeValue(data, (_data) => { 
                      
                      onChange(_data) 
                    });
                  }
                }
                getOptionLabel={(option) => option.locationName || '' }
                getOptionSelected={(option, value) => option.pk === value.pk}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Province"
                    placeholder="Select a Province"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    // value={value}
                    variant="outlined"
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
            name="province"
            control={control}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            name="contactemail"
            control={control}
            defaultValue={data ? data.data : ""}
            rules={{ required: "Email is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Email"
                variant="outlined"
                className={classes.input}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            name="contactphone"
            control={control}
            defaultValue={data ? data.contactPhone : ""}
            rules={{ required: "Phone is Required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Phone"
                variant="outlined"
                className={classes.input}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.formBox}>
          <Controller
            name="contactperson"
            control={control}
            defaultValue={data ? data.contactPerson : ""}
            rules={{ required: "Person is required" }}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <TextField
                label="Person"
                variant="outlined"
                className={classes.input}
                value={value}
                onChange={onChange}
                error={!!error}
                helperText={error ? error.message : null}
              />
            )}
          />
        </Grid>
        <Grid item lg={12} xs={12} md={12} className={classes.saveBtn}>
          <Button color="primary" type="submit">
            Save Changes
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};

export default FormOrganization;
