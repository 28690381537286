import React from "react";
import { Grid } from "@material-ui/core";

const PieChart = (props) => {

  const radius = props.radius;
  const circleCircumference = 2 * Math.PI * radius;

  const score = props.data;
  const numScore = Number(score) || 0;
  const total = numScore;

  const scorePercentage = (numScore / total) * props.data;
  const scoreStrokeDashoffset =
    circleCircumference - (circleCircumference * scorePercentage) / 100;

  const scoreAngle = (numScore / total) * 270;

  return (
    <Grid style={{ justifyContent: "center", alignItems: "center" }}>
      <div style={{ display:'flex', alignItems:'center', justifyContent:'center' }}>
          <svg height="180" width="180" viewBox="0 0 180 180">
            <g rotation={-90} originX="90" originY="90">
              {total === 0 ? (
                <circle
                  cx="50%"
                  cy="50%"
                  r={radius}
                  stroke="#EBEAED"
                  fill="transparent"
                  strokeWidth={props.strokeWidth}
                />
              ) : (
                <>
                  {props.showGreyCircle === true ? (
                    <circle
                      cx="50%"
                      cy="50%"
                      r={radius}
                      stroke="#EBEAED"
                      fill="transparent"
                      strokeWidth={props.strokeWidth}
                    />
                  ) : null}
                  <circle
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke={props.strokeColor}
                    strokeOpacity={0.5}
                    fill="transparent"
                    strokeWidth={props.strokeWidth}
                    originX="90"
                    originY="90"
                  />
                  <circle
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke={"#EBEAED"}
                    strokeOpacity={0.5}
                    fill="transparent"
                    strokeWidth={props.strokeWidth}
                    originX="90"
                    originY="90"
                  />
                  <circle
                    cx="50%"
                    cy="50%"
                    r={radius}
                    stroke={props.color}
                    fill="transparent"
                    strokeWidth={props.strokeWidth}
                    strokeDasharray={circleCircumference}
                    strokeDashoffset={scoreStrokeDashoffset}
                    rotation={scoreAngle}
                    originX="90"
                    originY="90"
                    strokeLinecap="round"
                  />
                </>
              )}
                 
            </g>
          </svg>
          <div style={{ position:'absolute' }}>
              <div style={{ color: "black", fontSize: props.labelSize, fontWeight: 900 }}>
                {total.toFixed()}%{" "}
              </div>
              <div
                style={{
                  width:'100%',
                  fontSize: "10px",
                  fontWeight: 900,
                  lineHeight: "12px",
                  letterSpacing: "0.02em",
                  color: props.color,
                  mixBlendMode: "normal",
                  opacity: 0.92,
                  textTransform: "uppercase",
                  textAlign:'center',
                  marginTop:5
                }}
              >
                {total === 0 ? "" : props.label}
              </div>
          </div>
      </div>

      {/* <Grid
        style={
          {
            //   position: "absolute",
              justifyContent: "center",
              alignItems: "center",
            //   top: 30,
          }
        }
      >
        <Grid
          style={{
            flexDirection: "row",
            marginTop: "-105px",
            // marginLeft: "55px",
          }}
        >
          <span
            style={{
              color: "black",
              marginTop: 20,
              fontSize: props.labelSize,
              fontWeight: 900,
            }}
          >
            {total.toFixed()}%{" "}
          </span>
        </Grid>
        <Grid
          style={{
            width: "auto",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div
            style={{
              width:'100%',
              fontSize: "10px",
              fontWeight: 900,
              lineHeight: "12px",
              letterSpacing: "0.02em",
              color: props.color,
              mixBlendMode: "normal",
              backgroundColor:'red',
              opacity: 0.92,
              // position: "absolute",
              textTransform: "uppercase",
              textAlign:'center',
              // marginLeft:
              //   props.label === "Very Good"
              //     ? "50px"
              //     : props.label === "Satisfactory"
              //     ? "40px"
              //     : props.label === "Great"
              //     ? "60px"
              //     : props.label === "Very Poor"
              //     ? "45px"
              //     : props.label === "Poor"
              //     ? "65px"
              //     : "55px",
            }}
          >
            {total === 0 ? "" : props.label}
          </div>
        </Grid>
      </Grid> */}
    </Grid>
  );
};

export default PieChart;
