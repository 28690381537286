import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  clock: {
    position: "relative",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 70,
    // height: 180,
    // width: 370,
    paddingTop: 20,
    "& section": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#2ECFFF",
      borderRadius: 7,
      width: '2.5em',
      // paddingRight: 60,
      // paddingLeft: 60,
      height: '2em',
      "& p": {        
        fontFamily: 'Inter-Regular',
        letterSpacing: "-0.085em",
        // marginTop: 70,
      },
      "& small": {
        color: "#23262F",
        fontWeight: 600,
        fontSize: 13,
        textTransform: "uppercase"
      },
    },
  },
}));

export default useStyles;
