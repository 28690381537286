// import axios from "axios";
import { axiosService as axios } from "../../helpers/utils-authentication";
import { setSnackBar } from "./snackbar.action";
import { GETPROVINCES, GETCITIES, SET_FILTER_LOCATIONS } from "../types/province.types";
import { appConfig } from "../../config/appconfig";
import { store } from '../store/rootStore';


export const addProvince = ({ provincename, abbreviation, capital, country },email, successCallback) => {
  return async (dispatch) => {
    const body = {
      locationname: provincename,
      email: email,
      abbreviation: abbreviation,
      capital: capital,
      country: country
    };

    return axios
      .post(`${appConfig.locationPath}addlocation`, body)
      .then((resp) => {
        const { success, statusMessage } = resp && resp.data;
        if (!success) {
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: statusMessage,
              key: "4",
              open: true,
            })
          );
          return;
        }

        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Province Created Successfully",
            key: "1",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const updateProvince = ({ provincename, abbreviation, capital, country }, province_id,email, successCallback) => {
  return async (dispatch) => {
    const body = {
      locationid: province_id,
      locationname: provincename,
      email:email,
      abbreviation: abbreviation,
      capital: capital,
      country: country
    };

    return axios
      .post(`${appConfig.locationPath}updatelocation`, body)
      .then((resp) => {
        const { success, statusMessage } = resp && resp.data;
        if (!success) {
          dispatch(
            setSnackBar({
              snackType: "error",
              snackbarMessage: statusMessage,
              key: "4",
              open: true,
            })
          );
          return;
        }

        dispatch(
          setSnackBar({
            snackType: "success",
            snackbarMessage: "Province Updated",
            key: "1",
            open: true,
          })
        );
        successCallback();
      })
      .catch((error) => {
       
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};

export const getProvinces = (email) => {
  let state = store.getState();
  return async (dispatch) => {
    return axios
      .get(`${appConfig.locationPath}getlocation`, 
        {
            headers: {
              Authorization: 'Bearer ' + state.Authentication.user?.userdata?.access_token
            },
            params: {
              email: email,
            }
        }
      )
      .then((resp) => {
      
        dispatch({
          type: GETPROVINCES,
          payload: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};


export const getCities = (email) => {

  let state = store.getState();
  return async (dispatch) => {
    return axios
      .get(`${appConfig.locationPath}getcities`, 
        {
            headers: {
              Authorization: 'Bearer ' + state.Authentication.user?.userdata?.access_token
            },
            params: {
              email: email,
            }
        }
      )
      .then((resp) => {
      
        dispatch({
          type: GETCITIES,
          payload: {
            data: resp.data.data,
          },
        });
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};



export const getFilterLocations = (email, filters) => {
  let state = store.getState();
  return async (dispatch) => {
    return axios
      .get(`${appConfig.locationPath}getlocationforfilter`, 
        {
            headers: {
              Authorization: 'Bearer ' + state.Authentication.user?.userdata?.access_token
            },
            params: {
              email: email,
              filters: filters
            }
        }
      )
      .then((resp) => {
      
        dispatch({
          type: SET_FILTER_LOCATIONS,
          payload: {
            data: resp.data.data.location,
          },
        });
      })
      .catch((error) => {
        
        dispatch(
          setSnackBar({
            snackType: "error",
            snackbarMessage: error?.response?.data?.statusMessage,
            key: "4",
            open: true,
          })
        );
      });
  };
};


