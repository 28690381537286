import React, { useState, useEffect, useCallback } from "react";
import Grid from '@mui/material/Grid';
import { useHistory, Link } from "react-router-dom";
import useStyles from "./users.styles";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import CustomTable from "../../components/table/table.component";
import {
  EditAction,
  ShowAction,
} from "../../components/table/tableActions";
import AddButton from "../../components/buttons/add-button/addButton.component";
import { useDispatch, useSelector } from "react-redux";
import {
  getUsers,
  getPermission
} from "../../redux/actions/authentication.action";
import Button from '@mui/material/Button';
import { getProvinces, getCities } from "../../redux/actions/provinces.action";
import { getOrganization, getMetaData } from "../../redux/actions/organization.action";
import { ArrowDownward, AddCircle, PersonAdd } from '@mui/icons-material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import { makeStyles } from '@mui/styles';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import UserForm from '../../components/user/user-form/userForm.component';
import AdminUserForm from "../../components/user/user-form/adminUserForm.component";
import Slide from '@mui/material/Slide';
import usericon from '../../assets/images/usericon.png';
import lockicon from '../../assets/images/lockicon.png';
import contacticon from '../../assets/images/contacticon.png'
import lockicon2 from '../../assets/images/lockicon2.png';
import statusicon from '../../assets/images/statusicon.png';
import userplus from '../../assets/images/userplus.png';
import impersonate from '../../assets/images/impersonate.png';
import actionicon from '../../assets/images/actionicon.png';
import { setImpersonating, resendInvitation, unInviteUser, bulkResendInvitation } from "../../redux/actions/authentication.action";
import { uploadCSV } from "../../redux/actions/authentication.action";
import IconButton from '@mui/material/IconButton';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import empathologo from '../../assets/images/empathologo.png';
import empathopurplelogo from '../../assets/images/empathopurplelogo.png';
import InputAdornment from '@mui/material/InputAdornment';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { setShowLoading } from "../../redux/actions/loading.action";
import { useMediaQuery } from "react-responsive";
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { MenuItems} from '../../components/drawer/drawer-items/drawerItems.component';
import { persistor }  from '../../redux/store/rootStore';
import { setLogout, resetAuthState } from "../../redux/actions/authentication.action";

import wellbeingicon from '../../assets/images/wellbeingicon.png';
import organizationicon from '../../assets/images/organizationicon.png';
import usersicon from '../../assets/images/usersicon.png';
import covidsurveyicon from '../../assets/images/covidsurveyicon.png';
import returntoworkicon from '../../assets/images/returntoworkicon.png';
import faqicon from '../../assets/images/faqicon.png';
import settingsicon from '../../assets/images/settingsicon.png';
import logouticon from '../../assets/images/logouticon.png';
import CloseIcon from "@mui/icons-material/Close";
import LinearProgress, { LinearProgressProps } from '@mui/material/LinearProgress';
import uploadListUsers from "../../assets/images/uploadfileusers.png";
import Ellipse from "../../assets/images/Ellipse.png";
import Happyemoji from "../../assets/images/happyEmoji.png";
import Sademoji from "../../assets/images/SadEmoji.png";
import { useDropzone } from "react-dropzone";
import DescriptionIcon from '@mui/icons-material/Description';
import { setSnackBar } from "../../redux/actions/snackbar.action";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});



const Users = ({ props }) => {
  const isDesktopOrLaptop = useMediaQuery({
    query: '(min-width: 601px)'
  })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 600px)' })
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editUserData, setEditUserData] = useState(null);
  const { user, userlist, loading, permissions, impersonating, impersonatedorganization } = useSelector((state) => state.Authentication);
  const { provinces } = useSelector((state) => state.Provinces);
  const { organizations, meta_data } = useSelector((state) => state.Organization);
  const [openAddEditUserModal, setOpenAddEditUserModal] = useState(false);
  const [openAdminAddEditUserModal, setOpenAdminAddEditUserModal] = useState(false);
  const [showConfirmImpersonating, setShowConfirmImpersonating] = useState(false);
  const [impersonatingOrganiztion, setImpersonatingOrganization] = useState(null);
  const [showUploadUsersDialog, setShowUploadUsersDialog] = useState(false);
  const [filteredUserlist, setFilteredUserlist] = useState(null);
  const [searchUserValue, setSearchUserValue] = useState('');
  
  const [selectedFile, setSelectedFile] = useState(null);
	const [isFilePicked, setIsFilePicked] = useState(false);
  const [showCSVError, setShowCSVError] = useState(false);
  const [csvError, setCSVError] = useState([]);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [fileuploadloading, setFileuploadLoading] = useState(false);
  const [fileuploadsuccess, setFileUploadSuccess] = useState(false);
  const [fileuploadfailed, setFileUploadFailed] = useState(false);
  const [upload_progress, setUploadProgress] = useState('0');
  const [uploadedUserCount, setUploadUserCount] = useState(null);

  const onDrop = useCallback(acceptedFiles => {
    // Do something with the files
    
    if (acceptedFiles.length === 0){

      dispatch(
        setSnackBar({
          snackType: "error",
          snackbarMessage: "Invalid file. Only Excel files allowed",
          key: "4",
          open: true,
        })
      );

    }
    else {
      setSelectedFile(acceptedFiles[0]);
      setIsFilePicked(true);
    }

  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles:1,
    accept: {
      // 'text/csv': ['.csv'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      // 'application/vnd.ms-excel': ['.xls'],
    },
  });
  
  const drawerWidth = 150;

  const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: `-${drawerWidth}px`,
      ...(open && {
        transition: theme.transitions.create('margin', {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
      }),
    }),
  );
  

  const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: window.innerWidth,
    height:100,
    backgroundColor:'#FFF',
    zIndex: theme.zIndex.drawer - 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      marginLeft: drawerWidth,
      width: `calc(100% - ${drawerWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  }));

  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));


  const StyledTextField = styled(TextField)({
    'label + &': {
      color:'pink'
    },
    '& label.Mui-focused': {
      color: '#000',
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: '#000',
    },
    '& .MuiInputBase-input':{
        height:'100%',
        width:300
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        color:'#000',
        borderRadius: 5,
        borderWidth: 1,
        borderColor: "#AAA"
      },
      '&:hover fieldset': {
        borderColor: '#C9C9C9',
      },
      '&.Mui-focused fieldset': {
        borderColor: '#533aed',
      },
    },
  });


  useEffect( () => {
    
    // dispatch(getProvinces(user?.email));
    if(user?.email){
      dispatch(getOrganization(user?.email));
    }

  }, [user]);

  useEffect(() => {
    if (user?.email) {
      dispatch(getProvinces(user?.email));
      dispatch(getCities(user?.email));
    }
  }, [user]);


  useEffect( () => {
    
    dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization));
    dispatch(getPermission(user?.email));
    dispatch(getMetaData(user?.email, () => {}, () => {}));


  }, [user]);

  useEffect(() => {
    
    setFilteredUserlist(userlist);
  }, [userlist])

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const returnOrganizationName = (organization_id) => {
  
    let organizationobject = organizations.filter(organization => organization.id == organization_id );
    if (organizationobject.length > 0){
      return organizationobject[0].name
    }
  }

  const returnOrganization = (organization_id) => {

      let organizationobject = organizations.filter(organization => organization.id == organization_id );
      
      if (organizationobject.length > 0){
      return organizationobject[0]
      }
  }


  const filterByStatus = (status) => {


      // if (status === 'FAIL' || status === 'PASS'){
      //     let templist = []

      //     templist = returntoworkdata?.details.filter(( s ) => s.result_status === status ); 
      
      //     setStatusFilterOn(true);
      //     setFilteredDetails(templist);
      // }
      // else {
      //     setFilteredDetails(returntoworkdata?.details)
      // }

  }

  const returnProfileDescription = (profile_name) => {

    if (profile_name === 'isDomainAdmin'){
    
      return 'Domain Admin';
    }
    else if (profile_name === 'isEndUser'){
      
      return 'Employee'
    }
    else if (profile_name === 'isDomainSuperAdmin'){
      return 'Domain Super Admin'
    }
    else if (profile_name === 'isGlobalAdmin'){
      return 'Global Admin'
    }
    else if (profile_name === 'isSuperAdmin'){
      return 'Super Admin'
    }

  }


  const closeAddEditUserDialog = () => {
    if ( user?.userdata?.profile_name === "isDomainSuperAdmin" || user?.userdata?.profile_name === "isDomainAdmin" ){
      setOpenAddEditUserModal(false)
    }
    else {
      setOpenAdminAddEditUserModal(false)
    }

  }

  const openAddEditUserDialog = (row, edit) => {

    if (edit === true ) {

      setEditUserData(row)
      setAdd(false);
      setEdit(true);
    }
    else {
      setEdit(false);
      setAdd(true);
    }
    

    if ( user?.userdata?.profile_name === "isDomainSuperAdmin" || user?.userdata?.profile_name === "isDomainAdmin" ){
      setOpenAddEditUserModal(true)
    }
    else {
      setOpenAdminAddEditUserModal(true)
    }

  }

  const confirmImpersonatingOrganization = () => {

    dispatch(setImpersonating( true, impersonatingOrganiztion.organization_id ));
    dispatch(getUsers(user?.email, user?.userdata?.organization_id, true, impersonatingOrganiztion.organization_id));
    setShowConfirmImpersonating(false)
  }

  const openConfirmImpersonatingDialog = (row) => {
    setShowConfirmImpersonating(true);
    setImpersonatingOrganization(row);
  }

  const closeImpersonationConfirmationDialog = () => {
    setImpersonatingOrganization(null);
    setShowConfirmImpersonating(false)
  }

  const stopImpersonating = () => {
    dispatch(setImpersonating( false, null ));
    dispatch(getUsers(user?.email, user?.userdata?.organization_id, false, null));

  }

  const openCSVUploadDialog = () => {
    setShowUploadUsersDialog(true)
  }

  const closeUploadCSVDialog = () => {
    setShowUploadUsersDialog(false);
    clearFile();
  }

  const checkIfDefaultOrganization = (organization_id) => {

    let org = organizations.filter( org => org.id == organization_id)
    return org[0].default
  }

  const fileInputChangeHandler = (event) => {
        
		setSelectedFile(event.target.files[0]);
		setIsFilePicked(true);
    setShowCSVError(false);

	};

  const clearFile = () => {
    // document.getElementById('fileinput').value = null;
    setSelectedFile(null);
    setIsFilePicked(false);
    setFileuploadLoading(false);
    setFileUploadFailed(false);
    setFileUploadSuccess(false);
  }

  const handleFileSubmission = () => {

    setShowCSVError(false)

    if (selectedFile == null){

    }
    else {

        let orgId = impersonating === true ? impersonatedorganization : user?.userdata?.organization_id;
        let location_id = impersonating === true ? organizations.filter( org => org.id === impersonatedorganization)[0].location_id : organizations.filter( org => org.id === user?.userdata?.organization_id)[0].location_id

        setFileuploadLoading(true);
        setFileUploadFailed(false);
        setFileUploadSuccess(false);

        dispatch(uploadCSV(user?.email, selectedFile, orgId, location_id,
        (resp) => {

            clearFile();
            // closeUploadCSVDialog();
            setUploadUserCount(resp)
            setFileUploadSuccess(true);
            setFileUploadFailed(false);
            setFileuploadLoading(false);
        }, 
        (err) => {

            // setShowCSVError(true);
            // setCSVError(err);
            setFileUploadSuccess(false);
            setFileUploadFailed(true);
            setFileuploadLoading(false);

        },
        (prog) => {
            setUploadProgress(prog)
        }))

    }
  }

  const showRowStatus = (row) => {

    if (row?.user_confirmed === true && row?.is_active === true || ( row?.user_confirmed === false && row.is_active === true && row?.is_onboarding_done === true )){
      return <div><span style={{color: '#1CDC93', fontWeight:'500' }}>Accepted</span></div>
    }
    else if(row?.user_confirmed === false && row?.is_active === true && row?.is_onboarding_done === false) {
      return <div><span style={{color: '#E767FF', fontWeight:'500'}}>Pending</span></div>
    }
    else if(row?.user_confirmed === false && row?.is_active === false) {
      return <div><span style={{ fontWeight:'500' }}>Not Sent</span></div>
    }
    else {
      return <div><span style={{color: 'red', fontWeight:'500'}}>Deactivated</span></div>
    }

  }

  const showInviteButton = (row) => {

    if (row?.user_confirmed === true && row.is_active === true || ( row?.user_confirmed === false && row.is_active === true && row?.is_onboarding_done === true )){
      return <div><Button onClick={() => unInvite(row) } style={{ backgroundColor:'#DCDCDC', color:'#FFF', fontSize: 10, borderRadius: 25, paddingRight:20, paddingLeft:20, letterSpacing: 1.2 }}>UNINVITE</Button></div>
    }
    else if (row?.user_confirmed === false && row.is_active === true && row?.is_onboarding_done === false) {

      return <div><Button onClick={() => reInviteUser(row) } style={{ backgroundColor:'#E767FF', color:'#FFF', fontSize: 10, borderRadius: 25, paddingRight:20, paddingLeft:20, letterSpacing: 1 }}>RE-INVITE</Button></div>
    }
    else if (row?.user_confirmed === false && row.is_active === false ){

      return <div><Button onClick={() => reInviteUser(row) } style={{ backgroundColor:'#533AED', color:'#FFF', fontSize: 10, borderRadius: 25, paddingRight:20, paddingLeft:20, letterSpacing: 1 }}>SEND INVITE</Button></div>
    }
    else {
      return null
    }

  }

  const reInviteUser = (row) => {
    let body = {
      email: user?.email,
      user_email: row.username
    }
    dispatch(setShowLoading(true));
    dispatch(resendInvitation( body,
      () => {

        dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization));
        dispatch(setShowLoading(false));
      },
      () => {
        dispatch(setShowLoading(false));
      }
    ))
  }

  const unInvite = (row) => {

    let body = {
      email: user?.email,
      user_email: row.username
    }
    dispatch(setShowLoading(true));
    dispatch(unInviteUser( body,
      () => {

        dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization));
        dispatch(setShowLoading(false));
        
      },
      () => {
        dispatch(setShowLoading(false));
      }
    ))
  }

  const bulkResendinvitationRequest = (row) => {

    let body = {
      email: user?.email,
      impersonating: impersonating,
      impersonating_organization_id: impersonatedorganization
    }

    dispatch(setShowLoading(true));
    dispatch(bulkResendInvitation( body,
      () => {

        dispatch(getUsers(user?.email, user?.userdata?.organization_id, impersonating, impersonatedorganization));
        dispatch(setShowLoading(false));
        
      },
      () => {
        dispatch(setShowLoading(false));
      }
    ))
  }

  // COLUMNS FOR DOMAIN ADMIN AND SUPER ADMINS
  const columns = [
    {
      field: "name",
      headerName: "User Name",
      minWidth: 150,
      flex: 1, 
      renderHeader: (params) => {
  
         return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                      <img src={usericon} style={{ width:20 }}/>
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
         )
      },
      renderCell: ({ row }) => {
        return `${row.first_name} ${row.last_name}`
      },
    },
    {
      field: "organization_id",
      headerName: "Organization",
      minWidth: 150,
      flex: 1,
      hide: (user?.userdata?.profile_name === 'isSuperAdmin' || user?.userdata?.profile_name === 'isGlobalAdmin') && impersonating === false ? false : true,
      
      renderHeader: (params) => {
          
         return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={lockicon} style={{ width:20 }}/>
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
         )
      },
      renderCell: ({ row }) => {
        return returnOrganizationName(row.organization_id)
      },
    },
    {
      field: "username",
      headerName: "Contact",
      flex:1,
      minWidth: 200,
      renderHeader: (params) => {
          
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={contacticon} style={{ width:25 }}/>  
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
        )
      },
      renderCell: ({ row }) => {
        return <span style={{ color: '#533aed'}}>{row.username}</span>
      }
    },
    {
      field: "profile_name",
      headerName: "Access Level",
      minWidth: 200,
      flex:1,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={lockicon2} style={{ width:20 }}/>  
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({ row }) => {
        return returnProfileDescription(row.profile_name)
      },
    },
    {
      field: "status",
      headerName: "Status",
      sortable: false,
      minWidth: 100,
      flex:1,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={statusicon} style={{ width:25 }}/> 
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({row}) => {
        return ( 
          <>
            {showRowStatus(row)}
          </>
          )
      }
    },
    // {
    //   field: "entered_date",
    //   headerName: "Onboarded",
    //   sortable: true,
    //   width: 200,
    //   renderCell: ({ row }) => {
    //     return row.entered_date ? new Date(row.entered_date).toLocaleDateString() : null;
    //   },
    // },
    {
      field: "Invite",
      headerName: "Invite",
      sortable: false,
      minWidth: 130,
      flex: 1,
      disableClickEventBubbling: true,
      renderHeader: (params) => {
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={userplus} style={{ width:25 }}/> 
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({row}) => {
        return (
          <>
          {showInviteButton(row)}
          </>
        );
      },
    },
    {
      field: "Impersonate",
      headerName: "Impersonate",
      sortable: false,
      hide: true,//user?.userdata?.profile_name === 'isSuperAdmin' && impersonating === false ? false : true,
      minWidth: 150,
      flex: 1,
      disableClickEventBubbling: true,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                    <img src={impersonate} style={{ width:25 }}/> 
                  </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({ row }) => {
        return (
          <div>
             {/* <Button style={{ backgroundColor:'#e767ff', color:'#FFF', fontSize: 10, borderRadius: 25, paddingRight:20, paddingLeft:20 }}>RE-INVITE</Button> */}
             {/* <Button style={{ backgroundColor:'#533aed', color:'#FFF', fontSize: 10, borderRadius: 25, paddingRight:20, paddingLeft:20 }}>SEND INVITE</Button> */}
             {
               checkIfDefaultOrganization(row.organization_id) === false ?
              <Button 
                style={{ 
                    backgroundColor:'#533aed', 
                    color:'#FFF', 
                    fontSize: 10, 
                    borderRadius: 25, 
                    paddingRight:20, 
                    paddingLeft:20 }} onClick={() => openConfirmImpersonatingDialog(row) }>Impersonate</Button>
              :
              null
            }
          </div>
        );
      },
    },
    {
      field: "Action",
      headerName: "Action",
      sortable: false,
      minWidth: 100,
      flex: 1,
      disableClickEventBubbling: true,
      renderHeader: (params) => {
        
        return (
            <div style={{ textTransform:'none', fontWeight:'bold', lineHeight:1, padding:10 }}>
                <div style={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center' }}>
                  <div>
                      <img src={actionicon} style={{ width:20 }}/> 
                    </div>
                  <div>{params.colDef.headerName}</div>
                </div>
            </div>
          )
      },
      renderCell: ({ row }) => {
        return (
          <div>
            <IconButton onClick={() => openAddEditUserDialog(row, true)} aria-label="edit" sx={{ backgroundColor:'#EEE' }}>
                <EditIcon style={{ color:'#533AED' }}/>
              </IconButton> 
          </div>
        );
      },
    },
  ];

  const searchUser = (event) => {

    setSearchUserValue(event.target.value);
    let templist = [];

    if (event.target.value.length > 0){
        let templist = []

        templist = userlist.filter(( s ) => s.first_name.toLowerCase().includes(event.target.value.toLowerCase()) || s.last_name.toLowerCase().includes(event.target.value.toLowerCase())); 
        setFilteredUserlist(templist);

    }
    else {

      setFilteredUserlist(userlist)
    }

  }

  

  function LinearProgressWithLabel(props) {
    return (
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <Box sx={{ width: '100%', mr: 1 }}>
          <LinearProgress variant="determinate" {...props} />
        </Box>
        <Box sx={{ minWidth: 35 }}>
          <Typography variant="body2" color="text.secondary">{`${Math.round(
            props.value,
          )}%`}</Typography>
        </Box>
      </Box>
    );
  }

  const [progress, setProgress] = React.useState(10);

  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     setProgress((prevProgress) => (prevProgress >= 100 ? 10 : prevProgress + 10));
  //   }, 800);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);


  const clearFileUploadError = () => {
    setFileUploadFailed(false);
    setFileUploadSuccess(false);
    setFileuploadLoading(false);
  }

  return (

    organizations.length > 0 ?
    <Grid container style={{ padding: 0 }}>

      <Grid container lg={12} xl={6} md={12} xs={12} direction="row">
        <AppBar position="absolute" open={true}>
          <Toolbar
            sx={{
              background: '#FFF',
              height: 150,
              display:'flex',
              flexDirection:'row', 
              justifyContent: 'space-between'
            }}
          >
              {/* <div style={{ height: '50%', color:'#000', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'1.2em' }}>
                  Hello, <span style={{ fontWeight:'bold' }}>{user?.userdata?.first_name}</span>
              </div> */}

              <div style={{ display:'flex', flexDirection:'row', alignItems:'center' }}>
                    
                    <StyledTextField 
                        variant="outlined" 
                        placeholder="Search Users"
                        autoFocus
                        sx={{
                            marginLeft:20
                        }}
                        InputProps={{
                            startAdornment:
                            <InputAdornment>
                                <SearchIcon sx={{ color:"#533AED" }} />
                            </InputAdornment>
                        }}
                        onChange={searchUser}
                        value={searchUserValue}
                    />

                    <div style={{ marginLeft:20}}>
                        
                        {/* <Button sx={{ backgroundColor: '#BBB', color:'#FFF', padding:1.5 }}>
                            <div>Filter By Access Level</div>
                            <KeyboardArrowDownIcon />
                        </Button> */}
              
                    </div>
      
      
            </div>
              
                {
                  impersonating === false ?
                    <div style={{ height: '50%', display:'flex', flexDirection:'row', alignSelf:'center' }}> 
                        <img src={empathopurplelogo} style={{ width: 'auto', height: '100%' }} />
                    </div>
                  :
                  <div style={{ fontWeight:'bold', borderRadius:10, paddingRight:20, paddingLeft:20, height: '50%', display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', color:'#FFF', backgroundColor:'#2ECDED'}}>
                      Impersonating {returnOrganization(impersonatedorganization)?.name}
                  </div>
                }

          </Toolbar>
        </AppBar>

      </Grid>

      <Grid
        container
        style={{
          width:'100%',
          marginTop:130
        }}
      >

          <Grid
            item
            style={{
              // height: "50px",
              // marginBottom: "10px"
              display:'flex',
              flexDirection:'row',
              justifyContent:'space-between',
              width:'100%'
            }}
          >

            <Grid
              item
              lg={6}
              xl={6}
              md={6}
              xs={6}
              style={{ display: "flex" }}
            >
                <div style={{ letterSpacing: 1.5, fontSize: 50, fontFamily: 'Sofia-Pro', fontWeight:'bold' }}>
                  User Management
                </div>

            </Grid>

            <Grid
              item
              lg={12}
              xl={12}
              md={12}
              xs={12}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
                {/* {
                  user?.userdata?.profile_name === 'isDomainSuperAdmin' || user?.userdata?.profile_name === 'isDomainAdmin' || impersonating === true?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#58d8f2', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, marginBottom:20 }}
                    variant="text"  
                    disableElevation> 
                    <ArrowDownward />
                    <Link to="/templates/empatho_users_template.xlsx" target="_blank" download style={{ padding:"0px 10px", textDecoration:'none', color:'#FFF' }}>Template</Link>
                  </Button>
                  :
                  null
                } */}

                {
                  user?.userdata?.profile_name === 'isSuperAdmin' || user?.userdata?.profile_name === 'isDomainSuperAdmin' || impersonating === true?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#533AED', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, marginBottom:20 }}
                    variant="text" 
                    onClick={() => openCSVUploadDialog() }
                    disableElevation>
                      <AddCircle/>
                      <div style={{ padding:"0px 10px" }}>Upload List of Users</div>
                  </Button>
                  :
                  null
                }
                {
                  user?.userdata?.profile_name === 'isDomainSuperAdmin' || user?.userdata?.profile_name === 'isDomainAdmin' || impersonating === true?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#4ABB5F', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, marginBottom:20 }}
                    variant="text" 
                    onClick={() => openAddEditUserDialog(null, false)}
                    disableElevation>
                      <AddCircleIcon/>
                      <div style={{ padding:"5px 10px", letterSpacing: 1.5 }}>Add User</div>
                  </Button>
                  :
                  null
                }
                {
                  user?.userdata?.profile_name === 'isDomainSuperAdmin' || user?.userdata?.profile_name === 'isDomainAdmin' || impersonating === true?
                  <Button 
                    onClick={() => bulkResendinvitationRequest()}
                    style={{ fontSize:12, backgroundColor:'#e767ff', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, padding:"10px 20px", marginBottom:20 }}
                    variant="text" 
                    disableElevation>
                      Invite All
                  </Button>
                  :
                  null
                }

                {
                  (user?.userdata?.profile_name === 'isSuperAdmin' || user?.userdata?.profile_name === 'isGlobalAdmin') && impersonating === false ?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#533aed', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, marginBottom:20 }}
                    variant="text" 
                    onClick={() => openAddEditUserDialog(null, false)}
                    disableElevation>
                      <AddCircleIcon/>
                      <div style={{ padding:"5px 10px", letterSpacing: 1.5 }}>Add User</div>
                  </Button>
                  :
                  null
                }

                {
                  impersonating === true ?
                  <Button 
                    style={{ fontSize:12, backgroundColor:'#000', color:'#FFF', marginRight:5, marginLeft:5, borderRadius: 25, padding:"10px 20px", marginBottom:20 }}
                    variant="text" 
                    disableElevation
                    onClick={() => stopImpersonating()}>Stop Impersonating</Button>
                  :
                  null
                }

            </Grid>
          </Grid>

      </Grid>
    
  

      <Grid item lg={12} xl={12} md={12} xs={12} style={{marginTop: 10}}
      >
        <CustomTable 
          columns={columns} 
          rows={filteredUserlist || []} 
          rowId={"id"}           
          loading={loading}
        />
      </Grid>

      {/* <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={showUploadUsersDialog}
          disableEscapeKeyDown={true}
          maxWidth={'md'}
        >
          <div style={{ minHeight: '20vh', minWidth: '25vw', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
             <div style={{ fontWeight:'bold', fontSize: '1.4em' }}>Upload CSV</div>
             
             <div style={{ marginTop: 20 }}>
                <input
                    accept=".csv"
                    name='file'
                    type='file'
                    onChange={fileInputChangeHandler}
                    id="fileinput"
                />
            </div>

             <div style={{ display:'flex', justifyContent:'flex-end', alignItems:'flex-end' }}>
                <Button 
                  style={{ backgroundColor:'#533aed', color:'#FFF', margin:5 }} 
                  onClick={() => handleFileSubmission() }
                  disabled={ selectedFile !== null && isFilePicked == true ? false : true }
                  >Upload File</Button>
                <Button style={{ backgroundColor: 'red', color:'#FFF', margin:5 }} onClick={() => clearFile() }>Clear File</Button>
                <Button style={{ backgroundColor:'#CCC', color:'#777', margin:5 }} onClick={() => closeUploadCSVDialog() }>Cancel</Button>
             </div>
          </div>
      </Dialog> */}

      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={showConfirmImpersonating}
          disableEscapeKeyDown={true}
          maxWidth={'md'}
        >
          <div style={{ minHeight: '20vh', minWidth: '25vw', display:'flex', flexDirection:'column', justifyContent:'space-between' }}>
             <div style={{ fontWeight:'bold', fontSize: '1.4em' }}>Impersonating {organizations.filter( org => org.id == impersonatingOrganiztion?.organization_id )[0]?.name}</div>
             <div style={{ fontSize: '1em', marginTop:20 }}>Are you sure you want to continue?</div>
             <div style={{ display:'flex', justifyContent:'flex-end', alignItems:'flex-end' }}>
                <Button style={{ backgroundColor:'#533aed', color:'#FFF', margin:5 }} onClick={() => confirmImpersonatingOrganization() }>Yes</Button>
                <Button style={{ backgroundColor:'#CCC', color:'#777', margin:5 }} onClick={() => closeImpersonationConfirmationDialog() }>Cancel</Button>
             </div>
          </div>
      </Dialog>

      {/* DOMAIN ADD USER DIALOG */}
      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              padding:20,
              zIndex:1
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openAddEditUserModal}
          disableEscapeKeyDown={true}
          maxWidth={'xl'}
        >
      
        <div style={{ padding:20, width:'50vw', borderRadius:15 }}>
          <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeAddEditUserDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </div>
          <div style={{ fontWeight:'bold', fontSize:25, textAlign:'center', marginBottom:20 }}>Add Empatho Participants</div>
          <UserForm add={add} edit={edit} closeAddEditUserDialog={closeAddEditUserDialog} userData={editUserData}/> 
        </div>
    
      </Dialog>


      {/* ADMIN ADD USER DIALOG */}
      <Dialog 
          PaperProps={{
            style: {
              backgroundColor: '#FFF',
              borderRadius:15,
              // padding:20,
              zIndex:1, 
            },
          }}
          TransitionComponent={Transition}
          onClose={(event, reason) => {
            if (reason !== 'backdropClick') {
              //DO NOTHING IF BACKDROP CLICK
            }
          }}
          open={openAdminAddEditUserModal}
          disableEscapeKeyDown={true}
          maxWidth={'lg'}
        >
      
        <div style={{ padding:20, width:'40vw', borderRadius:15 }}>
          <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:'flex-end'}}>
              <a style={{ display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', padding:10 }} onClick={() => closeAddEditUserDialog() }>
                <CancelIcon style={{ color:'#999' }}/>            
              </a>
          </div>
          <div style={{ fontWeight:'bold', fontSize:25, textAlign:'center', marginBottom:20 }}>{edit ? 'Edit User' : 'Add New User'}</div>
          <div style={{ justifyContent:'center', display:'flex', flexDirection:'column',alignItems:'center', justifyContent:'center' }}>
            <div style={{ width:'85%' }}>
              <AdminUserForm add={add} edit={edit} closeAddEditUserDialog={closeAddEditUserDialog} userData={editUserData}/>
            </div>
          </div>
        </div>
    
      </Dialog>

      {/* Add List of users */}
      <Dialog
        PaperProps={{
          style: {
            backgroundColor: "#FFF",
            borderRadius: 15,
            padding: 20,
            zIndex: 1,
          },
        }}
        TransitionComponent={Transition}
        onClose={(event, reason) => {
          if (reason !== "backdropClick") {
            //DO NOTHING IF BACKDROP CLICK
          }
        }}
        open={showUploadUsersDialog}
        disableEscapeKeyDown={true}
        maxWidth={"lg"}
      >
        <div
          style={{
            width: 794,
            minHeight: 500,
            borderRadius: "10px",
          }}
        >
          <div
            style={{
              fontStyle: "normal",
              fontWeight: 700,
              fontSize: "22px",
              lineHeight: "40px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            List of users
            <CloseIcon onClick={() => closeUploadCSVDialog()}></CloseIcon>
          </div>
          
          {
            !fileuploadsuccess && !fileuploadfailed ?
            (
              fileuploadloading === false ?
                <>
                  <div
                    style={{
                      width: "790px",
                      // height: "405px",
                      paddingBottom:20,
                      left: "380px",
                      top: "319px",
                      backgroundColor: "#EBEAED",
                      border: "1px dashed #533AED",
                      borderRadius: "15px",
                    }}
                    {...getRootProps({ className: "dropzone" })}
                  >
                    
                  
                        <div
                          style={{
                            fontStyle: "normal",
                            fontWeight: 500,
                            fontSize: "16px",
                            lineHeight: "19px",
                            textAlign: "center",
                          }}
                        >
                          <div
                            style={{
                              textAlign: "center",
                              justifyContent: "center",
                              marginTop: 100,
                            }}
                          >
                            <img
                              src={uploadListUsers}
                              style={{ width: "112px", height: "112px" }}
                            />
                          </div>
                          Drag and drop your list of users here <br></br> or
                          <input className="input-zone" {...getInputProps()} />

                          {
                          isFilePicked === true ?
                          <div
                            style={{
                              fontStyle: "normal",
                              fontWeight: 500,
                              fontSize: "16px",
                              lineHeight: "19px",
                              textAlign: "center",
                            }}
                          >
                            <div
                              style={{
                                flexDirection:'column',
                                alignItems:'center',
                                textAlign: "center",
                                justifyContent: "center",
                              }}
                            >
                              <DescriptionIcon style={{ fontSize: 50 }}/>
                              <div>
                                {selectedFile?.name}
                              </div>
                            </div>

                          </div>
                            :
                            null
                        }

                          <div
                            style={{ textAlign: "center", paddingTop: 5 }}>
                            <Button
                              style={{
                                backgroundColor: "#533AED",
                                borderRadius: "25px",
                                color: "#FFFFFF",
                                letterSpacing: 2,
                                textAlign: "center",
                                fontWeight: 600,
                                fontSize: 14,
                                width: "223.11px",
                                height: 50,
                              }}
                            >
                              SELECT FILE
                            </Button>
                          </div>

                          <div
                            style={{
                              color: "#464353",
                              fontStyle: "normal",
                              fontWeight: 400,
                              fontSize: "12px",
                              lineHeight: "15px",
                              textAlign: "center",
                              paddingTop: 20,
                            }}
                          >
                            Accepted file formats: <strong>.xlsx</strong>
                          </div>

                        </div>
                  
                  </div>

                  <div style={{display:"flex", justifyContent:'center', alignItems:'center' }}>
                    {
                      isFilePicked ?
                      <>
                        <Button style={{ 
                          margin:10,
                          backgroundColor: "#533AED",
                          borderRadius: "25px",
                          color: "#FFFFFF",
                          letterSpacing: 2,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 14,
                          width: "223.11px",
                          height: 50,
                        }} onClick={() => clearFile() }>Clear File</Button>
                        <Button style={{ 
                          margin:10,
                          backgroundColor: "#533AED",
                          borderRadius: "25px",
                          color: "#FFFFFF",
                          letterSpacing: 2,
                          textAlign: "center",
                          fontWeight: 600,
                          fontSize: 14,
                          width: "223.11px",
                          height: 50,
                        }} onClick={() => handleFileSubmission() }>Upload Now</Button>
                      </>
                      :
                      null
                    }
                  </div>

                  <div
                    style={{
                      color: "#464353",
                      fontStyle: "normal",
                      fontWeight: 400,
                      fontSize: "14px",
                      lineHeight: "40px",
                      textAlign: "center",
                      paddingTop: 20,
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                    }}
                  >
                    First time?&ensp;{" "}
                    <div
                      style={{
                        color: "#533AED",
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 14,
                      }}
                    >
                      {" "}
                      <Link to="/templates/empatho_users_template.xlsx" target="_blank" download style={{ textDecoration:'none' }}>Download our user list template.</Link>
                    </div>
                  </div>
                </>
              :

              <div style={{ flex:1, minHeight:480, display:'flex', flexDirection:"column", justifyContent:'center', alignItems:'center', color:'#533aed' }}>
                <div style={{ width: 400, fontWeight:'500', color:"#000", marginLeft:-40 }}>Uploading file</div>
                <LinearProgressWithLabel value={upload_progress} style={{ width: 400 }} color="inherit"/>
              </div>
            )
            :
            (
              fileuploadsuccess === true ?
                <>
                  <div style={{ textAlign: "center", marginTop: 100 }}>
                        <img src={Ellipse} style={{ width: "149px", height: "149px" }} />
                  </div>
                  <div style={{ textAlign: "center", marginTop: -125 }}>
                    <img src={Happyemoji} style={{ width: "94px", height: "94px" }} />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: 50,
                      justifyContent: "center",
                      fontSize: 16,
                      fontWeight: 400,
                    }}
                  >
                    Your list of users has been uploaded successfully and is being processed..
                  </div>
                  <div style={{ fontWeight: 600, fontSize: 16, textAlign: "center" }}>
                    You'll receive an email when processing has completed.
                  </div>
                  <div style={{ paddingTop: 70, textAlign: "center" }}>
                    <Button
                      onClick={() => {

                        setShowUploadUsersDialog(false);
                        setFileUploadSuccess(false);
                        setFileUploadFailed(false);

                      }}
                      style={{
                        backgroundColor: "#FFFFFF",
                        // boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "25px",
                        color: "#533AED",
                        letterSpacing: 2,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 14,
                        width: "223.11px",
                        height: 50,
                        border: "2px solid #533AED",
                      }}
                    >
                      Ok, Great!
                    </Button>
                </div>
              </>
              :
              <>
                  <div style={{ textAlign: "center", marginTop: 100 }}>
                    <img src={Ellipse} style={{ width: "149px", height: "149px" }} />
                  </div>
                  <div style={{ textAlign: "center", marginTop: -125 }}>
                    <img src={Sademoji} style={{ width: "94px", height: "94px" }} />
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      paddingTop: 50,
                      justifyContent: "center",
                      fontSize: 16,
                      fontWeight: 600,
                    }}
                  >
                    Your list of users could not be uploaded due to errors.
                  </div>
                  {/* <div style={{ fontWeight: 400, fontSize: 16, textAlign: "center" }}>
                    Dowload the file to see where the errors are and try again <br></br>
                    after fixing the errors.
                  </div> */}
                  {/* <div style={{ paddingTop: 70, textAlign: "center" }}>
                    <Button
                      style={{
                        backgroundColor: "#533AED",
                        borderRadius: "25px",
                        color: "#FFFFFF",
                        letterSpacing: 2,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 14,
                        width: "223.11px",
                        height: 50,
                      }}
                    >
                      Download file
                    </Button>
                  </div> */}
                   <div style={{ paddingTop: 70, textAlign: "center" }}>
                    <Button
                      onClick={() => clearFileUploadError()} 
                      style={{
                        backgroundColor: "#533AED",
                        borderRadius: "25px",
                        color: "#FFFFFF",
                        letterSpacing: 2,
                        textAlign: "center",
                        fontWeight: 600,
                        fontSize: 14,
                        width: "223.11px",
                        height: 50,
                      }}
                    >
                      Back
                    </Button>
                  </div>
              </>
            )

          }

        </div>
      </Dialog>


    </Grid>
    : null
  );
};

export default Users;