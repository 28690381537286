import React, { useState, useEffect } from "react";
import useStyles from "../../screens/organizations/organizations.styles";
import TextField from "@mui/material/TextField";
import { getProvinces } from "../../redux/actions/provinces.action";
import { useSelector, useDispatch } from "react-redux";
import SaveButton from "../buttons/save-button/saveButton.component";
import {
  getOrganization,
  updateOrganization,
} from "../../redux/actions/organization.action";
import {
  adminCreateUser,
  adminUpdateUser,
} from "../../redux/actions/authentication.action";
import { setSnackBar } from "../../redux/actions/snackbar.action";
import moment from "moment";
import { useHistory } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import { alpha, styled } from "@mui/material/styles";
import empathowhitelogo from "../../assets/images/empathowhitelogo.png";
import empathoactionableresults from "../../assets/images/actionableresults.png";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { ArrowDownward, AddCircle, PersonAdd } from "@mui/icons-material";
import Button from "@mui/material/Button";
import { useForm, Controller } from "react-hook-form";
import { setShowLoading } from "../../redux/actions/loading.action";
import CircularProgress from "@mui/material/CircularProgress";
import { getUsers } from "../../redux/actions/authentication.action";
import Autocomplete from "@mui/material/Autocomplete";
import AddIcon from "@mui/icons-material/Add";
import { addOrganization } from "../../redux/actions/organization.action";
import CovidQuestions from "../../components/covidQuestions/covidquestions.component";
import Divider from "@mui/material/Divider";
import { gridColumnsTotalWidthSelector } from "@mui/x-data-grid";
import { makeStyles } from "@material-ui/core";

const CovidSurveyForm = ({
  covidSurveyData,
  add,
  closeAddCovidSurveyDialog,
  edit,
}) => {
  // const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, permissions, impersonating, impersonatedorganization } =
    useSelector((state) => state.Authentication);
  const { organizations } = useSelector((state) => state.Organization);
  const { provinces } = useSelector((state) => state.Provinces);
  const { handleSubmit, control, setValue, getValues } = useForm();
  const [loading, setActionLoading] = useState(false);
  const [organization, setOrganization] = useState(false);
  const [permissionsList, setPermissionsList] = useState(permissions);
  const [accessleveldisabled, disabledAccessLevel] = useState(true);
  const accessLevels = [
    { profile_name: "isGlobalAdmin", name: "Global Admin" },
    { profile_name: "isDomainSuperAdmin", name: "Organization Admin" },
    { profile_name: "isDomainAdmin", name: "Admin" },
    { profile_name: "isEndUser", name: "Employee" },
  ];

  const [displayText, setDisplayText] = useState(false);
  const [displayQuestions, setDisplayQuestions] = useState(false);
  const [questionArray, setQuestionArray] = useState([]);

  
  useEffect(() => {
    if (edit === true) {
  
      setValue("question", covidSurveyData?.question);

      setValue("location");
    } else {
      // setValue("organization", returnOrganization(user?.userdata?.organization_id).name);
      // setValue("organization_id", user?.userdata?.organization_id);
      // setValue("location_id", returnOrganization(user?.userdata?.organization_id).location_id);
    }
  }, [user]);

  useEffect(() => {
    if (user?.email) {
      dispatch(getOrganization(user?.email));
      dispatch(getProvinces(user?.email));
    }
  }, [dispatch, user]);

  const returnLocation = (location_id) => {
    let locationobject = provinces.filter(
      (province) => province.id == location_id
    );

    if (locationobject.length > 0) {
      return locationobject[0];
    }
  };

  const submitAddEditOrganization = (data) => {
    
    if (add === true) {
      
      let body = {
        //"question": saves question,
        //"province": Saves province

        default: false,
      };

      setActionLoading(true);

      dispatch(
        addOrganization(
          body,
          () => {
            dispatch(getOrganization(user?.email));
            setActionLoading(false);
            closeAddCovidSurveyDialog();
          },
          () => {
            setActionLoading(false);
          }
        )
      );
    } else {
      
      let body = {
        //"question": saves question,
        //"province": Saves province
      };
      setActionLoading(true);

      dispatch(
        updateOrganization(
          body,
          () => {
            dispatch(getOrganization(user?.email));
            setActionLoading(false);
            closeAddCovidSurveyDialog();
          },
          () => {
            setActionLoading(false);
          }
        )
      );
    }
  };

  const removeQuestion = (index) => {
    
    const arr = Object.keys(questionArray).filter(
      (item) => Number(item) !== index
    );
    return setQuestionArray(arr);
  };

  const LoginTextField = styled(TextField)({
    "label + &": {
      color: "pink",
    },
    "& label.Mui-focused": {
      color: "#000",
    },
    "& .MuiInputBase-input": {
      height: 15,
      textAlign: "center",
      color: displayText ? "white" : "black",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#000",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        color: "#000",
        borderRadius: 2,
        borderWidth: 1.6,
        borderColor: "#AAA",
      },
      "&:hover fieldset": {
        borderColor: "#C9C9C9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#533aed",
      },
    },
  });

  const useStyles = makeStyles({
    option: {
      "&:hover": {
        backgroundColor: "#533AED !important",
        color: "white !important",
      }
    }
  });

  const styles = useStyles();

  return (
    <form
      style={{ height: "100%" }}
      onSubmit={handleSubmit(submitAddEditOrganization)}
    >
      <Grid
        style={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div style={{ width: "50%", paddingTop: 20 }}>
          <Grid item lg={12} md={12} xs={12} style={{ paddingBottom: 5 }}>
            Province
          </Grid>

          <Controller
            rules={{ required: "Province is Required" }}
            defaultValue={returnLocation(covidSurveyData?.location_id) || []}
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <Autocomplete
                options={provinces}
                classes={{
                  option: styles.option
                }}
                fullWidth={true}
                onChange={(_, data) => {
                  
                  setQuestionArray([...questionArray, {}]);
                  setValue("location_id", data ? data.id : "");
                  onChange(data);
                  setDisplayText(true);
                }}
                value={value}
                getOptionLabel={(option) => option.state_or_province || ""}
                getOptionSelected={(option, value) => option?.id === value?.id}
                renderOption={(props, option) => (
                  <div
                    style={{
                      padding: 5,
                      width: "100%",
                      textAlign: "center",
                      justifyContent: "center",
                    }}
                    {...props}
                  >
                    {option.state_or_province}
                  </div>
                )}
                disableClearable
                renderInput={(params) => (
                  <LoginTextField
                    {...params}
                    style={{
                      backgroundColor: displayText ? "#533AED" : "",
                      color: "#FFFFF",
                    }}
                    variant="outlined"
                    placeholder="SELECT PROVINCE"
                    readOnly={false}
                    error={!!error}
                    helperText={error ? error.message : null}
                  />
                )}
              />
            )}
            name="province"
            control={control}
          />
        </div>
      </Grid>

      {displayText && (
        <div
          style={{
            overflowY: "scroll",
            maxHeight: "calc(100% - 310px)",
            flex: 1,
          }}
        >
          <Grid item lg={12} md={12} xs={12} style={{ paddingBottom: 5 }}>
            {questionArray.map((question, index) => {
              return (
                <CovidQuestions
                  id={index}
                  question={question}
                  callback={() => setQuestionArray([...questionArray, {}])}
                  onDeleteCallBack={() => removeQuestion(index)}
                />
              );
            })}
          </Grid>
        </div>
      )}

      <Grid item lg={12} md={12} xs={12}>
        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: 60,
          }}
        >
          <Button
            type="submit"
            style={
              add === true
                ? {
                    fontSize: 12,
                    backgroundColor: "#533aed",
                    color: "#FFF",
                    margin: "5px 5px",
                    borderRadius: 25,
                    width: 250,
                  }
                : {
                    fontSize: 12,
                    backgroundColor: "grey",
                    color: "#FFF",
                    margin: "5px 5px",
                    borderRadius: 25,
                    width: 250,
                  }
            }
            variant="text"
            disabled={loading}
            disableElevation
          >
            <div style={{ padding: "10px 10px", letterSpacing: 1.5 }}>
              GO LIVE
            </div>

            {loading ? (
              <div style={{ justifyContent: "center", alignItems: "center" }}>
                <CircularProgress size={20} sx={{ color: "#FFF" }} />
              </div>
            ) : null}
          </Button>
        </Grid>

        <Grid
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "500",
            fontSize: 13,
            color: "black",
            marginTop: 20,
          }}
        >
          SAVE DRAFT
        </Grid>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            fontWeight: "300",
            fontSize: 11,
            color: "black",
            marginTop: 20,
          }}
        >
          You can only create one survey per province
        </div>
      </Grid>
    </form>
  );
};

export default CovidSurveyForm;
