import React from "react";
import { Grid, TextField } from "@material-ui/core";
import useStyles from "./register.styles";
import { useForm, Controller } from "react-hook-form";

//Components
import RegisterBtn from "../../../components/register-btn/registerBtn.component";

//Redux
import { useDispatch } from "react-redux";
import { setSignUp } from "../../../redux/actions/authentication.action";
import { useHistory } from "react-router-dom";

const Register = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { handleSubmit, control } = useForm();

  const onSubmit = (data) => dispatch(setSignUp(data,() => history.push({pathname: "/login",state: {new: true}})));

  return (
    <Grid container className={classes.container}>
      <Grid item lg={4} className={classes.background}></Grid>
      <Grid item lg={8} xs={12} className={classes.container_register}>
        <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
          <Grid className={classes.box}>
            <div className={classes.title}>Sign Up</div>
          </Grid>
          <Grid className={classes.box}>
            <Controller
              name="firstname"
              control={control}
              defaultValue=""
              rules={{ required: "First Name required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text_field}
                  variant="outlined"
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid className={classes.box}>
            <Controller
              name="lastname"
              control={control}
              defaultValue=""
              rules={{ required: "Last Name required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text_field}
                  variant="outlined"
                  label="LastName"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid className={classes.box}>
          <Controller
              name="email"
              control={control}
              defaultValue=""
              rules={{ required: "Email required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text_field}
                  variant="outlined"
                  label="Email"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid className={classes.box}>
            <Controller
              name="password"
              control={control}
              defaultValue=""
              rules={{ required: "Password required" }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  className={classes.text_field}
                  variant="outlined"
                  type="password"
                  label="Password"
                  onChange={onChange}
                  value={value}
                  error={!!error}
                  helperText={error ? error.message : null}
                />
              )}
            />
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            className={classes.box}
          >
            <RegisterBtn />
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};

export default Register;
